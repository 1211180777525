import combineQuery from 'graphql-combine-query';
import gql from 'graphql-tag';
import { print } from 'graphql/language/printer';

export class CombineGraphQL {

    constructor() {
        this.combineQueryFn = combineQuery('Composite');
        // this.combineMutationFn = 
        this.queryCounter = 0;
    }

    addQuery = (query, variable) => {
        this.combineQueryFn = this.combineQueryFn.addN(gql`${query}`, [variable],
            (name, index) => {
                return `${name}${this.queryCounter}`
            }, (name, index) => {
                return `${name}${this.queryCounter}`
            });

        this.queryCounter++;
    }

    getGraphQLQuery = () => {
        const { document, variables } = (() => this.combineQueryFn)();

        // console.log('combineGraphQL', print(document), variables);

        return {
            query: print(document),
            variables
        };
    }
}