import React from "react"
import { BrowserRouter } from "react-router-dom"
import "antd/dist/antd.less"
import "./css/App.css"
import { Layout } from "antd"

import HeaderApp from "./components/Component-HeaderApp"
import SideNav from "./components/Component-SideNav"
import FooterApp from "./components/Component-Footer"
import RoutePath from "./utils/RoutePath"

const { Content } = Layout;

const App = () => {

  // For IAM credentials
  // useEffect(() => {
  //   const init = async () => {
  //     const credentials = await Auth.currentCredentials()
  //     // console.log(credentials);
  //   }
  //   init()
  // }, [])

  return (
    <BrowserRouter>
      <Layout className="default-theme" style={{ height: "100%", minHeight: "100vh" }}>
        <SideNav />
        <Layout>
          <HeaderApp />
          <Content className="content">
            <RoutePath />
          </Content>
          {/* <FooterApp /> */}
        </Layout>
      </Layout>
    </BrowserRouter>
  )
}
export default App;
