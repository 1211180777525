import React, { useContext, useEffect, useState } from "react"
import { Button, Descriptions, Form, Input } from "antd"
import { CheckCircleOutlined, ExclamationCircleOutlined } from '@ant-design/icons'
import { Link } from "react-router-dom"

import path from "../utils/pathSettings"
import { AppContext } from "../contexts/AppContext"

const EmailChange = () => {
    const { actionSetPageTitle } = useContext(AppContext)
    const [form] = Form.useForm();
    const [user, setUser] = useState({})
    const validateMessages = {
        required: 'This field is required.',
        types: {
            email: 'This is not a valid email.',
        },
    }

    const getCurrentUserInfo = async () => {
        setUser({
            email: "current@email.com",
            emailVerified: true,
        })
    }

    useEffect(() => {
        actionSetPageTitle("Settings", true, path("settings"))

        getCurrentUserInfo()

        return () => {
            actionSetPageTitle("", false, "")
        }
    }, [])

    const onFinish = (values) => {
        // console.log("on finish", values);
    }

    return (
        <div>
            <div className="content-content">
                <Descriptions
                    title={"Change Email Address"}
                    column={3}
                >
                    <Descriptions.Item label="Current Email" span={3}>{user.email}
                        {
                            user.emailVerified === true ? (
                                <span className="emailverifiedstatus"><CheckCircleOutlined /></span>
                            ) :
                                (
                                    <span>
                                        <span className="emailverifiedstatus notverified"><ExclamationCircleOutlined /></span>
                                        <Link to={"/settings/email/verify"} style={{ marginLeft: "16px" }}><Button>Verify Email</Button></Link>
                                    </span>
                                )
                        }
                    </Descriptions.Item>
                </Descriptions>
                <Form
                    form={form}
                    layout="vertical"
                    onFinish={onFinish}
                    validateMessages={validateMessages}
                    requiredMark={false}
                >
                    <Form.Item
                        label="New Email"
                        name="email"
                        rules={[
                            {
                                type: 'email',
                                required: true,
                            },
                        ]}
                    >
                        <Input autoComplete="off" placeholder="Enter new email" />
                    </Form.Item>
                    <div className="button">
                        <Button type="primary" htmlType="submit">Update</Button>
                    </div>
                </Form>
            </div>
        </div>
    )
}

export default EmailChange