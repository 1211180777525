import React from 'react'
import { Card } from 'antd'
import moment from 'moment'

const CardVisitor = (props) => {
    // console.log(props);
    const { item } = props

    return (
        <Card title={
            <div className="card-visitor-title">
                <div>
                    <div>
                        {item.name}
                    </div>
                </div>
                <div>
                    {moment.utc(item.inTimestamp).local().format('DD MMM YYYY (ddd), HH:mm')}
                    <div style={{fontSize: '12px', fontStyle: 'italic', marginTop: '5px'}}>
                        {item.siteDisplay}
                    </div>
                </div>
            </div>
        } className="card-visitor">
            <div className="row">
                <div className="label">Contact Number:</div>
                <div className="data">{item.phoneNo}</div>
            </div>
            <div className="row">
                <div className="label">Purpose of Visit:</div>
                <div className="data">{item.purpose}</div>
            </div>
            <div className="row">
                <div className="label">Block:</div>
                <div className="data">
                    {item.block}
                </div>
            </div>
            <div className="row">
                <div className="label">Unit:</div>
                <div className="data">
                    {item.unit}
                </div>
            </div>
            <div className="row">
                <div className="label">Vehicle Number:</div>
                <div className="data">
                    {item.vehicleNo}
                </div>
            </div>
            <div className="row">
                <div className="label">Remarks:</div>
                <div className="data">
                    {item.remarks}
                </div>
            </div>
            <div className="row">
                <div className="label">Submission Type:</div>
                <div className="data">
                    {item.type !== "QR" ? item.type.slice(0, 1).toUpperCase() + item.type.slice(1).toLowerCase() : item.type}
                </div>
            </div>
        </Card>
    )
}

export default CardVisitor