import { useContext, useEffect, useState } from 'react'
import { Button, DatePicker, Descriptions, Form, Input, notification, Select, Spin, Tooltip } from 'antd'
import { CloseOutlined, InfoCircleOutlined } from '@ant-design/icons'
import { useHistory } from 'react-router-dom'
import API from '@aws-amplify/api'
import moment from 'moment'

import path from '../utils/pathSettings'
import { listOccurrence } from '../backend/graphql/queries'
import { createOccurrence } from '../backend/graphql/mutations'
import { AppContext } from '../contexts/AppContext'
import useUnsavedChangesWarning from '../hooks/useUnsavedChangesWarning'
import LoginConfirmation from './Component-Login-Confirmation'

const { Option } = Select

const OccurrenceCreate = () => {
    const { appState, actionSetPageTitle } = useContext(AppContext)
    const [form] = Form.useForm();
    const [loading, setLoading] = useState(false)
    const validateMessages = {
        required: 'This field is required.',
    }
    const history = useHistory()
    const [lastSN, setLastSN] = useState(0)
    const [confirm, setConfirm] = useState(false)
    const [Prompt, setDirty, setPristine] = useUnsavedChangesWarning()


    const createOccurrenceRecord = async (values) => {
        setLoading(true)
        const user = JSON.parse(localStorage.getItem('isap_cognitouser_site'))
        // const profiles = JSON.parse(user.signInUserSession.idToken.payload.profiles)
        // console.log("user", user);
        // console.log("profiles", profiles);
        try {
            let create = {
                securityAgencyID: appState.agencyID, // current fixed to first profile, need to have an attribute to store last agency profile
                securitySiteID: parseInt(values.securitySiteID),
                siteProfileID: parseInt(values.siteProfileID),
                siteHistoryID: parseInt(values.siteHistoryID),

                reportedByAccountID: user.username,
                reportedByStaffProfileID: appState.profileAgency.staffProfileID,
                timezone: new Date().getTimezoneOffset() / -60,

                sn: values.sn,
                date: values.datetime.format('YYYY-MM-DD'),
                datetime: values.datetime.toISOString().split('.')[0],
                title: values.title,
                description: values.description,
            }
            create = Object.entries(create).reduce(
                (a, [k, v]) => (v == null ? a : ((a[k] = v), a)),
                {}
            )
            // console.log("createOccurrence create", create);
            const variables = {
                create: create,
                agencyID: appState.agencyID
            }
            // console.log("createSiteProfileDetails", variables);
            const result = await API.graphql({
                query: createOccurrence,
                variables,
                authMode: 'AMAZON_COGNITO_USER_POOLS'
            })
            // console.log("createOccurrence result", result);

            if (result?.data?.result) {
                setPristine()
                notification.success({
                    message: 'Created successfully'
                })
                history.push(path('occurrence'))
            } else {
                throw Error('');
            }
        }
        catch (error) {
            console.log('error:', error);
            notification.error({
                message: 'Unable to create occurence'
            })
            setLoading(false)
        }
    }

    const getOccurrenceRecords = async (values) => {
        setLoading(true)
        // const user = JSON.parse(localStorage.getItem('isap_cognitouser_site'))
        // const profiles = JSON.parse(user.signInUserSession.idToken.payload.profiles)
        // console.log("user", user);
        // console.log("profiles", profiles);
        try {
            const variables = {
                filter: {
                    and: [{
                        datetime: {
                            ge: moment(values.datetime.format('YYYY-MM-DD 00:00:00')).toISOString().split('.')[0],
                        }
                    }, {
                        datetime: {
                            lt: moment(moment(values.datetime).add(1, 'day').format('YYYY-MM-DDT00:00:00')).toISOString().split('.')[0]
                        }
                    }],
                    siteProfileID: { eq: appState.siteProfile.siteProfileID }
                },
                pagination: {
                    limit: 1,
                    orderby: '`datetime` DESC',
                    // agencyID: profiles[0].securityAgencyID, // current fixed to first profile, need to have an attribute to store last agency profile
                    agencyID: appState.agencyID
                }
            }
            // console.log('listOccurrence', variables);
            const result = await API.graphql({
                query: listOccurrence,
                variables,
                authMode: 'AMAZON_COGNITO_USER_POOLS'
            })
            // console.log('listOccurrence result', result);

            if (result?.data?.result?.result?.length === 1) {
                setLoading(false)
                setLastSN(result.data.result.result[0]?.sn ?? 0);
            } else {
                setLoading(false)
                setLastSN(0);
            }
        }
        catch (error) {
            console.log('error:', error);
            notification.error({
                message: 'Unable to list occurence'
            })
            setLoading(false)
        }
    }

    useEffect(() => {
        actionSetPageTitle('Create Occurrence', true, path('occurrence'))

        return () => {
            actionSetPageTitle('', false, '')
        }
    }, [])

    useEffect(() => {
        if (appState.siteList?.length > 0) {
            let siteProfile = appState.siteList.find(s => s.id === appState.siteProfile.siteProfileID);
            // console.log('siteProfile', appState.siteList, siteProfile);
            form.setFieldsValue({
                siteProfileID: siteProfile?.id ?? '',
                securitySiteID: siteProfile?.securitySiteID,
                siteHistoryID: siteProfile?.siteHistoryID,
                datetime: moment(),
            })
            getOccurrenceRecords({
                siteProfileID: siteProfile?.id,
                datetime: moment(),
            });
        }
    }, [appState.siteList])

    useEffect(() => {
        form.setFieldsValue({
            sn: lastSN + 1
        })
    }, [lastSN])

    useEffect(() => {
        if (confirm) {
            onFinish(form.getFieldsValue());
        }
    }, [confirm])


    const onFinish = async (values) => {
        // console.log('on finish', values);
        await form.validateFields()
        let _values = {
            ...values
        }

        await createOccurrenceRecord(_values)
    }

    const onFormValuesChange = async (newValues, existingValues) => {
        // console.log('onFormValuesChange', newValues, existingValues);

        if (Object.keys(existingValues).includes('siteProfileID') && Object.keys(existingValues).includes('datetime') &&
            (Object.keys(newValues).includes('siteProfileID') || Object.keys(newValues).includes('datetime'))) {
            getOccurrenceRecords(form.getFieldValue());
        }
        setDirty()
    }

    const handleCancelUpdate = () => {
        history.push(path('occurrence'))
    }

    const renderListOptions = (array) => {
        if (array.length !== 0) {
            const listOptions = array.map((item, index) => {
                return (
                    <Option key={item.id} value={item.id}>{item.name}</Option>
                )
            })

            return listOptions
        }
        else {
            return (
                <div />
            )
        }
    }

    return (
        <div className="container-content allow-overflow">
            <Spin spinning={loading}>
                <div className="content-content">
                    <Form
                        form={form}
                        layout="vertical"
                        // onFinish={onFinish}
                        onValuesChange={onFormValuesChange}
                        validateMessages={validateMessages}
                        requiredMark={false}
                    >
                        <Descriptions
                            title={
                                <div className="row spaceBetween">
                                    <div>
                                        <span>{appState.profileAgency.securityAgencyName}</span>
                                    </div>
                                    <div>
                                        <Button onClick={handleCancelUpdate} shape={appState.broken === true ? 'circle' : ''} icon={<CloseOutlined />}>
                                            {appState.broken === true ? '' : 'Cancel'}
                                        </Button>
                                        &nbsp;&nbsp;
                                        {/* <LoginConformationPopup
                                            // title={
                                            //     <div>
                                            //         Are you sure?
                                            //     </div>
                                            // }
                                            // onConfirm={() => onFinish(form.getFieldsValue())}
                                            // okText="Yes"
                                            // cancelText="No"
                                            // placement="topRight"
                                            onConfirm={onFinish}
                                            confirmLoading={loading}
                                        >
                                            <Button type="primary" htmlType="submit" shape={appState.broken === true ? 'circle' : ''} icon={<CheckOutlined />}>
                                                {appState.broken === true ? '' : 'Submit'}
                                            </Button>
                                        </LoginConformationPopup> */}
                                        <LoginConfirmation
                                            confirm={confirm}
                                            setConfirm={setConfirm}
                                            form={form}
                                            collapsableButton
                                        />
                                    </div>
                                </div>
                            }
                            column={{ xs: 1, sm: 2 }}
                        />
                        <Form.Item
                            label={
                                <div>SN&nbsp;
                                    <Tooltip title="SN is auto generated based on selected site and date time." trigger={['click']}>
                                        <InfoCircleOutlined />
                                    </Tooltip>
                                </div>
                            }
                            name="sn"
                        >
                            <Input autoComplete="off" disabled={true} placeholder="Enter SN"></Input>
                        </Form.Item>
                        <Form.Item
                            label="Site"
                            name="siteProfileID"
                        >
                            <Select
                                showSearch
                                disabled
                                placeholder="Select site"
                                filterOption={(input, option) =>
                                    option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
                                }
                            >
                                {renderListOptions(appState.siteList.filter(s => s.id === appState.siteProfile.siteProfileID))}
                            </Select>
                        </Form.Item>
                        <Form.Item
                            name="securitySiteID"
                            hidden="true"
                        >
                            <Input disabled={true} />
                        </Form.Item>
                        <Form.Item
                            name="siteHistoryID"
                            hidden="true"
                        >
                            <Input disabled={true} />
                        </Form.Item>
                        <Form.Item
                            label="Date / Time"
                            name="datetime"
                        >
                            <DatePicker showTime={true} format={'YYYY-MM-DD HH:mm'} />
                        </Form.Item>
                        <Form.Item
                            label="Subject"
                            name="title"
                            rules={[
                                {
                                    required: true,
                                },
                            ]}
                        >
                            <Input autoComplete="off" placeholder="Enter title" />
                        </Form.Item>
                        <Form.Item
                            label="Occurrence"
                            name="description"
                            rules={[
                                {
                                    required: true
                                }
                            ]}
                        >
                            <Input autoComplete="off" placeholder="Enter description" />
                        </Form.Item>
                        <div className="button">
                            <Button onClick={handleCancelUpdate}>Cancel</Button>
                            &nbsp;&nbsp;
                            <LoginConfirmation
                                confirm={confirm}
                                setConfirm={setConfirm}
                                form={form}
                            />
                        </div>
                    </Form>
                </div>
            </Spin>
            {Prompt}
        </div>
    )
}

export default OccurrenceCreate