import React, { useContext, useEffect, useState } from "react"
import { useHistory } from "react-router-dom"

import { AppContext } from '../contexts/AppContext'

const Home = () => {
    const { appState } = useContext(AppContext)
    const history = useHistory()

    useEffect(() => {
        // console.log("appState", appState);
    }, [])

    return (
        <div className="container-content allow-overflow">
            Dashboard
        </div>
    )
}

export default Home