import React, { useContext, useEffect, useState } from 'react'
import { useHistory } from 'react-router-dom'
import { Button, Descriptions, Form, notification, Popconfirm, Table, Tag } from 'antd'
import { DeleteOutlined, PlusOutlined } from '@ant-design/icons'
import moment from 'moment'
import API from '@aws-amplify/api'

import path from '../utils/pathSettings'
import { listOccurrence } from '../backend/graphql/queries'
import { AppContext } from '../contexts/AppContext'

import FilterOccurrence from './Component-Filter-Occurrence'
import FilterAppliedOccurrence from './Component-FilterApplied-Occurrence'

const Occurrence = () => {
    const { appState, actionSetPageTitle } = useContext(AppContext)
    const history = useHistory()
    const [table, setTable] = useState({
        data: -1,
        pagination: {
            current: 1,
            pageSize: 20,
            offset: 0,
            defaultPageSize: 20,
            showSizeChanger: true,
        },
        editingId: '',
        loading: false
    })
    const [displayList, setDisplayList] = useState([])
    const [selectedRowKeys, setSelectedKeys] = useState([])
    const [values, setValues] = useState({
        search: '',
        site: 0,
        siteHistoryStatus: 'ACTIVE',
        dateRange: []
    })

    const getOccurrenceRecords = async (props, values) => {
        setTable({
            ...table,
            loading: true
        })

        try {
            let filter = {
                securityAgencyID: {
                    // eq: profiles[0].securityAgencyID // current fixed to first profile, need to have an attribute to store last agency profile
                    eq: appState.agencyID
                },
                and: [
                    {
                        datetime: { ge: values.dateRange[0].toISOString().split('.')[0] }
                    },
                    {
                        datetime: { le: values.dateRange[1].toISOString().split('.')[0] }
                    },
                ],
                siteProfileID: { eq: appState.siteProfile.siteProfileID },
            }

            if (values.search !== '') {
                filter = {
                    ...filter,
                    or: [
                        { title: { contains: values.search } },
                        { description: { contains: values.search } },
                    ],
                }
            }

            const variables = {
                pagination: {
                    limit: props.pageSize,
                    offset: props.offset,
                    orderby: '`date` DESC, `sn` DESC',
                    agencyID: appState.agencyID
                },
                filter: filter
            }
            // console.log("listOccurrence", variables);
            const result = await API.graphql({
                query: listOccurrence,
                variables,
                authMode: 'AMAZON_COGNITO_USER_POOLS'
            })
            // console.log("result", result);
            const data = result.data.result
            // console.log("data", data);

            setTable({
                ...table,
                data: data.result,
                loading: data?.result?.length === 0 ? false : true,
                pagination: {
                    ...table.pagination,
                    current: props.current,
                    offset: props.offset,
                    pageSize: props.pageSize,
                    total: props.offset >= data.count ? data.count + 1 : data.count // keeps the last pagination if it is the last record
                }
            })
        }
        catch (error) {
            console.log('error:', error);
            notification.error({
                message: 'Unable to retrieve occurrence records'
            })
            setTable({
                ...table,
                loading: false,
            })
        }

        // const timer = setTimeout(() => {
        //     setTable({
        //         ...table,
        //         data: dataState.siteRecords
        //     })
        //     clearTimeout(timer)
        // }, 1000)
    }

    useEffect(() => {
        // console.log(moment());
        // console.log(moment().format("MMM"));
        // form.setFieldsValue({
        //     ...values,
        //     month: moment()
        // })
        const currentDatetime = moment()
        let _values = {
            ...values,
            site: appState.siteProfile.siteProfileID,
            dateRange: [
                moment(currentDatetime.format('YYYY-MM-DD 00:00:00')).add(-1, 'days'),
                moment(currentDatetime.format('YYYY-MM-DD 23:59:59')),
            ]
        };
        setValues(_values)
        getOccurrenceRecords(table.pagination, _values)

        actionSetPageTitle('Occurrence')

        return () => {
            actionSetPageTitle('')
        }
    }, [])

    useEffect(() => {
        if (table.data !== -1 && appState.fixedListLoaded === true) {
            // console.log("table.data", table.data);
            let array = []
            for (let i = 0; i < table.data.length; i++) {
                let data = table.data[i];

                // console.log(appState.siteList.find(s => s.id == data.siteProfileID));
                let object = {
                    ...data,
                    siteName: appState?.siteList?.find(s => s.id == data.siteProfileID)?.name,
                    datetimeDisplay: moment.utc(data.datetime).local().format('DD MMM YYYY (ddd), HH:mm')
                }
                array.push(object)
            }
            setDisplayList(array)
            setTable({
                ...table,
                loading: false
            })
        }
    }, [table.data, appState.fixedListLoaded])

    const handleTableChange = (paginate) => {
        getOccurrenceRecords({
            ...table.pagination,
            current: paginate.current,
            pageSize: paginate.pageSize,
            offset: paginate.current * paginate.pageSize - paginate.pageSize,
        }, values);
    }

    const handleCreateClick = () => {
        history.push(path('occurrenceCreate'))
    }

    const handleDeleteClick = () => {
        // console.log("Delete", selectedRowKeys);
    }

    const onFinish = (values) => {
        // console.log("on finish", values);
        // setActiveKey("")
        setValues(values)
        getOccurrenceRecords(table.pagination, values)
    }

    const columns = [
        {
            title: 'Site',
            dataIndex: 'siteName',
        },
        {
            title: 'S/N',
            dataIndex: 'sn',
            width: 80
        },
        {
            title: 'Date Time',
            dataIndex: 'datetimeDisplay',
        },
        {
            title: 'Subject',
            dataIndex: 'title',
        },
        {
            title: 'Occurrence',
            dataIndex: 'description',
            width: '40%'
        },
    ]

    return (
        <div className="container-content allow-overflow">
            <div className="content-content">
                <Descriptions title={
                    <div className="row spaceBetween">
                        <div>
                            <span>{appState.profileAgency.securityAgencyName}</span> <Tag>Site</Tag>
                        </div>
                        <Button onClick={handleCreateClick} type="primary" shape={appState.broken === true ? 'circle' : ''} icon={<PlusOutlined />}>
                            {appState.broken === true ? '' : 'Create'}
                        </Button>
                    </div>
                } />
                <FilterOccurrence onFinish={onFinish} values={values} />
                <FilterAppliedOccurrence onFinish={onFinish} values={values} />
            </div>
            {
                selectedRowKeys.length !== 0 ? (
                    <div className="content-content">
                        <div className="action-row filter-applied">
                            <div className="filter-applied-item">
                                Selected: <b>{selectedRowKeys.length}</b>
                            </div>
                            <Popconfirm title={
                                <div>
                                    This is an irreversible action.
                                    Are you sure?
                                </div>
                            } onConfirm={() => handleDeleteClick(selectedRowKeys)} okText="Yes" cancelText="No">
                                <Button type="primary" shape={appState.broken === true ? 'circle' : ''} icon={<DeleteOutlined />} disabled={selectedRowKeys.length === 0 ? true : false}>
                                    {appState.broken === true ? '' : 'Delete'}
                                </Button>
                            </Popconfirm>
                        </div>
                    </div>
                ) : (
                    <div />
                )
            }
            <Form>
                <Table
                    columns={columns}
                    rowKey={record => record.id}
                    dataSource={displayList}
                    pagination={table.pagination}
                    loading={table.loading}
                    scroll={{ x: 1200, y: '55vh' }}
                    onChange={handleTableChange}
                />
            </Form>
        </div>
    )
}

export default Occurrence