import React, { useContext, useEffect, useState } from 'react'
import { Button, DatePicker, Descriptions, Empty, Form, Image, Input, Modal, notification, Popconfirm, Select, Spin, Tag, Upload } from 'antd'
import { CheckOutlined, CloseOutlined, PlusOutlined, SaveOutlined } from '@ant-design/icons'
import { useHistory } from 'react-router-dom'
import API from '@aws-amplify/api'
import { Storage } from '@aws-amplify/storage'
import moment from 'moment'
import combineQuery from 'graphql-combine-query';
import gql from 'graphql-tag';
import { print } from 'graphql/language/printer';

import { createTicket, createTicketFile, createVisitorRecord, submitIncident } from '../backend/graphql/mutations'
import path from '../utils/pathSettings'
import { getAwsExport } from '../utils/awsExportSettings'
import { AppContext } from '../contexts/AppContext'
import imgEmptyImage192 from '../media/Image-EmptyImage-192.png'
import useUnsavedChangesWarning from '../hooks/useUnsavedChangesWarning'
import { renderListOptions } from '../utils/constants'

const { Option } = Select
const { TextArea } = Input

const VisitorRecordsCreate = () => {
    const { appState, actionSetPageTitle } = useContext(AppContext)
    const [form] = Form.useForm()
    const [loading, setLoading] = useState(false)
    const dateFormatList = ['DD MMM YYYY HH:mm']
    const validateMessages = {
        required: 'This field is required.',
    }
    const history = useHistory()
    const [Prompt, setDirty, setPristine] = useUnsavedChangesWarning()

    const _createVisitorRecord = async (values, type) => {
        setLoading(true)
        console.log(values);

        const user = JSON.parse(localStorage.getItem('isap_cognitouser_site'))
        const profiles = JSON.parse(user.signInUserSession.idToken.payload.profiles)
        // console.log('user', user);
        // console.log('profiles', profiles);
        try {
            const variables = {
                create: {
                    securityAgencyID: profiles[0].securityAgencyID,
                    securitySiteID: appState.siteList.find(s => s.id == values.site)?.securitySiteID ?? null,
                    siteProfileID: values.site,

                    type: 'MANUAL', //values.type.toUpperCase(),
                    name: values.name,
                    phoneNo: values.phoneNo,
                    purpose: values.purpose.toUpperCase(),
                    block: values.block,
                    unit: values.unit,
                    vehicleNo: values.vehicleNo,
                    remarks: values.remarks,

                    inTimestamp: values.inTimestamp.toISOString().split('.')[0],
                    inAccountID: user.username,
                    inStaffProfileID: profiles[0].staffProfileID,
                    // submittedByDesignation: 
                }
            }

            variables.create = Object.entries(variables.create).reduce(
                (a, [k, v]) => (v == null ? a : ((a[k] = v), a)),
                {}
            )

            // console.log('createVisitorRecord', variables);
            const result = await API.graphql({
                query: createVisitorRecord,
                variables,
                authMode: 'AMAZON_COGNITO_USER_POOLS'
            })
            // console.log('result', result);


            if (result?.data?.result) {

                setPristine()
                notification.success({
                    message: 'Created successfully'
                })
                history.push(path('visitor'))
            } else {
                throw Error('')
            }
        }
        catch (error) {
            console.log('error:', error);
            notification.error({
                message: 'Unable to create ticket'
            })
            setLoading(false)
        }
    }

    useEffect(() => {

        actionSetPageTitle('Create Visitor', true, path('visitor'))

        return () => {
            actionSetPageTitle('', false, '')
        }
    }, [])

    useEffect(() => {
        if (appState.fixedListLoaded === true) {
            let siteProfile = appState.siteList.find(s => s.id === appState.siteProfile.siteProfileID);

            form.setFieldsValue({
                type: 'WALKIN',
                inTimestamp: moment(),
                site: siteProfile?.id ?? '',
                securitySiteID: siteProfile?.securitySiteID,
                siteHistoryID: siteProfile?.siteHistoryID,
            })
            setLoading(false)
        }
    }, [appState.fixedListLoaded])

    const onFinish = async (values, type) => {
        console.log('on finish', values);
        // console.log("uploaded images", state);
        await form.validateFields()
        // setLoading(true)
        // // const user = JSON.parse(localStorage.getItem("isap_cognitouser_site"))
        // // const profiles = JSON.parse(user.signInUserSession.idToken.payload.profiles)
        // // console.log("user", user);
        // // console.log("profiles", profiles);

        await _createVisitorRecord(values, type)
        // // setLoading(true)
        // // setPristine()
        // // const timer = setTimeout(() => {
        // //     clearTimeout(timer)
        // //     notification.success({
        // //         message: "Created successfully"
        // //     })
        // //     history.push(path("incident")) // redirect to created report page from backend response
        // // }, 1000)
    }

    const handleDatePickerChange = (date, dateString) => {
        // console.log(date, dateString);
        setDirty()
    }

    const handleFormChange = () => {
        setDirty()
    }

    const handleCancelUpdate = () => {
        history.push(path('ticketRecords'))
    }

    const disabledDate = (current) => {
        // Cannot select days next day onwards
        return current > moment().endOf('day');
    }

    const renderSiteListOptions = (array) => {
        // console.log("array", array);
        if (array.length !== 0) {
            const listOptions = array.map((item, index) => {
                return (
                    <Option key={item.id} value={item.id}>{item.name}</Option>
                )
            })

            return listOptions
        }
        else {
            return (
                <div />
            )
        }
    }

    return (
        <div className="container-content allow-overflow">
            <Spin spinning={loading}>
                <div className="content-content">
                    <Form
                        form={form}
                        layout="vertical"
                        onFinish={onFinish}
                        validateMessages={validateMessages}
                        requiredMark={true}
                    >
                        <Descriptions
                            title={
                                <div className="row spaceBetween">
                                    <div>
                                        <span>{appState.profileAgency.securityAgencyName}</span> <Tag>Visitor</Tag>
                                    </div>
                                    <div>
                                        <Button onClick={handleCancelUpdate} shape={appState.broken === true ? 'circle' : ''} icon={<CloseOutlined />}>
                                            {appState.broken === true ? '' : 'Cancel'}
                                        </Button>
                                        &nbsp;&nbsp;
                                        <Popconfirm title={
                                            <div>
                                                Are you sure?
                                            </div>
                                        } onConfirm={() => onFinish(form.getFieldsValue(), 'submit')} okText="Yes" cancelText="No" placement="topRight">
                                            <Button type="primary" htmlType="submit" shape={appState.broken === true ? 'circle' : ''} icon={<CheckOutlined />}>
                                                {appState.broken === true ? '' : 'Submit'}
                                            </Button>
                                        </Popconfirm>
                                    </div>
                                </div>
                            }
                            column={{ xs: 1, sm: 2 }}
                        />
                        <Form.Item
                            label="Site"
                            name="site"
                        >
                            <Select
                                showSearch
                                disabled
                                placeholder="Select Site"
                                filterOption={(input, option) =>
                                    option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
                                }
                                onChange={handleFormChange}
                            >
                                {renderListOptions(appState.siteList.filter(s => s.id === appState.siteProfile.siteProfileID))}
                            </Select>
                        </Form.Item>
                        {/* <Form.Item
                            label="Type"
                            name="type"
                            rules={[
                                {
                                    required: true,
                                },
                            ]}
                        >
                            <Select
                                showSearch
                                placeholder="Select type"
                                filterOption={(input, option) =>
                                    option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
                                }
                                onChange={handleFormChange}
                            >
                                {renderListOptions([
                                    {
                                        id: 'WALKIN',
                                        name: 'Walk-In'
                                    },
                                    {
                                        id: 'DRIVE',
                                        name: 'Drive'
                                    },
                                    {
                                        id: 'OTHERS',
                                        name: 'Others'
                                    },
                                ])}
                            </Select>
                        </Form.Item> */}
                        <Form.Item
                            label="Visitor Name"
                            name="name"
                            rules={[
                                {
                                    required: true,
                                },
                            ]}
                        >
                            <Input autoComplete="off" placeholder="Enter visitor name" onChange={handleFormChange} />
                        </Form.Item>
                        <Form.Item
                            label="Visitor Contact Number"
                            name="phoneNo"
                            rules={[
                                {
                                    required: true,
                                },
                            ]}
                        >
                            <Input autoComplete="off" placeholder="Enter contact number" onChange={handleFormChange} />
                        </Form.Item>
                        <Form.Item
                            label="Purpose of Visit"
                            name="purpose"
                            rules={[
                                {
                                    required: true,
                                },
                            ]}
                        >
                            <Select
                                showSearch
                                placeholder="Select purpose of visit"
                                filterOption={(input, option) =>
                                    option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
                                }
                            >
                                {renderListOptions(appState.purposeOfVisitList, 'name', 'name', 'name')}
                            </Select>
                        </Form.Item>
                        <Form.Item
                            label="Entry Date / Time"
                            name="inTimestamp"
                            rules={[
                                {
                                    required: true,
                                },
                            ]}
                        >
                            <DatePicker onChange={handleDatePickerChange} format={dateFormatList} showTime disabledDate={disabledDate} />
                        </Form.Item>
                        <Form.Item
                            label="Block"
                            name="block"
                            rules={[
                                {
                                    required: false,
                                },
                            ]}
                        >
                            <Input autoComplete="off" placeholder="Enter block" onChange={handleFormChange} />
                        </Form.Item>
                        <Form.Item
                            label="Unit"
                            name="unit"
                            rules={[
                                {
                                    required: false,
                                },
                            ]}
                        >
                            <Input autoComplete="off" placeholder="Enter unit" onChange={handleFormChange} />
                        </Form.Item>
                        <Form.Item
                            label="Vehicle Number"
                            name="vehicleNo"
                            rules={[
                                {
                                    required: false,
                                },
                            ]}
                        >
                            <Input autoComplete="off" placeholder="Enter vehicle number" onChange={handleFormChange} />
                        </Form.Item>
                        <Form.Item
                            label="Remarks"
                            name="remarks"
                            rules={[
                                {
                                    required: false,
                                },
                            ]}
                        >
                            <TextArea rows={4} autoComplete="off" placeholder="Enter remarks" onChange={handleFormChange} />
                        </Form.Item>
                        <div className="button">
                            <Button onClick={handleCancelUpdate}>Cancel</Button>
                            &nbsp;&nbsp;
                            <Popconfirm title={
                                <div>
                                    Are you sure?
                                </div>
                            } onConfirm={() => onFinish(form.getFieldsValue(), 'submit')} okText="Yes" cancelText="No">
                                <Button type="primary" htmlType="submit">Submit</Button>
                            </Popconfirm>
                        </div>
                    </Form>
                </div>
            </Spin>
            {Prompt}
        </div>
    )
}

export default VisitorRecordsCreate