import React, { useState, useEffect } from 'react'
import { Prompt } from 'react-router-dom'

const useUnsavedChangesWarning = (message) => {
    const [isDirty, setDirty] = useState(false)
    const messageDisplay = message !== undefined ? message : 'Are you sure you want to discard all changes?'
    const routerPrompt = <Prompt when={isDirty} message={messageDisplay} />

    useEffect(() => {
        window.onbeforeunload = isDirty

        return () => {
            window.onbeforeunload = null
        }
    }, [isDirty])

    return [routerPrompt, () => setDirty(true), () => setDirty(false) ]
}

export default useUnsavedChangesWarning