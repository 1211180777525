import React, { useContext, useEffect, useState } from 'react'
import { Button, Comment, Descriptions, Empty, Form, Image, Input, List, notification, Popconfirm, Space, Spin, Tag } from 'antd'
import { EditOutlined } from '@ant-design/icons'
import { useHistory } from 'react-router-dom'
import API from '@aws-amplify/api'
import Storage from '@aws-amplify/storage'
import moment from 'moment'
// import { Image as ImageReactPDF, Page, Text, View, Document, PDFDownloadLink, PDFViewer, StyleSheet } from "@react-pdf/renderer"
// import { Table as TableReactPDF, TableHeader, TableBody, TableCell, DataTableCell } from "@alex9923/react-pdf-table"

import { listIncidentComment, listIncidentReport, listIncidentFile } from '../backend/graphql/queries'
import { createIncidentComment, submitIncident } from '../backend/graphql/mutations'
import path from '../utils/pathSettings'
import { AppContext } from '../contexts/AppContext'
import imgEmptyImage192 from '../media/Image-EmptyImage-192.png'
import imgInimicodeLogo from '../media/logo-inimicode-light-155x31.png'
import useUnsavedChangesWarning from '../hooks/useUnsavedChangesWarning'

const { TextArea } = Input

const IncidentReport = (props) => {
    const { appState, actionSetPageTitle } = useContext(AppContext)
    const [form] = Form.useForm();
    const [loading, setLoading] = useState(false)
    const [loadingCommentList, setLoadingCommentList] = useState(false)
    const [loadingImageList, setLoadingImageList] = useState(false)
    const [incident, setIncident] = useState(-1)
    const [commentList, setCommentList] = useState(-1)
    const [imageListSource, setImageListSource] = useState([])
    const [display, setDisplay] = useState({})
    const [displayCommentList, setDisplayCommentList] = useState([])
    const validateMessages = {
        required: 'This field is required.',
    }
    const history = useHistory()
    const [Prompt, setDirty, setPristine] = useUnsavedChangesWarning()
    const [disabledSubmit, setDisabledSubmit] = useState(true)

    const getImageFromStorage = async (array) => {
        // console.log("array", array);
        let arrayUpdate = []
        for (let i = 0; i < array.length; i++) {
            try {
                const result = await Storage.get(array[i].key)
                // console.log("result", result.toString());
                // arrayUpdate.push(result.toString())
                arrayUpdate.push({
                    ...array[i],
                    src: result.toString()
                })
            }
            catch (error) {
                console.log('error:', error);
            }
        }

        setImageListSource(arrayUpdate)
    }

    const getIncidentRecord = async (id) => {
        setLoading(true)
        const user = JSON.parse(localStorage.getItem('isap_cognitouser_site'))
        const profiles = JSON.parse(user.signInUserSession.idToken.payload.profiles)
        try {
            const listIncidentReportDetails = {
                pagination: {
                    agencyID: appState.agencyID,
                },
                filter: {
                    securityAgencyID: {
                        eq: appState.agencyID,
                    },
                    id: {
                        eq: id
                    }
                }
            }
            const result = await API.graphql({
                query: listIncidentReport,
                variables: listIncidentReportDetails,
                authMode: 'AMAZON_COGNITO_USER_POOLS'
            })
            // console.log("result", result);
            setIncident(result.data.result.result[0])
            // console.log("result", result.data.result.result[0]);
        }
        catch (error) {
            console.log('error:', error);
            notification.error({
                message: 'Unable to retrieve incident report'
            })
            setLoading(false)
        }
    }

    const getIncidentCommentRecords = async (id) => {
        setLoadingCommentList(true)
        const user = JSON.parse(localStorage.getItem('isap_cognitouser_site'))
        const profiles = JSON.parse(user.signInUserSession.idToken.payload.profiles)
        try {
            const listIncidentCommentDetails = {
                pagination: {
                    agencyID: appState.agencyID,
                },
                filter: {
                    incidentReportID: {
                        eq: id
                    }
                }
            }
            // console.log("listIncidentCommentDetails", listIncidentCommentDetails);
            const result = await API.graphql({
                query: listIncidentComment,
                variables: listIncidentCommentDetails,
                authMode: 'AMAZON_COGNITO_USER_POOLS'
            })
            // console.log("result", result);
            setCommentList(result.data.result.result)
        }
        catch (error) {
            console.log('error:', error);
            notification.error({
                message: 'Unable to retrieve incident report comments'
            })
            setLoading(false)
        }

        // if (id !== undefined) {
        //     setCommentList(dataState.incidentCommentRecords)
        // }
        // else {
        //     notification.error({
        //         message: "Unable to retrieve incident comments"
        //     })
        //     const timer = setTimeout(() => {
        //         setLoadingCommentList(false)
        //         clearTimeout(timer)
        //     }, 1000)
        // }
    }

    const getIncidentFileRecords = async (id) => {
        setLoadingImageList(true)
        const user = JSON.parse(localStorage.getItem('isap_cognitouser_site'))
        const profiles = JSON.parse(user.signInUserSession.idToken.payload.profiles)
        try {
            const listIncidentFileDetails = {
                pagination: {
                    agencyID: appState.agencyID,
                },
                filter: {
                    incidentReportID: {
                        eq: id
                    }
                }
            }
            const result = await API.graphql({
                query: listIncidentFile,
                variables: listIncidentFileDetails,
                authMode: 'AMAZON_COGNITO_USER_POOLS'
            })
            // console.log("result", result);
            const data = result.data.result
            // console.log("data", data);
            const array = data.result ? data.result : []
            if (array.length > 0) {
                await getImageFromStorage(array)
            }
        }
        catch (error) {
            console.log('error:', error);
            notification.error({
                message: 'Unable to retrieve incident report files'
            })
        }
        finally {
            setLoadingImageList(false)
        }
    }

    const createIncidentReportComment = async (id, values) => {
        setLoadingCommentList(true)

        const user = JSON.parse(localStorage.getItem('isap_cognitouser_site'))
        const profiles = JSON.parse(user.signInUserSession.idToken.payload.profiles)
        const cognitoUsername = user.signInUserSession.idToken.payload['cognito:username']

        // console.log("user", user);
        // console.log("profiles", profiles);
        // console.log("cognitoUsername", cognitoUsername);

        try {
            const incidentCommentDetails = {
                create: {
                    // accountID: appState.cognitoUserAgency.username,
                    accountID: cognitoUsername,
                    staffProfileID: profiles[0].staffProfileID,
                    incidentReportID: id,
                    comment: values.comment,
                }
            }
            // console.log("incidentCommentDetails", incidentCommentDetails);

            const result = await API.graphql({
                query: createIncidentComment,
                variables: incidentCommentDetails,
                authMode: 'AMAZON_COGNITO_USER_POOLS'
            })
            // console.log(result);
            notification.success({
                message: 'Updated successfully'
            })
            getIncidentCommentRecords(id)
            form.setFieldsValue({
                comment: ''
            })
            setDisabledSubmit(true)
            setPristine()
        }
        catch (error) {
            console.log('error:', error);
            notification.error({
                message: 'Unable to create comment'
            })
            setLoadingCommentList(false)
        }
    }

    const updateIncidentReportRecord = async (id) => {
        const user = JSON.parse(localStorage.getItem('isap_cognitouser_site'))
        const profiles = JSON.parse(user.signInUserSession.idToken.payload.profiles)
        // console.log("user", user);
        // console.log("profiles", profiles);
        try {
            let incidentDetails = {
                updateIncidentReport: {
                    id: id,
                    securityAgencyID: appState.agencyID,
                    securitySiteID: incident.securitySiteID,
                    siteProfileID: incident.siteProfileID,
                    status: 'CLOSED',
                    closedBy: user.username,
                    closedByStaffProfileID: profiles[0].staffProfileID,
                },
                updateIncidentDetail: { id: 0 }
            }
            // console.log("incidentDetails", incidentDetails);
            const result = await API.graphql({
                query: submitIncident,
                variables: incidentDetails,
                authMode: 'AMAZON_COGNITO_USER_POOLS'
            })
            // console.log("result", result);
            notification.success({
                message: 'Updated successfully'
            })
            getIncidentRecord(result.data.result.id)
            getIncidentCommentRecords(result.data.result.id)
            // history.push(path("incidentReport", [result.data.result.id]))
        }
        catch (error) {
            console.log('error:', error);
            notification.error({
                message: 'Unable to update incident report'
            })
            setLoading(false)
            setLoadingCommentList(false)
        }
    }

    useEffect(() => {
        getIncidentRecord(props.match.params.id)
        getIncidentCommentRecords(props.match.params.id)
        getIncidentFileRecords(props.match.params.id)

        actionSetPageTitle('Incident Report', true, path('incident'))

        return () => {
            actionSetPageTitle('', false, '')
        }
    }, [])

    useEffect(() => {
        // if (Object.keys(incident).length !== 0) {
        if (incident !== -1 && appState.fixedListLoaded === true) {
            let siteDisplay = ''
            for (let i = 0; i < appState.siteList.length; i++) {
                if (incident.siteProfileID === appState.siteList[i].id) {
                    siteDisplay = appState.siteList[i].name
                    break
                }
            }
            let incidentTypeDisplay = ''
            for (let i = 0; i < appState.incidentTypeList.length; i++) {
                if (incident.type === appState.incidentTypeList[i].id) {
                    incidentTypeDisplay = appState.incidentTypeList[i].name
                    break
                }
            }

            setDisplay({
                ...incident,
                siteDisplay,
                incidentTypeDisplay,
                createdOnDisplay: moment(new Date(incident.createdOn.replace(/-/g, '/') + ' UTC')).format('DD MMM YYYY (ddd), HH:mm'),
                updatedOnDisplay: moment(new Date(incident.updatedOn.replace(/-/g, '/') + ' UTC')).format('DD MMM YYYY (ddd), HH:mm'),
                // incidentDatetimeStartDisplay: moment(new Date(incident.incidentDatetimeStart.replace(/-/g, "/") + " UTC")).format('DD MMM YYYY (ddd), HH:mm'),
                // incidentDatetimeEndDisplay: incident.incidentDatetimeEnd !== "" ? moment(new Date(incident.incidentDatetimeEnd.replace(/-/g, "/") + " UTC")).format('DD MMM YYYY (ddd), HH:mm') : "",
                incidentDatetimeStartDisplay: moment(new Date(incident.incidentStart.replace(/-/g, '/') + ' UTC')).format('DD MMM YYYY (ddd), HH:mm'),
                incidentDatetimeEndDisplay: incident.incidentEnd !== '' ? moment(new Date(incident.incidentEnd.replace(/-/g, '/') + ' UTC')).format('DD MMM YYYY (ddd), HH:mm') : '',
                statusDisplay: incident.status.slice(0, 1).toUpperCase() + incident.status.slice(1).toLowerCase(),
                statusColour: incident.status === 'CLOSED' ? 'green' : 'orange'
            })
            setLoading(false)
        }
    }, [incident, appState.fixedListLoaded])

    useEffect(() => {
        if (commentList !== -1) {
            let array = []
            for (let i = 0; i < commentList.length; i++) {
                array.push({
                    ...commentList[i],
                    updatedOnDisplay: moment(new Date(commentList[i].updatedOn.replace(/-/g, '/') + ' UTC')).format('DD MMM YYYY (ddd), HH:mm'),
                })
            }
            setDisplayCommentList(array)
            setLoadingCommentList(false)
        }
    }, [commentList])

    const onFinish = (values) => {
        // console.log("on finish", values);
        // console.log("appState", appState);
        createIncidentReportComment(props.match.params.id, values)
    }

    const handleCloseCaseClick = () => {
        // console.log("close case");
        setLoading(true)
        setLoadingCommentList(true)
        updateIncidentReportRecord(props.match.params.id)
    }

    const handleEditClick = () => {
        history.push(path('incidentReportEdit', [props.match.params.id]))
    }

    const handleFormChange = (e) => {
        // console.log(e.target.value);
        if (e.target.value.length !== 0) {
            setDirty()
            setDisabledSubmit(false)
        }
        else {
            setPristine()
            setDisabledSubmit(true)
        }
    }

    const renderImages = (array) => {
        const images = array.map((item) => {
            // console.log("item", item);
            return (
                <div key={item.id}>
                    <Image
                        width={100}
                        src={item.src !== null ? item.src : ''}
                        fallback={imgEmptyImage192}
                    />
                    &nbsp;&nbsp;
                </div>
            )
        })
        return images
    }

    const renderUploadImages = (array) => {
        return (
            (array.length === 0) ? (
                <Empty image={Empty.PRESENTED_IMAGE_SIMPLE} />
            ) : (
                <div className="row">
                    <Image.PreviewGroup>
                        {renderImages(array)}
                    </Image.PreviewGroup>
                </div>
            )
        )
    }

    // const renderUploadImagesReport = (array) => {
    //     // console.log("array", array);
    //     return (
    //         (array.length === 0) ? (
    //             <TableCell weighting={0.8078} style={[styles.tablecell, styles.tablecellvalue]}>No Image</TableCell>
    //         ) : (
    //             // <Text>Images</Text>
    //             <TableCell weighting={0.8078} style={[styles.tablecell, styles.tablecellvalue, styles.rows]}>
    //                 {
    //                     array.map((item) => {
    //                         // console.log("item", item);
    //                         return (
    //                             <ImageReactPDF style={{ width: "150px", marginBottom: "5px" }} src={{ uri: item.src, method: "GET", headers: { "Cache-Control": "no-cache" }, body: "" }} />
    //                         )
    //                     })
    //                 }
    //             </TableCell>
    //         )
    //     )
    // }

    const renderCommentList = (array) => {
        // console.log("array", array);
        return (
            (array.length === 0) ? (
                <div />
            ) : (
                <List
                    className="comment-list"
                    header={`${displayCommentList.length} ${displayCommentList.length === 1 ? 'comment' : 'comments'}`}
                    itemLayout="horizontal"
                    dataSource={displayCommentList}
                    renderItem={item => (
                        <li>
                            <Comment
                                actions={item.actions}
                                author={item.staffProfileName}
                                avatar={item.avatar}
                                content={item.comment}
                                datetime={item.updatedOnDisplay}
                            />
                        </li>
                    )}
                />
            )
        )
    }

    // const renderCommentListReport = (array) => {
    //     return (
    //         (array.length === 0) ? (
    //             <TableReactPDF data={[array]}>
    //                 <TableBody>
    //                     <TableCell style={styles.tablecell}>No Comment</TableCell>
    //                 </TableBody>
    //             </TableReactPDF>
    //         ) : (
    //             array.map((item) => {
    //                 console.log(item);
    //                 return (
    //                     <TableReactPDF data={[item]}>
    //                         <TableBody>
    //                             <TableCell weighting={0.1922} style={styles.tablecell}>
    //                                 {item.staffProfileName}
    //                             </TableCell>
    //                             <TableCell weighting={0.8078} style={styles.tablecell}>
    //                                 {item.comment + "\n\n " + item.updatedOnDisplay}
    //                             </TableCell>
    //                         </TableBody>
    //                     </TableReactPDF>
    //                 )
    //             })
    //         )
    //     )
    // }

    // const styles = StyleSheet.create({
    //     page: {
    //         fontSize: "12px",
    //         padding: "16px",
    //     },
    //     row: {
    //         display: "flex",
    //         flexDirection: "row"
    //     },
    //     title: {
    //         fontSize: "18px",
    //         fontWeight: "700",
    //         textAlign: "center",
    //         marginBottom: "16px"
    //     },
    //     text: {
    //         marginBottom: "5px"
    //     },
    //     tablecell: {
    //         padding: "5px",
    //         fontSize: "10px",
    //     },
    //     tablecellvalue: {
    //         fontWeight: "bold",
    //     }
    // })

    // const MyDocument = (props) => {
    //     const { incident, commentList, imageListSource } = props
    //     console.log("incident", incident);
    //     console.log("commentList", commentList);
    //     console.log("imageListSource", imageListSource);
    //     return (
    //         <Document>
    //             <Page size="A4" style={styles.page}>
    //                 <ImageReactPDF style={{ width: "120px", marginBottom: "16px" }} src={imgInimicodeLogo} />
    //                 <View style={styles.title}>
    //                     <Text>{appState.profileAgency.securityAgencyName} - Incident Report</Text>
    //                 </View>
    //                 <TableReactPDF data={[incident]}>
    //                     <TableBody>
    //                         <TableCell weighting={0.2} style={styles.tablecell}>Report UID</TableCell>
    //                         <DataTableCell weighting={0.4} style={[styles.tablecell, styles.tablecellvalue]} getContent={(r) => r.uid + "\n" + "Last Updated: " + r.updatedOnDisplay + "\n" + "Created On: " + r.createdOnDisplay} />
    //                         <TableCell weighting={0.1} style={styles.tablecell}>Site</TableCell>
    //                         <DataTableCell weighting={0.3} style={[styles.tablecell, styles.tablecellvalue]} getContent={(r) => r.siteDisplay} />
    //                     </TableBody>
    //                 </TableReactPDF>
    //                 <TableReactPDF data={[incident]}>
    //                     <TableBody>
    //                         <TableCell weighting={0.2} style={styles.tablecell}>Incident Type</TableCell>
    //                         <DataTableCell weighting={0.4} style={[styles.tablecell, styles.tablecellvalue]} getContent={(r) => r.incidentTypeDisplay} />
    //                         <TableCell weighting={0.1} style={styles.tablecell}>Status</TableCell>
    //                         <DataTableCell weighting={0.3} style={[styles.tablecell, styles.tablecellvalue]} getContent={(r) => r.statusDisplay} />
    //                     </TableBody>
    //                 </TableReactPDF>
    //                 <TableReactPDF data={[incident]}>
    //                     <TableBody>
    //                         <TableCell weighting={0.1922} style={styles.tablecell}>Subject</TableCell>
    //                         <DataTableCell weighting={0.8078} style={[styles.tablecell, styles.tablecellvalue]} getContent={(r) => r.title} />
    //                     </TableBody>
    //                 </TableReactPDF>
    //                 <TableReactPDF data={[incident]}>
    //                     <TableBody>
    //                         <TableCell weighting={0.1922} style={styles.tablecell}>Incident Datetime</TableCell>
    //                         <DataTableCell weighting={0.8078} style={[styles.tablecell, styles.tablecellvalue]} getContent={(r) => r.incidentDatetimeStartDisplay + " - " + r.incidentDatetimeEndDisplay} />
    //                     </TableBody>
    //                 </TableReactPDF>
    //                 <TableReactPDF data={[incident]}>
    //                     <TableBody>
    //                         <TableCell weighting={0.1922} style={styles.tablecell}>Incident Location</TableCell>
    //                         <DataTableCell weighting={0.8078} style={[styles.tablecell, styles.tablecellvalue]} getContent={(r) => r.location} />
    //                     </TableBody>
    //                 </TableReactPDF>
    //                 <TableReactPDF data={[incident]}>
    //                     <TableBody>
    //                         <TableCell weighting={0.1922} style={styles.tablecell}>Incident Description</TableCell>
    //                         <DataTableCell weighting={0.8078} style={[styles.tablecell, styles.tablecellvalue]} getContent={(r) => r.description} />
    //                     </TableBody>
    //                 </TableReactPDF>
    //                 <TableReactPDF data={[incident]}>
    //                     <TableBody>
    //                         <TableCell weighting={0.1922} style={styles.tablecell}>Actions Taken</TableCell>
    //                         <DataTableCell weighting={0.8078} style={[styles.tablecell, styles.tablecellvalue]} getContent={(r) => r.action} />
    //                     </TableBody>
    //                 </TableReactPDF>
    //                 <TableReactPDF data={[incident]}>
    //                     <TableBody>
    //                         <TableCell weighting={0.1922} style={styles.tablecell}>Remarks</TableCell>
    //                         <DataTableCell weighting={0.8078} style={[styles.tablecell, styles.tablecellvalue]} getContent={(r) => r.remarks} />
    //                     </TableBody>
    //                 </TableReactPDF>
    //                 <TableReactPDF data={[imageListSource]}>
    //                     <TableBody>
    //                         <TableCell weighting={0.1922} style={styles.tablecell}>Uploaded Image(s)</TableCell>
    //                         {renderUploadImagesReport(imageListSource)}
    //                     </TableBody>
    //                 </TableReactPDF>
    //                 <TableReactPDF data={[incident]}>
    //                     <TableBody>
    //                         <TableCell weighting={0.2} style={styles.tablecell}>Submitted By</TableCell>
    //                         <DataTableCell weighting={0.4} style={[styles.tablecell, styles.tablecellvalue]} getContent={(r) => r.submittedByStaffName} />
    //                         <TableCell weighting={0.1} style={styles.tablecell}>Closed By</TableCell>
    //                         <DataTableCell weighting={0.3} style={[styles.tablecell, styles.tablecellvalue]} getContent={(r) => r.closedByStaffName} />
    //                     </TableBody>
    //                 </TableReactPDF>
    //                 <View style={{ marginBottom: "16px" }}></View>
    //                 {/* Comments here */}
    //                 <TableReactPDF data={[commentList]}>
    //                     <TableBody>
    //                         <TableCell style={styles.tablecell}>{commentList.length + " comment(s)"}</TableCell>
    //                     </TableBody>
    //                 </TableReactPDF>
    //                 {renderCommentListReport(commentList)}
    //             </Page>
    //         </Document>
    //     )
    // }

    return (
        <div className="container-content allow-overflow">
            <Spin spinning={loading}>
                <div className="content-content">
                    <Descriptions
                        title={
                            <div className="row spaceBetween">
                                <div>
                                    <span>{appState.profileAgency.securityAgencyName}</span> <Tag>Security Site</Tag>
                                </div>
                                {/* <Button onClick={handleEditClick} shape={appState.broken === true ? 'circle' : ''} icon={<EditOutlined />}>
                                    {appState.broken === true ? '' : 'Edit'}
                                </Button> */}
                            </div>
                        }
                        bordered
                        column={{ xs: 1, sm: 2 }}
                        // extra={
                        //     <Space>
                        //         <Button onClick={handleEditClick} shape={appState.broken === true ? 'circle' : ''} icon={<EditOutlined />}>
                        //             {appState.broken === true ? '' : 'Edit'}
                        //         </Button>
                        //         {/* {
                        //             Object.keys(display).length !== 0 ? (
                        //                 <Button type="default">
                        //                     <PDFDownloadLink document={<MyDocument incident={display} commentList={displayCommentList} imageListSource={imageListSource} />} fileName={"Incident Report_" + display.siteDisplay + "_" + display.title + ".pdf"}>
                        //                         {({ blob, url, loading, error }) =>
                        //                             loading ? '...' : 'Export'
                        //                         }
                        //                     </PDFDownloadLink>
                        //                 </Button>
                        //             ) : ""
                        //         } */}
                        //     </Space>
                        // }
                    >
                        <Descriptions.Item label="Report UID">
                            {display.uid}
                            <div className="sub-italic">
                                {
                                    display.createdOnDisplay !== display.updatedOnDisplay ? (
                                        <span>
                                            Last updated: {display.updatedOnDisplay}
                                            <br />
                                            Created On: {display.createdOnDisplay}
                                        </span>
                                    ) : (
                                        <span>Created On: {display.createdOnDisplay}</span>
                                    )
                                }
                            </div>
                        </Descriptions.Item>
                        <Descriptions.Item label="Site">{display.siteDisplay}</Descriptions.Item>
                        <Descriptions.Item label="Incident Type">{display.incidentTypeDisplay}</Descriptions.Item>
                        <Descriptions.Item label="Status">
                            <Tag color={display.statusColour}>
                                {display.statusDisplay}
                            </Tag>
                        </Descriptions.Item>
                        <Descriptions.Item label="Subject" span={2}>{display.title}</Descriptions.Item>
                        <Descriptions.Item label="Incident Datetime" span={2}>{display.incidentDatetimeStartDisplay + ' - ' + display.incidentDatetimeEndDisplay}</Descriptions.Item>
                        {/* <Descriptions.Item label="Incident Datetime Start">{display.incidentDatetimeStartDisplay}</Descriptions.Item>
                        <Descriptions.Item label="Incident Datetime End">{display.incidentDatetimeEndDisplay}</Descriptions.Item> */}
                        <Descriptions.Item label="Incident Location" span={2}>{display.location}</Descriptions.Item>
                        {/* <Descriptions.Item label="Incident Description" span={2}>{display.incidentDescription}</Descriptions.Item>
                        <Descriptions.Item label="Actions Taken" span={2}>{display.actionsTaken}</Descriptions.Item>
                        <Descriptions.Item label="Remarks" span={2}>{display.remarks}</Descriptions.Item>
                        <Descriptions.Item label="Upload Image(s)" span={2}>
                            renderUploadImages()
                        </Descriptions.Item>
                        <Descriptions.Item label="Submitted By">{display.submittedBy}</Descriptions.Item> */}
                    </Descriptions>
                    <Descriptions
                        bordered
                        column={{ xs: 1, sm: 2 }}
                        layout="vertical"
                    >
                        <Descriptions.Item label="Incident Description" span={2}>{display.description}</Descriptions.Item>
                        <Descriptions.Item label="Actions Taken" span={2}>{display.action}</Descriptions.Item>
                        <Descriptions.Item label="Remarks" span={2}>{display.remarks}</Descriptions.Item>
                        <Descriptions.Item label="Upload Image(s)" span={2}>
                            <Spin spinning={loadingImageList}>
                                {/* {display.imagesKey} */}
                                {/* {renderUploadImages(display.imagesKey)} */}
                                {/* {renderUploadImages(display.imagesKey !== undefined ? JSON.parse(display.imagesKey) : [])} */}
                                {renderUploadImages(imageListSource)}
                            </Spin>
                        </Descriptions.Item>
                    </Descriptions>
                    <Descriptions
                        bordered
                        column={{ xs: 1, sm: 2 }}
                    >
                        <Descriptions.Item label="Submitted By">{display.submittedByStaffName}</Descriptions.Item>
                        {
                            incident.status === 'CLOSED' ?
                                (
                                    <Descriptions.Item label="Closed By">{display.closedByStaffName}</Descriptions.Item>
                                ) : ''
                        }
                    </Descriptions>
                </div>
            </Spin>
            <Spin spinning={loadingCommentList}>
                <div className="content-content">
                    {renderCommentList(displayCommentList)}
                    {
                        incident.status === 'CLOSED' ? ('') : (
                            <div>
                                <Form
                                    form={form}
                                    layout="vertical"
                                    onFinish={onFinish}
                                    validateMessages={validateMessages}
                                    requiredMark={false}
                                >
                                    <Form.Item
                                        label="Comment"
                                        name="comment"
                                        rules={[
                                            {
                                                required: true,
                                            },
                                        ]}
                                    >
                                        <TextArea disabled={incident.status === 'CLOSED' ? true : false} rows={4} autoComplete="off" placeholder="Enter comment" onChange={handleFormChange} />
                                    </Form.Item>
                                    <div className="button">
                                        <Popconfirm title={
                                            <div>
                                                Are you sure?<br />
                                                No amendments can be made once comment is submitted.
                                            </div>
                                        } onConfirm={() => onFinish(form.getFieldsValue())} okText="Yes" cancelText="No" disabled={disabledSubmit}>
                                            {/* <Button type="primary" htmlType="submit" disabled={form.getFieldValue("comment") === undefined || form.getFieldValue("comment") === "" ? true : false}>Submit</Button> */}
                                            <Button type="primary" htmlType="submit" disabled={disabledSubmit}>Submit</Button>
                                        </Popconfirm>
                                    </div>
                                </Form>
                                {/* {
                                    incident.status === 'CLOSED' ? '' :
                                        (
                                            <div>
                                                <br /><br /><br />
                                                <Popconfirm title={
                                                    <div>
                                                        Are you sure?<br />
                                                        No amendments can be made to this incident once the case is closed.
                                                    </div>
                                                } onConfirm={handleCloseCaseClick} okText="Yes" cancelText="No">
                                                    <Button type="primary" style={{ backgroundColor: '#D0342C', border: '#D0342C' }}>Close Case</Button>
                                                </Popconfirm>
                                            </div>
                                        )
                                } */}
                            </div>
                        )
                    }
                </div>
            </Spin>
            {Prompt}
            {/* {
                Object.keys(display).length !== 0 ? (
                    <PDFViewer width="1000" height="600">
                        <MyDocument incident={display} commentList={displayCommentList} imageListSource={imageListSource} />
                    </PDFViewer>
                ) : ""
            } */}
        </div>
    )
}

export default IncidentReport