import React, { useContext, useEffect, useState } from "react"
import { Button, Checkbox, Form, Input, notification, Spin, Tabs } from "antd"
import { UserOutlined, LockOutlined } from "@ant-design/icons"
import { Link, useHistory } from "react-router-dom"
import { Auth } from "@aws-amplify/auth"

import { AppContext } from '../contexts/AppContext'

import Logo from "../media/logo-inimicode-light-155x31.png"
import path from "../utils/pathSettings"

const Login = (props) => {
    const { appState, actionLogin } = useContext(AppContext)
    const { from } = props.location.state || { from: "/" }
    const history = useHistory()
    const [form] = Form.useForm();
    const [loading, setLoading] = useState(false)
    const validateMessages = {
        required: "This field is required.",
        types: {
            email: "This is not a valid email.",
        },
    }

    useEffect(() => {
        if (appState.validUserLogin === true && appState.validSiteSelection === false) {
            history.push(path('siteSelection'))
        } else {
            if (localStorage.getItem("isap_remember_site") !== null) {
                form.setFieldsValue({
                    username: localStorage.getItem("isap_remember_site"),
                    remember: true,
                })
            }
            else {
                form.setFieldsValue({
                    remember: false
                })
            }
        }
        return () => { }
    }, [])

    const onFinish = async (values) => {
        // console.log("onFinish", values);
        setLoading(true)
        // console.log("username is number", !isNaN(Number(values.username)));
        let username = values.username
        if (!isNaN(Number(values.username)) === true && values.username.length === 8) {
            username = "+65" + values.username
        }
        try {
            const user = await Auth.signIn(username, values.password)
            // console.log(user);
            const cognitoGroups = user.signInUserSession.idToken.payload["cognito:groups"]
            // console.log("cognitoGroups", cognitoGroups);
            const profiles = JSON.parse(user.signInUserSession.idToken.payload.profiles)
            // console.log("profiles", profiles);

            let platformPermission = false

            let profile = profiles.find(s => s.securityAgencyID === appState.agencyID);
            // console.log(profile);

            if (profile && profile.staffProfileID > 0) {
                platformPermission = true;
            }

            if (platformPermission) {
                if (values.remember === true) {
                    localStorage.setItem("isap_remember_site", username)
                }
                actionLogin(user)
                history.push(path("siteSelection"))
            }
            else {
                try {
                    await Auth.signOut();
                } catch (error) {
                    console.log('error signing out: ', error);
                }
                notification.error({
                    message: "Not a registered account"
                })
                setLoading(false)
            }

        }
        catch (error) {
            console.log("error signing in: ", error);
            notification.error({
                message: error.message
            })
            setLoading(false)
        }

        // const timer = setTimeout(() => {
        //     setLoading(false)
        //     clearTimeout(timer)
        // }, 1000)
    }

    const handleCheckChange = (e) => {
        // console.log("handleCheckChange");
        if (form.getFieldValue("remember") === false) {
            localStorage.removeItem("isap_remember_site")
        }
    }

    return (
        <div className="login">
            <div className="container-login">
                <div className="login-body">
                    <div className="login-header">
                        <img src={Logo} />
                    </div>
                    <div className="login-subheader">
                        ISAP: Site Portal
                    </div>
                    <div className="login-content">
                        <Spin spinning={loading}>
                            <Form
                                form={form}
                                name="normal_login"
                                className="login-form"
                                onFinish={onFinish}
                                validateMessages={validateMessages}
                            >
                                <Form.Item
                                    name="username"
                                    rules={[
                                        {
                                            required: true,
                                        },
                                    ]}
                                >
                                    <Input autoComplete="off" prefix={<UserOutlined className="site-form-item-icon" />} placeholder="Phone number, username, or email" />
                                </Form.Item>
                                <Form.Item
                                    name="password"
                                    rules={[
                                        {
                                            required: true,
                                            message: 'Please input your Password.',
                                        },
                                    ]}
                                >
                                    <Input.Password
                                        prefix={<LockOutlined className="site-form-item-icon" />}
                                        type="password"
                                        placeholder="Password"
                                    />
                                </Form.Item>
                                <Form.Item>
                                    <Form.Item name="remember" valuePropName="checked" noStyle>
                                        <Checkbox onChange={handleCheckChange}>Remember me</Checkbox>
                                    </Form.Item>
                                    <Link className="login-form-forgot" to="/forgotpassword">
                                        Forgot password
                                    </Link>
                                </Form.Item>

                                <Form.Item>
                                    <Button type="primary" htmlType="submit" className="login-form-button">
                                        Log In
                                    </Button>
                                </Form.Item>
                            </Form>
                        </Spin>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default Login