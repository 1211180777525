import React, { useContext, useEffect, useState } from 'react'
import { CloseOutlined } from '@ant-design/icons';

import { AppContext } from '../contexts/AppContext'

const FilterAppliedAttendance = (props) => {
    const { appState } = useContext(AppContext)
    const approvalPage = props.approvalPage !== undefined ? props.approvalPage : false
    const checkpointSitePage = props.checkpointSitePage !== undefined ? props.checkpointSitePage : false
    const onDutyPage = props.onDutyPage !== undefined ? props.onDutyPage : false
    const [values, setValues] = useState({})
    const [display, setDisplay] = useState({})
    const [visibility, setVisibility] = useState(false)

    useEffect(() => {
        setValues(props.values)

        let dateRange = ''
        if (!approvalPage && !checkpointSitePage && !onDutyPage && props.values.dateRange.length === 2) {
            dateRange = props.values.dateRange[0].format('DD MMM YYYY') + ' - ' + props.values.dateRange[1].format('DD MMM YYYY')
        }

        let site = ''
        if (props.values.site !== 0) {
            site = appState?.siteList?.find(s => s.id == props.values.site)?.name;
            // for (let i = 0; i < appState.siteList.length; i++) {
            //     if (props.values.site === appState.siteList[i].securitySiteID) {
            //         site = appState.siteList[i].name
            //         break
            //     }
            // }
        }

        let approvalStatus = ''
        if (!approvalPage && props.values.approvalStatus !== 0) {
            approvalStatus = props.values.approvalStatus
        }

        if (props.values.search === '' && dateRange === '' && site === '' && approvalStatus === '') {
            setVisibility(false)
        }
        else {
            setVisibility(true)
        }

        setDisplay({
            search: props.values.search,
            dateRange: dateRange,
            site: site,
            approvalStatus: approvalStatus,
        })

    }, [props])

    const handlefilterClick = (e, id) => {
        props.onFinish({
            ...values,
            [id]: id === 'search' ? ('') : (0)
            // [id]: id === "search" ? ("") : (id === "dateRange" ? ([]) : (0))
        })
    }

    return (
        <div>
            {
                visibility === true ? (
                    <div className="filter-applied">
                        {
                            approvalPage === false && checkpointSitePage === false && onDutyPage === false ? (
                                <div className="filter-applied-item">
                                    Time In Date Range: <b>{display.dateRange}</b>
                                </div>
                            ) : (
                                <div />
                            )
                        }
                        {
                            display.search !== undefined && display.search !== '' ? (
                                <div className="filter-applied-item">
                                    Search: <b>{display.search}</b>&nbsp;
                                    <span className="close" onClick={(e) => handlefilterClick(e, 'search')}>
                                        <CloseOutlined />
                                    </span>
                                </div>
                            ) : (
                                <div />
                            )
                        }
                        {
                            display.site !== '' ? (
                                <div className="filter-applied-item">
                                    Site: <b>{display.site}</b>&nbsp;
                                    {/* <span className="close" onClick={(e) => handlefilterClick(e, 'site')}>
                                        <CloseOutlined />
                                    </span> */}
                                </div>
                            ) : (
                                <div />
                            )
                        }
                        {
                            approvalPage === false && checkpointSitePage === false && onDutyPage === false && display.approvalStatus !== '' ? (
                                <div className="filter-applied-item">
                                    Approval Status: <b>{display.approvalStatus}</b>&nbsp;
                                    <span className="close" onClick={(e) => handlefilterClick(e, 'approvalStatus')}>
                                        <CloseOutlined />
                                    </span>
                                </div>
                            ) : (
                                <div />
                            )
                        }
                    </div>
                ) : (
                    <div />
                )
            }

        </div>
    )
}

export default FilterAppliedAttendance