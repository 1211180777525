import React, { useContext, useEffect, useState } from 'react'
import { useHistory } from 'react-router-dom'
import { Button, Descriptions, Empty, notification, Space, Spin, Table, Tag } from 'antd'
import moment from 'moment'
import API from '@aws-amplify/api'

import { listVisitorRecord } from '../backend/graphql/queries'
import { AppContext } from '../contexts/AppContext'
import CardVisitor from './Component-Card-Visitor'
import FilterAppliedVisitor from './Component-FilterApplied-Visitor'
import FilterVisitor from './Component-Filter-Visitor'
import { PlusOutlined } from '@ant-design/icons'
import path from '../utils/pathSettings'

const Visitor = () => {
    const { appState, actionSetPageTitle } = useContext(AppContext)
    const history = useHistory()
    const [table, setTable] = useState({
        data: -1,
        pagination: {
            current: 1,
            pageSize: 20,
            offset: 0,
            defaultPageSize: 20,
            showSizeChanger: true,
        },
        loading: false
    })
    const [displayList, setDisplayList] = useState([])
    const [values, setValues] = useState({
        search: '',
        dateRange: [],
        site: 0,
    })

    const getVisitorRecords = async (props, values, forExport = false) => {
        setTable({
            ...table,
            loading: true
        })
        
        try {
            let filter = {
                securityAgencyID: {
                    // eq: profiles[0].securityAgencyID // current fixed to first profile, need to have an attribute to store last agency profile
                    eq: appState.agencyID
                },
                and: [
                    {
                        inTimestamp: { ge: values.dateRange[0].toISOString().split('.')[0] }
                    },
                    {
                        inTimestamp: { le: values.dateRange[1].toISOString().split('.')[0] }
                    },
                ],
                siteProfileID: { eq: appState.siteProfile.siteProfileID },
            }

            if (values.search !== '') {
                filter = {
                    ...filter,
                    or: [
                        { type: { contains: values.search } },
                        { name: { contains: values.search } },
                        { phoneNo: { contains: values.search } },
                        { purpose: { contains: values.search } },
                        { block: { contains: values.search } },
                        { unit: { contains: values.search } },
                        { vehicleNo: { contains: values.search } },
                        { remarks: { contains: values.search } }
                    ]
                }
            }

            if (values.site !== 0) {
                filter = {
                    ...filter,
                    siteProfileID: { eq: values.site }
                }
            }

            const variables = {
                pagination: {
                    orderby: '`inTimestamp` ASC',
                    agencyID: appState.agencyID
                },
                filter: filter
            }
            if (!forExport) {
                variables.pagination = {
                    ...variables.pagination,
                    limit: props.pageSize,
                    offset: props.offset,
                }
            }
            // console.log("listVisitorDetails", listVisitorDetails);
            const result = await API.graphql({
                query: listVisitorRecord,
                variables: variables,
                authMode: 'AMAZON_COGNITO_USER_POOLS'
            })
            // console.log("result", result);
            const data = result.data.result
            // console.log("data", data);

            setTable({
                ...table,
                data: data.result,
                loading: data.result.length === 0 ? false : true,
                pagination: {
                    ...table.pagination,
                    current: props.current,
                    offset: props.offset,
                    pageSize: props.pageSize,
                    total: props.offset >= data.count ? data.count + 1 : data.count // keeps the last pagination if it is the last record
                }
            })
        }
        catch (error) {
            console.log('error:', error);
            notification.error({
                message: 'Unable to retrieve visitor records'
            })
            setTable({
                ...table,
                loading: false,
            })
        }

        // const timer = setTimeout(() => {
        //     setTable({
        //         ...table,
        //         data: dataState.visitorRecords
        //     })
        //     clearTimeout(timer)
        // }, 1000)
    }

    useEffect(() => {
        // console.log(moment());
        const currentDatetime = moment()
        let _values = {}
        if (sessionStorage.getItem('isap_site_visitor_records') !== null) {
            // console.log(moment(JSON.parse(sessionStorage.getItem("isap_site_visitor_records")).dateRange[0]));
            _values = {
                ...values,
                ...JSON.parse(sessionStorage.getItem('isap_site_visitor_records')),
                site: appState.siteProfile.siteProfileID,
                dateRange: [
                    moment(JSON.parse(sessionStorage.getItem('isap_site_visitor_records')).dateRange[0]),
                    moment(JSON.parse(sessionStorage.getItem('isap_site_visitor_records')).dateRange[1]),
                ]
            }
        }
        else {
            _values = {
                ...values,
                site: appState.siteProfile.siteProfileID,
                dateRange: [
                    moment(new Date(currentDatetime.format('YYYY-MM-DD 00:00:00'))).subtract(1, 'days'),
                    moment(new Date(currentDatetime.format('YYYY-MM-DD 23:59:59'))),
                ]
            }
        }
        setValues(_values)
        getVisitorRecords(table.pagination, _values)

        actionSetPageTitle('Visitor')

        return () => {
            actionSetPageTitle('')
        }
    }, [])

    useEffect(() => {
        if (table.data !== -1 && appState.fixedListLoaded === true) {
            let array = tabulateDisplayListData(table)

            setDisplayList(array)
            setTable({
                ...table,
                loading: false
            })
        }
    }, [table.data, appState.fixedListLoaded])

    const tabulateDisplayListData = (_table) => {
        let array = []
        // for (let i = 0; i < _table?.data?.length; i++) {
        //     let data = _table.data[i];

        for (let data of _table?.data) {

            let object = {
                ...data,
                siteName: appState?.siteList?.find(s => s.id === data.siteProfileID)?.name,
                datetimeObj: moment.utc(data.datetime).local(),
                datetimeDisplay: moment.utc(data.datetime).local().format('DD MMM YYYY (ddd), HH:mm'),
                strikethrough: data.cancelled
            }
            array.push(object)
        }

        return array;
    }

    const handleTableChange = (paginate) => {
        getVisitorRecords({
            ...table.pagination,
            current: paginate.current,
            pageSize: paginate.pageSize,
            offset: paginate.current * paginate.pageSize - paginate.pageSize,
        })
    }

    const handleScoll = (e) => {
        // console.log("scroll height: ", e.target.scrollHeight);
        // console.log("scroll top", e.target.scrollTop);
        // console.log("client height", e.target.clientHeight);
        const atTop = e.target.scrollTop === 0
        const atBottom = e.target.scrollHeight - e.target.scrollTop === e.target.clientHeight
        if (atBottom) {
            // console.log("BOTTOM");
        }
        else if (atTop) {
            // console.log("TOP");
        }
    }

    const handleCreateClick = () => {
        history.push(path('visitorCreate'))
    }

    const onFinish = (values) => {
        console.log('on finish', values);
        let _values = {
            ...values,
            dateRange: [
                moment(values.dateRange[0].format('YYYY-MM-DD 00:00:00')),
                moment(values.dateRange[1].format('YYYY-MM-DD 23:59:59')),
            ]
        }
        sessionStorage.setItem('isap_site_visitor_records', JSON.stringify(_values))
        setValues(_values)
        getVisitorRecords(table.pagination, _values)
    }

    const columns = [
        {
            title: 'Site',
            dataIndex: 'siteName'
        },
        {
            title: 'Datetime',
            dataIndex: 'inTimestamp',
            render: (text) => {
                return (
                    moment.utc(text).local().format('DD MMM YYYY (ddd), HH:mm')
                    // moment(new Date(text.replace(/-/g, "/") + " UTC")).format('DD MMM YYYY (ddd), HH:mm')
                )
            }
        },
        {
            title: 'Visitor Name',
            dataIndex: 'name'
        },
        {
            title: 'Visitor Contact Number',
            dataIndex: 'phoneNo'
        },
        {
            title: 'Purpose of Visit',
            dataIndex: 'purpose'
        },
        {
            title: 'Block',
            dataIndex: 'block',
            weighting: 0.05,
        },
        {
            title: 'Unit',
            dataIndex: 'unit',
            weighting: 0.05,
        },
        {
            title: 'Vehicle Number',
            dataIndex: 'vehicleNo'
        },
        {
            title: 'Remarks',
            dataIndex: 'remarks',
            weighting: 0.2,
        },
        {
            title: 'Submission Type',
            dataIndex: 'type',
            weighting: 0.08,
            // render: (text, record) => {
            //     return (
            //         text !== "QR" ? text.slice(0, 1).toUpperCase() + text.slice(1).toLowerCase() : text
            //     )
            // }
        },
    ]

    const renderCards = (array) => {
        const cards = array.map((item) => {
            console.log(item)
            return (
                <CardVisitor item={item} key={item.id} />
            )
        })
        return cards
    }

    const renderCardVisitorRecords = (array) => {
        return (
            (array.length === 0) ? (
                <Empty image={Empty.PRESENTED_IMAGE_SIMPLE} />
            ) : (
                renderCards(array)
            )
        )
    }


    return (
        <div className="container-content allow-overflow">
            <div className="content-content">
                <Descriptions
                    title={
                        <div className="row spaceBetween">
                            <div>
                                <span>{appState.profileAgency.securityAgencyName}</span> <Tag>Visitor</Tag>
                            </div>
                        </div>
                    }
                    extra={
                        <Space>
                            <Button onClick={handleCreateClick} type="primary" shape={appState.broken === true ? 'circle' : ''} icon={<PlusOutlined />}>
                                {appState.broken === true ? '' : 'Create'}
                            </Button>
                        </Space>
                    } />
                <FilterVisitor onFinish={onFinish} values={values} />
                <FilterAppliedVisitor onFinish={onFinish} values={values} />
            </div>
            <Table
                className="above-md"
                columns={columns}
                rowKey={record => record.id}
                dataSource={displayList}
                pagination={table.pagination}
                loading={table.loading}
                scroll={{ x: 1200, y: '55vh' }}
                onChange={handleTableChange}
            />
            <div className="below-md container-flexgrow" onScroll={handleScoll}>
                <Spin spinning={table.loading}>
                    {renderCardVisitorRecords(displayList)}
                </Spin>
            </div>
        </div >
    )
}

export default Visitor