import awsmobileDev from "../backend/aws-exports-dev"
import awsmobileProd from "../backend/aws-exports-prod"

export const getAwsExport = () => {
    const environment = "dev"
    let awsmobile
    switch (environment) {
        case "dev":
            awsmobile = awsmobileDev
            break
        case "prod":
            awsmobile = awsmobileProd
            break
        default:
            break
    }
    return awsmobile
}