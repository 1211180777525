import React, { useContext, useEffect } from "react"
import { Menu } from "antd"
import { Link } from "react-router-dom"

import path from "../utils/pathSettings"
import { AppContext } from "../contexts/AppContext"

const Settings = () => {
    const { actionSetPageTitle } = useContext(AppContext)

    useEffect(() => {
        actionSetPageTitle("Settings")

        return () => {
            actionSetPageTitle("")
        }
    }, [])

    return (
        <div>
            <Menu style={{borderRight:"none"}}>
                <Menu.Item key={path("emailChange")}>
                    <Link to={path("emailChange")}>Change Email Address</Link>
                </Menu.Item>
                <Menu.Item key={path("mobileChange")}>
                    <Link to={path("mobileChange")}>Change Mobile Number</Link>
                </Menu.Item>
                <Menu.Item key={path("passwordChange")}>
                    <Link to={path("passwordChange")}>Change Password</Link>
                </Menu.Item>
                <Menu.Item key={path("preferences")}>
                    <Link to={path("preferences")}>Account Preferences</Link>
                </Menu.Item>
            </Menu>
        </div>
    )
}

export default Settings