import React from 'react'
import { useHistory } from 'react-router-dom'
import { Card, Tag } from 'antd'
import moment from 'moment'

import path from '../utils/pathSettings'

const CardIncident = (props) => {
    // console.log(props);
    const { item } = props
    const history = useHistory()

    const handleClick = () => {
        if (item.status !== 'DRAFT') {
            history.push(path('incidentReport', [item.id]))
        }
        else {
            history.push(path('incidentDraftReportEdit', [item.id]))
        }
    }

    return (
        <Card title={
            <div className="card-incident-title">
                <div>
                    <div>
                        {item.uid}
                    </div>
                    <div>
                        {item.title}
                    </div>
                </div>
                <div>
                    <Tag color={item.status === 'CLOSED' ? 'green' : (item.status === 'OPEN' ? 'orange' : 'cyan')}>
                        {item.status.slice(0, 1).toUpperCase() + item.status.slice(1).toLowerCase()}
                    </Tag>
                    <div style={{fontSize: '12px', fontStyle: 'italic', marginTop: '5px'}}>
                        {item.siteDisplay}
                    </div>
                </div>
            </div>
        } className="card-incident" onClick={handleClick}>
            <div className="row">
                <div className="label">Incident Type:</div>
                <div className="data">{item.incidentTypeDisplay}</div>
            </div>
            <div className="row">
                <div className="label">Submitted By:</div>
                <div className="data">{item.submittedByStaffName}</div>
            </div>
            <div className="row">
                <div className="label">Incident Datetime:</div>
                <div className="data">
                    {/* {moment(new Date(item.incidentDatetimeStart.replace(/-/g, "/") + " UTC")).format('DD MMM YYYY (ddd), HH:mm')} */}
                    {moment(new Date(item.incidentStart.replace(/-/g, '/') + ' UTC')).format('DD MMM YYYY (ddd), HH:mm')}
                    &nbsp;-&nbsp;
                    {/* {moment(new Date(item.incidentDatetimeEnd.replace(/-/g, "/") + " UTC")).format('DD MMM YYYY (ddd), HH:mm')} */}
                    {moment(new Date(item.incidentEnd.replace(/-/g, '/') + ' UTC')).format('DD MMM YYYY (ddd), HH:mm')}
                </div>
            </div>
            {/* <div className="row">
                <div className="label">Incident Datetime Start:</div>
                <div className="data">
                    {moment(new Date(item.incidentDatetimeStart.replace(/-/g, "/") + " UTC")).format('DD MMM YYYY (ddd), HH:mm')}
                </div>
            </div>
            <div className="row">
                <div className="label">Incident Datetime End:</div>
                <div className="data">
                    {
                        item.endDate !== "" ?
                            moment(new Date(item.incidentDatetimeEnd.replace(/-/g, "/") + " UTC")).format('DD MMM YYYY (ddd), HH:mm') :
                            ""
                    }
                </div>
            </div> */}
            {
                item.createdOn !== item.updatedOn ? (
                    <span>
                        <div className="row">
                            <div className="label">Last Updated:</div>
                            <div className="data">
                                {moment(new Date(item.updatedOn.replace(/-/g, '/') + ' UTC')).format('DD MMM YYYY (ddd), HH:mm')}
                            </div>
                        </div>
                        <div className="row">
                            <div className="label">Created On:</div>
                            <div className="data">
                                {moment(new Date(item.createdOn.replace(/-/g, '/') + ' UTC')).format('DD MMM YYYY (ddd), HH:mm')}
                            </div>
                        </div>
                    </span>
                ) : (
                    <div className="row">
                        <div className="label">Created On:</div>
                        <div className="data">
                            {moment(new Date(item.createdOn.replace(/-/g, '/') + ' UTC')).format('DD MMM YYYY (ddd), HH:mm')}
                        </div>
                    </div>
                )
            }
        </Card>
    )
}

export default CardIncident