import React, { useContext, useEffect, useState } from 'react'
import { useHistory } from 'react-router-dom'
import { Button, Descriptions, Empty, notification, Spin, Table, Tag } from 'antd'
import { FormOutlined, PlusOutlined } from '@ant-design/icons'
import moment from 'moment'
import API from '@aws-amplify/api'

import { listIncidentReport } from '../backend/graphql/queries'
import path from '../utils/pathSettings'
import { DataContext } from '../contexts/DataContext'
import { AppContext } from '../contexts/AppContext'
import CardIncident from './Component-Card-Incident'
// import FilterIncident from './Component-Filter-Incident'
// import FilterAppliedIncident from './Component-FilterApplied-Incident'

const IncidentDraft = () => {
    const { dataState } = useContext(DataContext)
    const { appState, actionSetPageTitle } = useContext(AppContext)
    const history = useHistory()
    const [table, setTable] = useState({
        data: -1,
        pagination: {
            current: 1,
            pageSize: 20,
            offset: 0,
            defaultPageSize: 20,
            showSizeChanger: true,
        },
        loading: false
    })
    const [displayList, setDisplayList] = useState([])
    const [values, setValues] = useState({
        search: '',
        dateRange: [],
        incidentType: 0,
        status: 0,
    })

    const getIncidentDraftRecords = async (props) => {
        setTable({
            ...table,
            loading: true
        })

        const user = JSON.parse(localStorage.getItem('isap_cognitouser_site'))
        // console.log("user", user);
        const profiles = JSON.parse(user.signInUserSession.idToken.payload.profiles)
        // console.log("profiles", profiles);
        // console.log("profile", profiles[0].securityAgencyID);

        try {
            let filter = {
                securityAgencyID: {
                    eq: profiles[0].securityAgencyID // current fixed to first profile, need to have an attribute to store last agency profile
                },
                status: { eq: 'DRAFT' },
                submittedBy: { eq: user.username },
            }

            const listIncidentReportDetails = {
                pagination: {
                    limit: props.pageSize,
                    offset: props.offset,
                    agencyID: profiles[0].securityAgencyID, // current fixed to first profile, need to have an attribute to store last agency profile
                    orderby: '`incidentStart` ASC'
                },
                filter: filter
            }
            // console.log(listIncidentReportDetails);
            const result = await API.graphql({
                query: listIncidentReport,
                variables: listIncidentReportDetails,
                authMode: 'AMAZON_COGNITO_USER_POOLS'
            })
            // console.log("result", result);
            const data = result.data.result
            // console.log("data", data);
            setTable({
                ...table,
                data: data.result,
                loading: data.result.length === 0 ? false : true,
                pagination: {
                    ...table.pagination,
                    current: props.current,
                    offset: props.offset,
                    pageSize: props.pageSize,
                    total: props.offset >= data.count ? data.count + 1 : data.count // keeps the last pagination if it is the last record
                }
            })
        }
        catch (error) {
            console.log('error:', error);
            notification.error({
                message: 'Unable to retrieve incident reports'
            })
            setTable({
                ...table,
                loading: false,
            })
        }

        // const timer = setTimeout(() => {
        //     setTable({
        //         ...table,
        //         data: dataState.incidentDraftRecords
        //     })
        //     clearTimeout(timer)
        // }, 1000)
    }

    useEffect(() => {
        // console.log(moment());
        const currentDatetime = moment()
        setValues({
            ...values,
            dateRange: [currentDatetime, currentDatetime],
        })
        getIncidentDraftRecords(table.pagination)

        actionSetPageTitle('Incident - Draft', true, path('incident'))

        return () => {
            actionSetPageTitle('', false, '')
        }
    }, [])

    useEffect(() => {
        if (table.data !== -1 && appState.fixedListLoaded === true) {
            let array = []
            for (let k = 0; k < table.data.length; k++) {
                let object = {}

                let siteDisplay = ''
                for (let i = 0; i < appState.siteList.length; i++) {
                    if (table.data[k].siteProfileID === appState.siteList[i].id) {
                        siteDisplay = appState.siteList[i].name
                        break
                    }
                }

                let incidentTypeDisplay = ''
                for (let i = 0; i < appState.incidentTypeList.length; i++) {
                    if (table.data[k].type === appState.incidentTypeList[i].id) {
                        incidentTypeDisplay = appState.incidentTypeList[i].name
                        break
                    }
                }

                object = {
                    ...table.data[k],
                    siteDisplay,
                    incidentTypeDisplay,
                }
                array.push(object)
            }

            setDisplayList(array)
            setTable({
                ...table,
                loading: false
            })
        }
    }, [table.data, appState.fixedListLoaded])
    const handleTableChange = (paginate) => {
        getIncidentDraftRecords({
            ...table.pagination,
            current: paginate.current,
            pageSize: paginate.pageSize,
            offset: paginate.current * paginate.pageSize - paginate.pageSize,
        })
    }

    const handleOnRow = (record, rowIndex) => {
        return {
            onClick: (event) => {
                // console.log(event.target.className);
                if (event.target.className !== 'noRowClick' && event.target.className !== '') {
                    history.push(path('incidentDraftReportEdit', [record.id]))
                }
            },
        }
    }

    const handleScoll = (e) => {
        // console.log("scroll height: ", e.target.scrollHeight);
        // console.log("scroll top", e.target.scrollTop);
        // console.log("client height", e.target.clientHeight);
        const atTop = e.target.scrollTop === 0
        const atBottom = e.target.scrollHeight - e.target.scrollTop === e.target.clientHeight
        if (atBottom) {
            // console.log("BOTTOM");
        }
        else if (atTop) {
            // console.log("TOP");
        }
    }
    
    const columns = [
        {
            title: 'Report UID',
            dataIndex: 'uid'
        },
        {
            title: 'Subject',
            dataIndex: 'title'
        },
        {
            title: 'Incident Type',
            dataIndex: 'incidentTypeDisplay'
        },
        {
            title: 'Site',
            dataIndex: 'siteDisplay',
        },
        {
            title: 'Status',
            dataIndex: 'status',
            render: (text, record) => {
                const colour = text === 'CLOSED' ? 'green' : (text === 'OPEN' ? 'orange' : 'cyan')
                return (
                    <Tag color={colour}>
                        {text.slice(0, 1).toUpperCase() + text.slice(1).toLowerCase()}
                    </Tag>
                )
            }
        },
        {
            title: 'Submitted By',
            dataIndex: 'submittedByStaffName'
        },
        // {
        //     title: "Incident Datetime",
        //     dataIndex: "incidentDatetimeStart",
        //     render: (text, record) => {
        //         return (
        //             <div>
        //                 {moment(new Date(text.replace(/-/g, "/") + " UTC")).format('DD MMM YYYY (ddd), HH:mm')}
        //                 &nbsp;-&nbsp;
        //                 {moment(new Date(record.incidentDatetimeEnd.replace(/-/g, "/") + " UTC")).format('DD MMM YYYY (ddd), HH:mm')}
        //             </div>
        //         )
        //     }
        // },
        {
            title: 'Incident Datetime Start',
            // dataIndex: "incidentDatetimeStart",
            dataIndex: 'incidentStart',
            render: (text, record) => {
                return (
                    moment(new Date(text.replace(/-/g, '/') + ' UTC')).format('DD MMM YYYY (ddd), HH:mm')
                )
            }
        },
        {
            title: 'Incident Datetime End',
            // dataIndex: "incidentDatetimeEnd",
            dataIndex: 'incidentEnd',
            render: (text, record) => {
                return (
                    text !== '' ?
                        moment(new Date(text.replace(/-/g, '/') + ' UTC')).format('DD MMM YYYY (ddd), HH:mm') :
                        ''
                )
            }
        },
        {
            title: 'Created On',
            dataIndex: 'createdOn',
            render: (text, record) => {
                return (
                    moment(new Date(text.replace(/-/g, '/') + ' UTC')).format('DD MMM YYYY (ddd), HH:mm')
                )
            }
        },
        {
            title: 'Last Updated',
            dataIndex: 'updatedOn',
            render: (text, record) => {
                return (
                    moment(new Date(text.replace(/-/g, '/') + ' UTC')).format('DD MMM YYYY (ddd), HH:mm')
                )
            }
        },
    ]

    const renderCards = (array) => {
        const cards = array.map((item) => {
            // console.log(item);
            return (
                <CardIncident item={item} key={item.id} />
            )
        })
        return cards
    }

    const renderCardIncidentRecords = (array) => {
        return (
            (array.length === 0) ? (
                <Empty image={Empty.PRESENTED_IMAGE_SIMPLE} />
            ) : (
                renderCards(array)
            )
        )
    }

    return (
        <div className="container-content allow-overflow">
            <div className="content-content">
                <Descriptions title={
                    <div className="row spaceBetween">
                        <div>
                            <span>{appState.profileAgency.securityAgencyName}</span> <Tag>Incident</Tag>
                        </div>
                    </div>
                } />
            </div>
            <Table
                className="above-md"
                columns={columns}
                rowKey={record => record.id}
                dataSource={displayList}
                pagination={table.pagination}
                loading={table.loading}
                scroll={{ x: 1000, y: '55vh' }}
                onChange={handleTableChange}
                onRow={handleOnRow}
                rowClassName={'table-row-clickable'}
            />
            <div className="below-md container-flexgrow" onScroll={handleScoll}>
                <Spin spinning={table.loading}>
                    {renderCardIncidentRecords(displayList)}
                </Spin>
            </div>
        </div>
    )
}

export default IncidentDraft