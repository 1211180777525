import { Button, Form, Input, Modal, notification, Spin } from "antd"
import React, { useContext, useState } from "react"
import { CheckOutlined, UserOutlined, LockOutlined } from '@ant-design/icons'
import { AppContext } from "../contexts/AppContext"
import { Auth } from "@aws-amplify/auth"

const LoginConfirmation = (props) => {
    const { appState, actionSetPageTitle, actionLogin } = useContext(AppContext)
    const [open, setOpen] = useState(false)
    const [loading, setLoading] = useState(false)
    const [form2] = Form.useForm()
    const { confirm, setConfirm, form, collapsableButton } = props;
    const validateMessages = {
        required: "This field is required.",
        types: {
            email: "This is not a valid email.",
        },
    }

    const preSubmitCheck = async () => {
        await form.validateFields()
        setOpen(true)
    }

    const onFinish = async (values) => {
        // console.log("onFinish", values);
        setLoading(true)
        // console.log("username is number", !isNaN(Number(values.username)));
        let username = values.username
        if (!isNaN(Number(values.username)) === true && values.username.length === 8) {
            username = "+65" + values.username
        }
        try {
            const user = await Auth.signIn(username, values.password)
            // console.log(user);
            const cognitoGroups = user.signInUserSession.idToken.payload["cognito:groups"]
            // console.log("cognitoGroups", cognitoGroups);
            const profiles = JSON.parse(user.signInUserSession.idToken.payload.profiles)
            // console.log("profiles", profiles);

            let platformPermission = false

            let profile = profiles.find(s => s.securityAgencyID === appState.agencyID);
            // console.log(profile);

            if (profile && profile.staffProfileID > 0) {
                platformPermission = true;
            }

            if (platformPermission) {
                setConfirm(true);
                actionLogin(user)
            } else {
                setConfirm(false);
            }
            setOpen(false)
        }
        catch (error) {
            console.log("error signing in: ", error);
            notification.error({
                message: error.message
            })
            setLoading(false)
        }

        // const timer = setTimeout(() => {
        //     setLoading(false)
        //     clearTimeout(timer)
        // }, 1000)
    }

    const onCancel = () => {
        setOpen(false)
    }

    return (
        <>
            <Button type="primary" htmlType="submit" onClick={preSubmitCheck} shape={appState.broken && collapsableButton === true ? 'circle' : ''} icon={<CheckOutlined />}>
                {appState.broken && collapsableButton === true ? '' : 'Submit'}
            </Button>
            <Modal
                title={<div className="modal-title">Sign in to Confirm</div>}
                open={open}
                onCancel={onCancel}
                footer={[
                    <Button key="back" onClick={onCancel} disabled={loading}>
                        Cancel
                    </Button>
                ]}
                className="modal-deploymenttemplate"
                confirmLoading={loading}
                closable={false}
            // ref={ref}
            >
                <div className="login-content">
                    <Spin spinning={loading}>
                        <Form
                            form={form2}
                            name="normal_login"
                            className="login-form"
                            onFinish={onFinish}
                            validateMessages={validateMessages}
                        >
                            <Form.Item
                                name="username"
                                rules={[
                                    {
                                        required: true,
                                    },
                                ]}
                            >
                                <Input
                                    autoComplete="off"
                                    prefix={<UserOutlined className="site-form-item-icon" />}
                                    placeholder="Phone number, username, or email"
                                />
                            </Form.Item>
                            <Form.Item
                                name="password"
                                rules={[
                                    {
                                        required: true,
                                        message: "Please input your Password.",
                                    },
                                ]}
                            >
                                <Input.Password
                                    prefix={<LockOutlined className="site-form-item-icon" />}
                                    type="password"
                                    placeholder="Password"
                                />
                            </Form.Item>
                            <Form.Item>
                                <Button
                                    type="primary"
                                    htmlType="submit"
                                    className="login-form-button"
                                >
                                    Log In
                                </Button>
                            </Form.Item>
                        </Form>
                    </Spin>
                </div>
            </Modal>
        </>
    )
}

export default LoginConfirmation