import React, { useContext, useEffect, useState } from 'react'
import { Button, Collapse, DatePicker, Form, Input, Select } from 'antd'
import { SearchOutlined } from '@ant-design/icons'
import moment from 'moment'

import { AppContext } from '../contexts/AppContext'
import { renderListOptions } from '../utils/constants'

const { Panel } = Collapse;
const { RangePicker } = DatePicker;
const { Option } = Select

const FilterVisitor = (props) => {
    const { appState } = useContext(AppContext)
    const { onFinish, values } = props
    const [form] = Form.useForm();
    const [activeKey, setActiveKey] = useState('filter')
    const dateFormatList = ['DD MMM YYYY']
    const validateMessages = {
        required: 'This field is required.',
    }

    useEffect(() => {
        form.setFieldsValue(values)
    }, [values])

    useEffect(() => {
        if (appState.broken === true) {
            setActiveKey('')
        }
        else {
            setActiveKey('filter')
        }
    }, [appState.broken])

    const onChangeCollapse = () => {
        if (activeKey === '') {
            setActiveKey('filter')
        }
        else {
            setActiveKey('')
        }
    }

    const onFinishThis = (values) => {
        setActiveKey('')
        onFinish(values)
    }

    const onChangeDatePicker = (date, dateString) => {
        // console.log(date, dateString);
    }

    const disabledDate = (current) => {
        // Cannot select days next day onwards
        return current > moment().endOf('day');
    }

    return (
        <div className="filter">
            <Collapse activeKey={activeKey} onChange={onChangeCollapse} ghost>
                <Panel header="Filter" key="filter">
                    <Form
                        form={form}
                        layout="vertical"
                        onFinish={onFinishThis}
                        validateMessages={validateMessages}
                        requiredMark={false}
                    >
                        <Form.Item
                            name="search"
                        >
                            <Input autoComplete="off" prefix={<SearchOutlined />} placeholder="Search (Name, Contact Number, Purpose of Visit, Block, Unit, Vehicle Number, Remarks)" allowClear />
                        </Form.Item>
                        <Form.Item
                            label="Date Range"
                            name="dateRange"
                            rules={[
                                {
                                    required: true,
                                },
                            ]}
                        >
                            <RangePicker onChange={onChangeDatePicker} format={dateFormatList} disabledDate={disabledDate} />
                        </Form.Item>
                        <Form.Item
                            label="Site"
                            name="site"
                        >
                            <Select
                                showSearch
                                disabled
                                placeholder="Select site"
                                filterOption={(input, option) =>
                                    option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
                                }
                            >
                                {/* <Option value={0}>All</Option> */}
                                {renderListOptions(appState.siteList.filter(s => s.id === appState.siteProfile.siteProfileID))}
                            </Select>
                        </Form.Item>
                        <div className="button">
                            <Button type="primary" htmlType="submit">Apply</Button>
                        </div>
                    </Form>
                </Panel>
            </Collapse>
        </div>
    )
}

export default FilterVisitor