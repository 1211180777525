import React, { useContext, useEffect, useState } from 'react'
import { Button, Descriptions, Empty, Form, Image, Input, notification, Popconfirm, Select, Spin, Table, Tag, Typography } from 'antd'
import moment from 'moment'
import API from '@aws-amplify/api'
import Storage from '@aws-amplify/storage'

import { listAttendance } from '../backend/graphql/queries'
// import { DataContext } from "../contexts/DataContext"
import { AppContext } from '../contexts/AppContext'
// import CardAttendance from './Component-Card-Attendance';

import imgEmptyImage192 from '../media/Image-EmptyImage-192.png'
import FilterAppliedAttendance from './Component-FilterApplied-Attendance'
import FilterAttendance from './Component-Filter-Attendance'

const AttendanceOnDuty = () => {
    // const { dataState } = useContext(DataContext)
    const { appState, actionSetPageTitle } = useContext(AppContext)
    const [table, setTable] = useState({
        data: -1,
        pagination: {
            current: 1,
            pageSize: 20,
            offset: 0,
            defaultPageSize: 20,
            showSizeChanger: true,
        },
        loading: false
    })
    const [displayList, setDisplayList] = useState([])
    const [values, setValues] = useState({
        search: '',
        site: 0,
    })

    const getAttendanceRecords = async (props, values) => {
        setTable({
            ...table,
            loading: true,
        })
        // const user = JSON.parse(localStorage.getItem('isap_cognitouser_site'))
        // const profiles = JSON.parse(user.signInUserSession.idToken.payload.profiles)
        try {
            let filter = {
                securityAgencyID: {
                    // eq: profiles[0].securityAgencyID // current fixed to first profile, need to have an attribute to store last agency profile
                    eq: appState.agencyID
                },
                or: [{
                    timeInSiteProfileID: {
                        eq: appState.siteProfile.siteProfileID
                    }
                }, {
                    timeOutSiteProfileID: {
                        eq: appState.siteProfile.siteProfileID
                    }
                }],
                timeOut: { attributeExists: false },
            }

            if (values.search !== '') {
                filter = {
                    ...filter,
                    or: [
                        { staffNo: { contains: values.search } },
                        { staffName: { contains: values.search } },
                    ]
                }
            }

            // if (values.site !== 0) {
            //     filter = {
            //         ...filter,
            //         timeInSiteProfileID: { eq: values.site }
            //     }
            // }

            const listAttendanceDetails = {
                pagination: {
                    limit: props.pageSize,
                    offset: props.offset,
                    // agencyID: profiles[0].securityAgencyID, // current fixed to first profile, need to have an attribute to store last agency profile
                    agencyID: appState.agencyID,
                    orderby: '`timeIn` DESC'
                },
                filter: filter
            }
            // console.log("listAttendanceDetails", listAttendanceDetails);
            const result = await API.graphql({
                query: listAttendance,
                variables: listAttendanceDetails,
                authMode: 'AMAZON_COGNITO_USER_POOLS'
            })
            // console.log("result", result);
            const data = result.data.result
            // console.log("data", data);
            setTable({
                ...table,
                data: data.result,
                loading: data.result.length === 0 ? false : true,
                pagination: {
                    ...table.pagination,
                    current: props.current,
                    offset: props.offset,
                    pageSize: props.pageSize,
                    total: props.offset >= data.count ? data.count + 1 : data.count // keeps the last pagination if it is the last record
                }
            })
        }
        catch (error) {
            console.log('error:', error);
            notification.error({
                message: 'Unable to retrieve attendance records'
            })
            setTable({
                ...table,
                loading: false,
            })
        }
    }

    const tabulateDisplayListData = async (table) => {
        let array = []
        for (let i = 0; i < table.data.length; i++) {
            let object = table.data[i]
            // console.log("object", object);
            if (table.data[i].timeOut !== null) {
                const totalDurationMilliseconds = new Date(table.data[i].timeOut.replace(/-/g, '/') + ' UTC') - new Date(table.data[i].timeIn.replace(/-/g, '/') + ' UTC')
                const totalDurationHours = totalDurationMilliseconds / 1000 / 60 / 60
                const totalDurationHoursSuggested = Math.round(totalDurationHours * 2) / 2
                const totalMinutesSuggested = totalDurationHoursSuggested * 60
                // console.log(totalDurationMilliseconds);
                // console.log(totalDurationHoursSuggested);
                if (table.data[i].approvalStatus === 'PENDING') {
                    object = {
                        ...table.data[i],
                        approvedDuration: totalMinutesSuggested
                    }
                }
            }
            let imageSourceTimeIn = ''
            let imageSourceTimeOut = ''
            if (object.timeInBucket !== null && object.timeInBucket !== undefined && object.timeInBucket !== '') {
                // imageSourceTimeIn = await Storage.get(object.timeInBucket)
                imageSourceTimeIn = await Storage.get(object.timeInKey)
                // console.log("imageSourceTimeIn", imageSourceTimeIn);
                object = {
                    ...object,
                    imageSourceTimeIn
                }
            }
            if (object.timeOutBucket !== null && object.timeOutBucket !== undefined && object.timeOutBucket !== '') {
                // imageSourceTimeOut = await Storage.get(object.timeOutBucket)
                imageSourceTimeOut = await Storage.get(object.timeOutKey)
                // console.log("imageSourceTimeOut", imageSourceTimeOut);
                object = {
                    ...object,
                    imageSourceTimeOut
                }
            }
            array.push(object)
        }
        setDisplayList(array)
        setTable({
            ...table,
            loading: false
        })
    }

    const columns = [
        // {
        //     title: "Staff Num",
        //     dataIndex: "staffNo"
        // },
        {
            title: 'Staff Name',
            dataIndex: 'staffName'
        },
        {
            title: 'Site In',
            dataIndex: 'timeInSiteProfileID',
            render: (text, record) => {
                return (
                    record.timeInSiteProfileName
                )
            }
        },
        {
            title: 'Time In',
            dataIndex: 'timeIn',
            render: (text, record) => {
                return (
                    moment(new Date(text.replace(/-/g, '/') + ' UTC')).format('DD MMM YYYY (ddd), HH:mm')
                )
            }
        },
        {
            title: 'Attendance Images',
            dataIndex: 'attendanceImages',
            render: (text, record) => {
                return (
                    <div className="row">
                        <Image.PreviewGroup>
                            <Image
                                width={100}
                                src={record.imageSourceTimeIn !== null ? record.imageSourceTimeIn : ''}
                                fallback={imgEmptyImage192}
                            />
                        </Image.PreviewGroup>
                    </div>
                )
            }
        },
    ]

    useEffect(() => {
        let _values = {}
        _values = {
            ...values,
            site: appState.siteProfile.siteProfileID,
        }
        setValues(_values)
        getAttendanceRecords(table.pagination, _values)

        actionSetPageTitle('Attendance - On Duty')

        return () => {
            actionSetPageTitle('')
        }
    }, [])

    useEffect(() => {
        if (table.data !== -1) {
            tabulateDisplayListData(table)
        }
    }, [table.data])

    const handleTableChange = (paginate) => {
        getAttendanceRecords({
            ...table.pagination,
            current: paginate.current,
            pageSize: paginate.pageSize,
            offset: paginate.current * paginate.pageSize - paginate.pageSize,
        }, values)
    }

    const handleScoll = (e) => {
        // console.log("scroll height: ", e.target.scrollHeight);
        // console.log("scroll top", e.target.scrollTop);
        // console.log("client height", e.target.clientHeight);
        const atTop = e.target.scrollTop === 0
        const atBottom = e.target.scrollHeight - e.target.scrollTop === e.target.clientHeight
        if (atBottom) {
            // console.log("BOTTOM");
        }
        else if (atTop) {
            // console.log("TOP");
        }
    }

    const onFinish = (values) => {
        // console.log("on finish", values);
        // setActiveKey("")
        setValues(values)
        getAttendanceRecords(table.pagination, values)
    }

    return (
        <div className="container-content allow-overflow">
            <div className="content-content">
                <Descriptions title={
                    <div className="row spaceBetween">
                        <div>
                            <span>{appState?.profileAgency?.securityAgencyName}</span> <Tag>Attendance</Tag>
                        </div>
                    </div>
                } />
                <FilterAttendance onFinish={onFinish} values={values} onDutyPage={true} />
                <FilterAppliedAttendance onFinish={onFinish} values={values} onDutyPage={true} />
            </div>
            <Table
                columns={columns}
                rowKey={record => record.id}
                dataSource={displayList}
                pagination={table.pagination}
                loading={table.loading}
                scroll={{ x: 1200, y: '55vh' }}
                onChange={handleTableChange}
            />
        </div>
    )
}

export default AttendanceOnDuty