import React, { useContext } from "react"
import { Route, Redirect } from "react-router-dom"

import { AppContext } from '../contexts/AppContext'
import path from "./pathSettings"

export const PrivateRoute = ({ component: Component, ...rest }) => {
    const { appState } = useContext(AppContext)

    return (
        <Route
            {...rest}
            render={(props) =>
                // appState.validUserLogin ? (
                //     appState.validSiteSelection ? (
                //             <Component {...props} />
                //         ) : (
                //             <Redirect to={
                //                 {
                //                     pathname: path('siteSelection'),
                //                     state: { from: props.location }
                //                 }
                //             } />
                //         )
                // ) : (
                //     <Redirect to={
                //         {
                //             pathname: path('login'),
                //             state: { from: props.location }
                //         }
                //     } />
                // )
                appState.validUserLogin && appState.validSiteSelection ? (
                    <Component {...props} />
                ) : (
                    appState.validUserLogin ? (
                        <Redirect to={{
                            pathname: path('siteSelection'),
                            state: { from: props.location }
                        }} />) : (
                        <Redirect to={{
                            pathname: path('login'),
                            state: { from: props.location }
                        }} />)

                )
            }
        />
    )
}