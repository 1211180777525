import React, { useContext, useEffect, useState } from 'react'
import { Button, DatePicker, Descriptions, Empty, Form, Image, Input, Modal, notification, Popconfirm, Select, Spin, Tag, Upload } from 'antd'
import { CheckOutlined, CloseOutlined, PlusOutlined } from '@ant-design/icons'
import { useHistory } from 'react-router-dom'
import API from '@aws-amplify/api'
import Storage from '@aws-amplify/storage'
import moment from 'moment'

import { listIncidentReport, listIncidentFile } from '../backend/graphql/queries'
import { submitIncident } from '../backend/graphql/mutations'
import path from '../utils/pathSettings'
import { getAwsExport } from '../utils/awsExportSettings'
import { AppContext } from '../contexts/AppContext'
import imgEmptyImage192 from '../media/Image-EmptyImage-192.png'
import useUnsavedChangesWarning from '../hooks/useUnsavedChangesWarning'

const { Option } = Select
const { TextArea } = Input

const IncidentReportEdit = (props) => {
    const { appState, actionSetPageTitle } = useContext(AppContext)
    const [form] = Form.useForm()
    const [loading, setLoading] = useState(false)
    const [loadingImageList, setLoadingImageList] = useState(false)
    const [incident, setIncident] = useState(-1)
    const [imageListSource, setImageListSource] = useState([])
    const [display, setDisplay] = useState({})
    const dateFormatList = ['DD MMM YYYY HH:mm']
    const validateMessages = {
        required: 'This field is required.',
    }
    const getBase64 = (file) => {
        return new Promise((resolve, reject) => {
            const reader = new FileReader();
            reader.readAsDataURL(file);
            reader.onload = () => resolve(reader.result);
            reader.onerror = error => reject(error);
        });
    }
    const uploadButton = (
        <div>
            <PlusOutlined />
            <div style={{ marginTop: 8 }}>Upload</div>
        </div>
    )
    const [state, setState] = useState({
        fileList: []
    })
    const history = useHistory()
    const [Prompt, setDirty, setPristine] = useUnsavedChangesWarning()

    const getImageFromStorage = async (array) => {
        // console.log("array", array);
        let arrayUpdate = []
        for (let i = 0; i < array.length; i++) {
            try {
                const result = await Storage.get(array[i].key)
                // console.log("result", result.toString());
                // arrayUpdate.push(result.toString())
                arrayUpdate.push({
                    ...array[i],
                    src: result.toString()
                })
            }
            catch (error) {
                console.log('error:', error);
            }
        }

        setImageListSource(arrayUpdate)
    }

    const getIncidentRecord = async (id) => {
        setLoading(true)
        const user = JSON.parse(localStorage.getItem('isap_cognitouser_site'))
        const profiles = JSON.parse(user.signInUserSession.idToken.payload.profiles)

        try {
            const listIncidentReportDetails = {
                pagination: {
                    agencyID: profiles[0].securityAgencyID, // current fixed to first profile, need to have an attribute to store last agency profile
                },
                filter: {
                    securityAgencyID: {
                        eq: profiles[0].securityAgencyID // current fixed to first profile, need to have an attribute to store last agency profile
                    },
                    id: {
                        eq: id
                    }
                }
            }
            const result = await API.graphql({
                query: listIncidentReport,
                variables: listIncidentReportDetails,
                authMode: 'AMAZON_COGNITO_USER_POOLS'
            })
            // console.log("result", result);
            setIncident(result.data.result.result[0])
            // console.log("result", result.data.result.result[0]);
        }
        catch (error) {
            console.log('error:', error);
            notification.error({
                message: 'Unable to retrieve incident report'
            })
            setLoading(false)
        }

        // if (id !== undefined) {
        //     for (let i = 0; i < dataState.incidentRecords.length; i++) {
        //         if (parseInt(id) === dataState.incidentRecords[i].id) {
        //             setIncident(dataState.incidentRecords[i])
        //             break
        //         }
        //     }
        // }
        // else {
        //     notification.error({
        //         message: "Unable to retrieve incident report"
        //     })
        //     const timer = setTimeout(() => {
        //         setLoading(false)
        //         clearTimeout(timer)
        //     }, 1000)
        // }
    }

    const getIncidentFileRecords = async (id) => {
        setLoadingImageList(true)
        const user = JSON.parse(localStorage.getItem('isap_cognitouser_site'))
        const profiles = JSON.parse(user.signInUserSession.idToken.payload.profiles)
        try {
            const listIncidentFileDetails = {
                pagination: {
                    agencyID: profiles[0].securityAgencyID, // current fixed to first profile, need to have an attribute to store last agency profile
                },
                filter: {
                    incidentReportID: {
                        eq: id
                    }
                }
            }
            const result = await API.graphql({
                query: listIncidentFile,
                variables: listIncidentFileDetails,
                authMode: 'AMAZON_COGNITO_USER_POOLS'
            })
            // console.log("result", result);
            const data = result.data.result
            // console.log("data", data);
            const array = data.result ? data.result : []
            if (array.length > 0) {
                await getImageFromStorage(array)
            }
        }
        catch (error) {
            console.log('error:', error);
            notification.error({
                message: 'Unable to retrieve incident report files'
            })
        }
        finally {
            setLoadingImageList(false)
        }
    }

    const uploadFile = async (file) => {
        // console.log(file);
        const user = JSON.parse(localStorage.getItem('isap_cognitouser_site'))
        const profiles = JSON.parse(user.signInUserSession.idToken.payload.profiles)

        const fileName = 'ir_' + moment().utc().format('YYYY-MM-DD_HH:mm:ss') + '_' + file.name
        const fileType = file.type

        try {
            const result = await Storage.put('incident/' + profiles[0].securityAgencyID + '/' + fileName, file, {
                contentType: fileType
            })
            // console.log(result.key);
            return result.key
        }
        catch (error) {
            console.log('error:', error);
            notification.error({
                message: error
            })
        }
    }

    const updateIncidentReportRecord = async (values, imageKeyList, id) => {
        const user = JSON.parse(localStorage.getItem('isap_cognitouser_site'))
        const profiles = JSON.parse(user.signInUserSession.idToken.payload.profiles)
        // console.log("user", user);
        // console.log("profiles", profiles);
        try {
            let updateIncidentFile = []
            if (imageKeyList.length !== 0) {
                for (let i = 0; i < imageKeyList.length; i++) {
                    updateIncidentFile.push(
                        {
                            id: 0,
                            bucket: getAwsExport().aws_user_files_s3_bucket,
                            key: imageKeyList[i],
                            region: getAwsExport().aws_user_files_s3_bucket_region,
                        }
                    )
                }
            }
            let incidentDetails = {
                updateIncidentReport: {
                    id: id,
                    securityAgencyID: profiles[0].securityAgencyID, // current fixed to first profile, need to have an attribute to store last agency profile
                    securitySiteID: appState?.siteList?.filter(s => s.id == values.site)[0]?.securitySiteID,
                    siteProfileID: values.site,
                    submittedBy: user.username,
                    submittedByStaffProfileID: profiles[0].staffProfileID,
                    status: values.status,
                },
                updateIncidentDetail: {
                    id: 0,
                    staffProfileID: profiles[0].staffProfileID,
                    type: values.incidentType,
                    title: values.subject,
                    incidentStart: values.incidentDatetimeStart.toISOString().split('.')[0],
                    incidentEnd: values.incidentDatetimeEnd.toISOString().split('.')[0],
                    location: values.incidentLocation,
                    description: values.incidentDescription,
                    // personInvolved not captured by front end
                    action: values.actionsTaken,
                    remarks: values.remarks !== undefined && values.remarks !== null ? values.remarks : ''
                }
            }
            if (updateIncidentFile.length !== 0) {
                incidentDetails = {
                    ...incidentDetails,
                    updateIncidentFile: updateIncidentFile
                }
            }
            // console.log("incidentDetails", incidentDetails);
            const result = await API.graphql({
                query: submitIncident,
                variables: incidentDetails,
                authMode: 'AMAZON_COGNITO_USER_POOLS'
            })
            // console.log("result", result);
            setPristine()
            notification.success({
                message: 'Updated successfully'
            })
            history.push(path('incidentReport', [result.data.result.id]))
        }
        catch (error) {
            console.log('error:', error);
            notification.error({
                message: 'Unable to update incident report'
            })
            setLoading(false)
        }
    }

    useEffect(() => {
        getIncidentRecord(props.match.params.id)
        getIncidentFileRecords(props.match.params.id)

        actionSetPageTitle('Edit Incident Report', true, path('incidentReport', [props.match.params.id]))

        return () => {
            actionSetPageTitle('', false, '')
        }
    }, [])

    useEffect(() => {
        if (incident !== -1) {
            // console.log(incident);
            let siteDisplay = ''
            for (let i = 0; i < appState.siteList.length; i++) {
                if (incident.siteProfileID === appState.siteList[i].id) {
                    siteDisplay = appState.siteList[i].name
                    break
                }
            }

            form.setFieldsValue({
                ...incident,
                // incidentDatetimeStart: moment(new Date(incident.incidentDatetimeStart.replace(/-/g, "/") + " UTC")),
                // incidentDatetimeEnd: moment(new Date(incident.incidentDatetimeEnd.replace(/-/g, "/") + " UTC")),
                // incidentDatetimeStart: moment(new Date(incident.incidentStart.replace(/-/g, "/") + " UTC")),
                incidentDatetimeStart: moment.utc(incident.incidentStart).local(),
                // incidentDatetimeEnd: moment(new Date(incident.incidentEnd.replace(/-/g, "/") + " UTC")),
                incidentDatetimeEnd: moment.utc(incident.incidentEnd).local(),
                incidentType: incident.type,
                site: incident.siteProfileID,
                status: incident.status,
                subject: incident.title,
                incidentLocation: incident.location,
                incidentDescription: incident.description,
                actionsTaken: incident.action,
            })
            setDisplay({
                ...incident,
                siteDisplay,
                createdOnDisplay: moment(new Date(incident.createdOn.replace(/-/g, '/') + ' UTC')).format('DD MMM YYYY (ddd), HH:mm'),
                updatedOnDisplay: moment(new Date(incident.updatedOn.replace(/-/g, '/') + ' UTC')).format('DD MMM YYYY (ddd), HH:mm'),
                // incidentDatetimeStartDisplay: moment(new Date(incident.incidentDatetimeStart.replace(/-/g, "/") + " UTC")).format('DD MMM YYYY (ddd), HH:mm'),
                // incidentDatetimeStartDisplay: moment(new Date(incident.incidentStart.replace(/-/g, "/") + " UTC")).format('DD MMM YYYY (ddd), HH:mm'),
                // incidentDatetimeEndDisplay: incident.incidentDatetimeEnd !== "" ? moment(new Date(incident.incidentDatetimeEnd.replace(/-/g, "/") + " UTC")).format('DD MMM YYYY (ddd), HH:mm') : "",
                // incidentDatetimeEndDisplay: incident.incidentEnd !== "" ? moment(new Date(incident.incidentEnd.replace(/-/g, "/") + " UTC")).format('DD MMM YYYY (ddd), HH:mm') : "",
                // statusDisplay: incident.status.slice(0, 1).toUpperCase() + incident.status.slice(1).toLowerCase(),
                // statusColour: incident.status === "CLOSED" ? "green" : "orange"
            })
            setLoading(false)
        }
    }, [incident])

    const onFinish = async (values) => {
        // console.log("on finish", values);
        // console.log("uploaded images", state);
        await form.validateFields()
        setLoading(true)
        let imageKeyList = []
        // upload file if any
        if (state.fileList.length > 0) {
            for (let i = 0; i < state.fileList.length; i++) {

                const file = state.fileList[i].originFileObj
                const imageKey = await uploadFile(file)
                imageKeyList.push(imageKey)
                // console.log("imageKey", imageKey);
            }
        }
        // console.log("imageKeyList", imageKeyList);
        await updateIncidentReportRecord(values, imageKeyList, props.match.params.id)

        // setPristine()
        // const timer = setTimeout(() => {
        //     clearTimeout(timer)
        //     notification.success({
        //         message: "Updated successfully"
        //     })
        //     history.push(path("incidentReport", [props.match.params.id]))
        // }, 1000)
    }

    const handleDatePickerChange = (date, dateString) => {
        // console.log(date, dateString);
        setDirty()
    }

    const handleFormChange = () => {
        setDirty()
    }

    const handleCancel = () => {
        setState({ ...state, previewVisible: false })
    }

    const handlePreview = async (file) => {
        if (!file.url && !file.preview) {
            file.preview = await getBase64(file.originFileObj);
        }

        setState({
            ...state,
            previewImage: file.url || file.preview,
            previewVisible: true,
            previewTitle: file.name || file.url.substring(file.url.lastIndexOf('/') + 1),
        })
    }

    const handleChange = (file) => {
        let { fileList } = file
        setState({ ...state, fileList: fileList })
        setDirty()
    }

    const handleCancelUpdate = () => {
        history.push(path('incidentReport', [props.match.params.id]))
    }

    const handleImageDelete = (key) => {
        // console.log("Image key", key);
        notification.success({
            message: 'Deleted successfully'
        })
    }

    const disabledDate = (current) => {
        // Cannot select days next day onwards
        return current > moment().endOf('day');
    }

    const renderListOptions = (array, stringId) => {

        const listOptions = array.map((item, index) => {
            if (stringId !== undefined && stringId === 1) {
                return (
                    <Option key={item.id} value={item.id.toString()}>{item.name}</Option>
                )
            }
            else {
                return (
                    <Option key={item.id} value={item.id}>{item.name}</Option>
                )
            }
        })

        return listOptions
    }

    const renderSiteListOptions = (array) => {
        // console.log("array", array);
        if (array.length !== 0) {
            const listOptions = array.map((item, index) => {
                return (
                    <Option key={item.securitySiteID} value={item.securitySiteID}>{item.name}</Option>
                )
            })

            return listOptions
        }
        else {
            return (
                <div />
            )
        }
    }

    const renderImages = (array) => {
        const images = array.map((item) => {
            return (
                <div key={item.id}>
                    <Image
                        width={100}
                        src={item.src !== null ? item.src : ''}
                        fallback={imgEmptyImage192}
                    />
                    &nbsp;&nbsp;
                </div>
            )
            // return (
            //     <div style={{display: "flex", alignItems: "center"}}>
            //         <Image
            //             width={100}
            //             src={item.key !== null ? item.key : ""}
            //             fallback={imgEmptyImage192}
            //         />
            //         <Popconfirm title="Are you sure?" onConfirm={() => handleImageDelete(item.key)} okText="Yes" cancelText="No">
            //             <Button shape="circle" icon={<DeleteOutlined />} />
            //         </Popconfirm>
            //         &nbsp;&nbsp;
            //     </div>
            // )
        })
        return images
    }

    const renderUploadImages = (array) => {
        // console.log("array", array);
        return (
            (array.length === 0) ? (
                <Empty image={Empty.PRESENTED_IMAGE_SIMPLE} />
            ) : (
                <div className="row">
                    <Image.PreviewGroup>
                        {renderImages(array)}
                    </Image.PreviewGroup>
                </div>
            )
        )
    }

    return (
        <div className="container-content allow-overflow">
            <Spin spinning={loading}>
                <div className="content-content">
                    <Form
                        form={form}
                        layout="vertical"
                        onFinish={onFinish}
                        validateMessages={validateMessages}
                        requiredMark={false}
                    >
                        <Descriptions
                            title={
                                <div className="row spaceBetween">
                                    <div>
                                        <span>{appState.profileAgency.securityAgencyName}</span> <Tag>Incident</Tag>
                                    </div>
                                    <div>
                                        <Button onClick={handleCancelUpdate} shape={appState.broken === true ? 'circle' : ''} icon={<CloseOutlined />}>
                                            {appState.broken === true ? '' : 'Cancel'}
                                        </Button>
                                        &nbsp;&nbsp;
                                        <Popconfirm title={
                                            <div>
                                                Are you sure?
                                            </div>
                                        } onConfirm={() => onFinish(form.getFieldsValue())} okText="Yes" cancelText="No" placement="topRight">
                                            <Button type="primary" htmlType="submit" shape={appState.broken === true ? 'circle' : ''} icon={<CheckOutlined />}>
                                                {appState.broken === true ? '' : 'Update'}
                                            </Button>
                                        </Popconfirm>
                                    </div>
                                </div>
                            }
                            bordered
                            column={{ xs: 1, sm: 2 }}
                        >
                            <Descriptions.Item label="Report UID" span={2}>
                                {display.uid}
                                <div className="sub-italic">
                                    {
                                        display.createdOnDisplay !== display.updatedOnDisplay ? (
                                            <span>
                                                Last updated: {display.updatedOnDisplay}
                                                <br />
                                                Created On: {display.createdOnDisplay}
                                            </span>
                                        ) : (
                                            <span>Created On: {display.createdOnDisplay}</span>
                                        )
                                    }
                                </div>
                            </Descriptions.Item>
                            <Descriptions.Item label="Site">{display.siteDisplay}</Descriptions.Item>
                            <Descriptions.Item label="Submitted By">{display.submittedByStaffName}</Descriptions.Item>
                        </Descriptions>
                        <br />
                        <Form.Item
                            label="Site"
                            name="site"
                            rules={[
                                {
                                    required: true,
                                },
                            ]}
                        >
                            <Select
                                showSearch
                                placeholder="Select site"
                                filterOption={(input, option) =>
                                    option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
                                }
                                disabled={true}
                            >
                                {renderListOptions(appState.siteList)}
                            </Select>
                        </Form.Item>
                        <Form.Item
                            label="Incident Type"
                            name="incidentType"
                            rules={[
                                {
                                    required: true,
                                },
                            ]}
                        >
                            <Select
                                showSearch
                                placeholder="Select incident type"
                                filterOption={(input, option) =>
                                    option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
                                }
                                onChange={handleFormChange}
                            >
                                {renderListOptions(appState.incidentTypeList)}
                            </Select>
                        </Form.Item>
                        <Form.Item
                            label="Status"
                            name="status"
                            rules={[
                                {
                                    required: true,
                                },
                            ]}
                        >
                            <Select
                                showSearch
                                placeholder="Select status"
                                filterOption={(input, option) =>
                                    option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
                                }
                                onChange={handleFormChange}
                                disabled={true}
                            >
                                <Option key="OPEN" value="OPEN">Open</Option>
                                <Option key="CLOSED" value="CLOSED">Closed</Option>
                            </Select>
                        </Form.Item>
                        <Form.Item
                            label="Subject"
                            name="subject"
                            rules={[
                                {
                                    required: true,
                                },
                            ]}
                        >
                            <Input autoComplete="off" placeholder="Enter subject" onChange={handleFormChange} />
                        </Form.Item>
                        <Form.Item
                            label="Incident Datetime Start"
                            name="incidentDatetimeStart"
                            rules={[
                                {
                                    required: true,
                                },
                            ]}
                        >
                            <DatePicker showTime onChange={handleDatePickerChange} format={dateFormatList} disabledDate={disabledDate} />
                        </Form.Item>
                        <Form.Item
                            label="Incident Datetime End"
                            name="incidentDatetimeEnd"
                            rules={[
                                {
                                    required: true,
                                },
                            ]}
                        >
                            <DatePicker showTime onChange={handleDatePickerChange} format={dateFormatList} disabledDate={disabledDate} />
                        </Form.Item>
                        <Form.Item
                            label="Incident Location"
                            name="incidentLocation"
                            rules={[
                                {
                                    required: true,
                                },
                            ]}
                        >
                            <Input autoComplete="off" placeholder="Enter incident location" onChange={handleFormChange} />
                        </Form.Item>
                        {/* <Form.Item
                            label="People Involved"
                            name="peopleInvolved"
                        >
                            <TextArea rows={2} autoComplete="off" placeholder="Enter people involved" />
                        </Form.Item> */}
                        <Form.Item
                            label="Incident Description"
                            name="incidentDescription"
                            rules={[
                                {
                                    required: true,
                                },
                            ]}
                        >
                            <TextArea rows={4} autoComplete="off" placeholder="Enter incident description" onChange={handleFormChange} />
                        </Form.Item>
                        <Form.Item
                            label="Actions Taken"
                            name="actionsTaken"
                            rules={[
                                {
                                    required: true,
                                },
                            ]}
                        >
                            <TextArea rows={3} autoComplete="off" placeholder="Enter actions taken" onChange={handleFormChange} />
                        </Form.Item>
                        <Form.Item
                            label="Remarks"
                            name="remarks"
                        >
                            <TextArea rows={2} autoComplete="off" placeholder="Enter remarks" onChange={handleFormChange} />
                        </Form.Item>
                        <Form.Item
                            label="Upload Image(s)"
                        >
                            <Upload
                                action={getBase64}
                                listType="picture-card"
                                fileList={state.fileList}
                                onPreview={handlePreview}
                                onChange={(file) => handleChange(file)}
                                multiple={true}
                            >
                                {state.fileList.length >= 5 ? null : uploadButton}
                            </Upload>
                            <Modal
                                visible={state.previewVisible}
                                title={state.previewTitle}
                                footer={null}
                                onCancel={handleCancel}
                            >
                                <img alt="upload preview" style={{ width: '100%' }} src={state.previewImage} />
                            </Modal>
                        </Form.Item>
                        {
                            // display.imagesKey !== undefined && JSON.parse(display.imagesKey).length !== 0 ? (
                            imageListSource.length !== 0 ? (
                                <Form.Item
                                    label="Uploaded Image(s)"
                                >
                                    <Spin spinning={loadingImageList}>
                                        {/* {renderUploadImages(display.imagesKey !== undefined ? JSON.parse(display.imagesKey) : [])} */}
                                        {renderUploadImages(imageListSource)}
                                    </Spin>
                                </Form.Item>
                            ) : (
                                <div />
                            )
                        }
                        {/* <Form.Item
                            label="Uploaded Image(s)"
                        >
                            <Spin spinning={loadingImageList}>
                                {renderUploadImages(imageListSource)}
                            </Spin>
                        </Form.Item> */}
                        <div className="button">
                            <Button onClick={handleCancelUpdate}>Cancel</Button>
                            &nbsp;&nbsp;
                            <Popconfirm title={
                                <div>
                                    Are you sure?
                                </div>
                            } onConfirm={() => onFinish(form.getFieldsValue())} okText="Yes" cancelText="No">
                                <Button type="primary" htmlType="submit">Update</Button>
                            </Popconfirm>
                        </div>
                    </Form>
                </div>
            </Spin>
            {Prompt}
        </div>
    )
}

export default IncidentReportEdit