import React, { useContext, useEffect, useState } from "react"
import { Button, Checkbox, Form, Input, message, notification, Select, Space, Spin, Tabs } from "antd"
import { UserOutlined, LockOutlined, EnvironmentOutlined } from "@ant-design/icons"
import { Link, useHistory } from "react-router-dom"
import Auth from "@aws-amplify/auth"

import { AppContext } from '../contexts/AppContext'

import Logo from "../media/logo-inimicode-light-155x31.png"
import path from "../utils/pathSettings"
import { siteSelectionRequestForOTP } from "../utils/constants"
import API from "@aws-amplify/api"
import { createNewSiteProfileOTP } from "../backend/graphql/mutations"
import { verifySiteProfileOTP } from "../backend/graphql/queries"

const SiteSelection = (props) => {
    const { appState, actionLogin, actionSetSite } = useContext(AppContext)
    const { from, toChange } = props.location.state || { from: "/" }
    const { Option } = Select
    const history = useHistory()
    const [form] = Form.useForm()
    const [loading, setLoading] = useState(false)
    const [currentStep, setCurrentStep] = useState(0)
    const validateMessages = {
        required: "This field is required.",
        types: {
            email: "This is not a valid email.",
        },
    }

    const generateSiteProfileOTP = async (siteProfileID) => {
        setLoading(true)
        try {
            const variables = {
                create: {
                    siteProfileID: siteProfileID
                }
            }
            // console.log('searchStaffProfileDetails', searchStaffProfileDetails);
            const result = await API.graphql({
                query: createNewSiteProfileOTP,
                variables,
                authMode: 'AMAZON_COGNITO_USER_POOLS',
            })
            // console.log('result', result);
            const data = result.data.result
            // console.log('data', data);

            if (data) {
                setCurrentStep(currentStep + 1)
            }
        }
        catch (error) {
            // setLoading(false)
            console.log('error: ', error);
            notification.error({
                message: 'Unable to proceed'
            })
        }
        finally { setLoading(false) }
    }

    const _verifySiteProfileOTP = async (siteProfileID, otp) => {
        setLoading(true)
        try {
            const variables = {
                siteProfileID,
                otp,
            }
            // console.log('searchStaffProfileDetails', searchStaffProfileDetails);
            const result = await API.graphql({
                query: verifySiteProfileOTP,
                variables,
                authMode: 'AMAZON_COGNITO_USER_POOLS',
            })
            // console.log('result', result);
            const data = JSON.parse(result.data.result)
            // console.log('data', data);

            if (data.result) {
                actionSetSite(appState.siteList.find(s => s.id === siteProfileID));
                history.push(path('home'))
            }
        }
        catch (error) {
            // setLoading(false)
            console.log('error: ', error);
            notification.error({
                message: 'Unable to proceed'
            })
        }
        finally { setLoading(false) }
    }

    useEffect(() => {
        // console.log('siteSelection', toChange, appState.validUserLogin, appState.validSiteSelection, appState)
        if (!toChange) {
            if (appState.validUserLogin !== true) {
                history.push(path('login'))
            } else if (appState.validUserLogin && appState.validSiteSelection) {
                // history.push(path('home'))
                history.push(from)
            }
        }
        // if (appState.validUserLogin === true) {
        //     history.push(from)
        // }
        // if (localStorage.getItem("isap_remember_site") !== null) {
        //     form.setFieldsValue({
        //         username: localStorage.getItem("isap_remember_site"),
        //         remember: true,
        //     })
        // }
        // else {
        //     form.setFieldsValue({
        //         remember: false
        //     })
        // }
        return () => { }
    }, [])

    const onFinish = async (values) => {
        // console.log("onFinish", values);
        setLoading(true)

        if (siteSelectionRequestForOTP === false) {
            actionSetSite(appState.siteList.find(s => s.id === values.siteID));
            history.push(path('home'))

            setLoading(false)
        } else {
            generateSiteProfileOTP(values.siteID)
        }
    }

    const onFinishOTP = async (values) => {
        _verifySiteProfileOTP(values.siteID, values.otp)
    }

    const onCancel = async (values) => {
        history.push(path('home'))
    }

    const handleCheckChange = (e) => {
        // console.log("handleCheckChange");
        if (form.getFieldValue("remember") === false) {
            localStorage.removeItem("isap_remember_site")
        }
    }

    const renderSiteListOptions = (array) => {
        // console.log("array", array);
        if (array.length !== 0) {
            const listOptions = array.map((item, index) => {
                return (
                    <Option key={item.id} value={item.id}>{item.name}</Option>
                )
            })

            return listOptions
        }
        else {
            return (
                <div />
            )
        }
    }

    return (
        <div className="login">
            <div className="container-login">
                <div className="login-body">
                    <div className="login-header">
                        <img src={Logo} />
                    </div>
                    <div className="login-subheader">
                        Site Selection
                    </div>
                    <div className="login-content">
                        <Spin spinning={loading}>
                            {currentStep === 0 ?
                                <Form
                                    form={form}
                                    name="normal_login"
                                    className="login-form"
                                    onFinish={onFinish}
                                    validateMessages={validateMessages}
                                >
                                    <Form.Item
                                        name="siteID"
                                        rules={[
                                            {
                                                required: true,
                                            },
                                        ]}
                                    >
                                        {/* <Input autoComplete="off" prefix={<EnvironmentOutlined className="site-form-item-icon" />} placeholder="Site name" /> */}
                                        <Select
                                            showSearch
                                            placeholder="Select Site"
                                            filterOption={(input, option) =>
                                                option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
                                            }
                                        >
                                            {renderSiteListOptions(appState.siteList.filter(s => s.status === 'ACTIVE'))}
                                        </Select>
                                    </Form.Item>
                                    <Form.Item>
                                        <Space direction="vertical" className="login-form-button">
                                            <Button type="primary" htmlType="submit" className="login-form-button">
                                                {siteSelectionRequestForOTP ? 'Request for OTP' : 'Submit'}
                                            </Button>
                                            <Button type="default" htmlType="submit" className="login-form-button" onClick={onCancel}>
                                                Cancel
                                            </Button>
                                        </Space>
                                    </Form.Item>
                                </Form> :
                                <Form
                                    form={form}
                                    name="normal_login"
                                    className="login-form"
                                    onFinish={onFinishOTP}
                                    validateMessages={validateMessages}
                                >
                                    <Form.Item
                                        name="siteID"
                                        // hidden={true}
                                        rules={[
                                            {
                                                required: true,
                                            },
                                        ]}
                                    >
                                        {/* <Input autoComplete="off" prefix={<EnvironmentOutlined className="site-form-item-icon" />} placeholder="Site name" /> */}
                                        <Select
                                            showSearch
                                            placeholder="Select Site"
                                            disabled={true}
                                            filterOption={(input, option) =>
                                                option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
                                            }
                                        >
                                            {renderSiteListOptions(appState.siteList.filter(s => s.status === 'ACTIVE'))}
                                        </Select>
                                    </Form.Item>
                                    <Form.Item
                                        name="otp"
                                        rules={[
                                            {
                                                required: true,
                                            },
                                            {

                                                whitespace: false,
                                            },
                                            {
                                                pattern: new RegExp(/[0-9]+/),
                                                message: 'Only digits'
                                            }
                                        ]}
                                    >
                                        <Input autoComplete="off" placeholder="Enter 6 digits OTP" />
                                    </Form.Item>
                                    <Form.Item>
                                        <Space direction="vertical" className="login-form-button">
                                            <Button type="primary" htmlType="submit" className="login-form-button">
                                                Submit
                                            </Button>
                                            <Button type="default" htmlType="submit" className="login-form-button" onClick={onCancel}>
                                                Cancel
                                            </Button>
                                        </Space>
                                    </Form.Item>
                                </Form>
                            }
                        </Spin>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default SiteSelection