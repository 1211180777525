import React, { useContext, useEffect, useState } from 'react'
import { useHistory } from 'react-router-dom'
import { Badge, Button, Descriptions, Empty, notification, Spin, Table, Tag } from 'antd'
import { FormOutlined, PlusOutlined } from '@ant-design/icons'
import moment from 'moment'
import API from '@aws-amplify/api'

import { listIncidentReportWithDraftCount } from '../backend/graphql/custom_queries'
import path from '../utils/pathSettings'
import { AppContext } from '../contexts/AppContext'
import CardIncident from './Component-Card-Incident'
import FilterIncident from './Component-Filter-Incident'
import FilterAppliedIncident from './Component-FilterApplied-Incident'
import { listIncidentReport } from '../backend/graphql/queries'

const Incident = () => {
    const { appState, actionSetPageTitle } = useContext(AppContext)
    const history = useHistory()
    const [table, setTable] = useState({
        data: -1,
        pagination: {
            current: 1,
            pageSize: 20,
            offset: 0,
            defaultPageSize: 20,
            showSizeChanger: true,
        },
        loading: false
    })
    const [displayList, setDisplayList] = useState([])
    const [draftCount, setDraftCount] = useState(0)
    const [values, setValues] = useState({
        search: '',
        dateRange: [],
        incidentType: 0,
        status: 0,
        site: 0,
    })

    const getIncidentRecords = async (props, values) => {
        // console.log("values", values);
        setTable({
            ...table,
            loading: true
        })

        const user = JSON.parse(localStorage.getItem('isap_cognitouser_site'))
        // console.log("user", user);
        const profiles = JSON.parse(user.signInUserSession.idToken.payload.profiles)
        // console.log("profiles", profiles);
        // console.log("profile", profiles[0].securityAgencyID);

        try {
            let filter = {
                securityAgencyID: {
                    // eq: profiles[0].securityAgencyID // current fixed to first profile, need to have an attribute to store last agency profile
                    eq: appState.agencyID
                },
                and: [
                    {
                        incidentStart: { ge: values.dateRange[0].toISOString().split('.')[0] }
                        // incidentStart: { ge: moment(new Date(values.dateRange[0].format("YYYY-MM-DD 00:00:00").replace(/-/g, "/") + " UTC")).format("YYYY-MM-DD HH:mm:ss") }
                        // incidentStart: { ge: values.dateRange[0].format("YYYY-MM-DD 00:00:00") }
                    },
                    {
                        incidentStart: { le: values.dateRange[1].toISOString().split('.')[0] }
                        // incidentEnd: { le: moment(new Date(values.dateRange[1].format("YYYY-MM-DD 23:59:59").replace(/-/g, "/") + " UTC")).format("YYYY-MM-DD HH:mm:ss") }
                        // incidentEnd: { le: values.dateRange[1].format("YYYY-MM-DD 23:59:59") }
                    },
                ]
            }

            if (values.search !== '') {
                filter = {
                    ...filter,
                    or: [{ uid: { contains: values.search } }, { title: { contains: values.search } }, { submittedBy: { contains: values.search } }]
                }
            }

            if (values.incidentType !== 0) {
                filter = {
                    ...filter,
                    type: { eq: values.incidentType }
                }
            }

            if (values.status !== 0) {
                filter = {
                    ...filter,
                    status: { eq: values.status }
                }
            }
            else {
                filter = {
                    ...filter,
                    status: { ne: 'DRAFT' }
                }
            }

            if (values.site !== 0) {
                filter = {
                    ...filter,
                    siteProfileID: { eq: values.site }
                }
            }

            const listIncidentReportDetails = {
                pagination: {
                    limit: props.pageSize,
                    offset: props.offset,
                    agencyID: profiles[0].securityAgencyID, // current fixed to first profile, need to have an attribute to store last agency profile
                    orderby: '`incidentStart` ASC'
                },
                // pagination2: {
                //     agencyID: profiles[0].securityAgencyID, // current fixed to first profile, need to have an attribute to store last agency profile
                // },
                filter: filter,
                // filter2: {
                //     securityAgencyID: {
                //         eq: profiles[0].securityAgencyID // current fixed to first profile, need to have an attribute to store last agency profile
                //     },
                //     status: { eq: 'DRAFT' },
                //     submittedBy: { eq: user.username },
                // }
            }
            // console.log(listIncidentReportDetails);
            const result = await API.graphql({
                query: listIncidentReport,
                variables: listIncidentReportDetails,
                authMode: 'AMAZON_COGNITO_USER_POOLS'
            })
            // console.log("result", result);
            const data = result.data.result
            // const data2 = result.data.result2
            // console.log("data", data);
            setTable({
                ...table,
                data: data.result,
                loading: data.result.length === 0 ? false : true,
                pagination: {
                    ...table.pagination,
                    current: props.current,
                    offset: props.offset,
                    pageSize: props.pageSize,
                    total: props.offset >= data.count ? data.count + 1 : data.count // keeps the last pagination if it is the last record
                }
            })
            // setDraftCount(data2.result.length)
        }
        catch (error) {
            console.log('error:', error);
            notification.error({
                message: 'Unable to retrieve incident reports'
            })
            setTable({
                ...table,
                loading: false,
            })
        }

        // const timer = setTimeout(() => {
        //     setTable({
        //         ...table,
        //         data: dataState.incidentRecords
        //     })
        //     clearTimeout(timer)
        // }, 1000)
    }

    useEffect(() => {
        // console.log(moment());
        const currentDatetime = moment()
        let _values = {}
        if (sessionStorage.getItem('isap_site_incident_records') !== null) {
            // console.log(moment(JSON.parse(sessionStorage.getItem("isap_site_incident_records")).dateRange[0]));
            _values = {
                ...values,
                ...JSON.parse(sessionStorage.getItem('isap_site_incident_records')),
                site: appState.siteProfile.siteProfileID,
                dateRange: [
                    moment(JSON.parse(sessionStorage.getItem('isap_site_incident_records')).dateRange[0]),
                    moment(JSON.parse(sessionStorage.getItem('isap_site_incident_records')).dateRange[1]),
                ]
            }
        }
        else {
            _values = {
                ...values,
                site: appState.siteProfile.siteProfileID,
                dateRange: [
                    moment(new Date(currentDatetime.format('YYYY-MM-DD 00:00:00'))).subtract(1, 'days'),
                    moment(new Date(currentDatetime.format('YYYY-MM-DD 23:59:59'))),
                ]
            }
        }
        setValues(_values)
        getIncidentRecords(table.pagination, _values)

        actionSetPageTitle('Incident')

        return () => {
            actionSetPageTitle('')
        }
    }, [])

    useEffect(() => {
        if (table.data !== -1 && appState.fixedListLoaded === true) {
            let array = []
            for (let k = 0; k < table.data.length; k++) {
                let object = {}

                let siteDisplay = ''
                for (let i = 0; i < appState.siteList.length; i++) {
                    if (table.data[k].siteProfileID === appState.siteList[i].id) {
                        siteDisplay = appState.siteList[i].name
                        break
                    }
                }

                let incidentTypeDisplay = ''
                for (let i = 0; i < appState.incidentTypeList.length; i++) {
                    if (table.data[k].type === appState.incidentTypeList[i].id) {
                        incidentTypeDisplay = appState.incidentTypeList[i].name
                        break
                    }
                }

                object = {
                    ...table.data[k],
                    siteDisplay,
                    incidentTypeDisplay,
                }
                array.push(object)
            }

            setDisplayList(array)
            setTable({
                ...table,
                loading: false
            })
        }
    }, [table.data, appState.fixedListLoaded])

    const handleTableChange = (paginate) => {
        getIncidentRecords({
            ...table.pagination,
            current: paginate.current,
            pageSize: paginate.pageSize,
            offset: paginate.current * paginate.pageSize - paginate.pageSize,
        })
    }

    const handleOnRow = (record, rowIndex) => {
        return {
            onClick: (event) => {
                // console.log(event.target.className);
                if (event.target.className !== 'noRowClick' && event.target.className !== '') {
                    history.push(path('incidentReport', [record.id]))
                }
            },
        }
    }

    const handleScoll = (e) => {
        // console.log("scroll height: ", e.target.scrollHeight);
        // console.log("scroll top", e.target.scrollTop);
        // console.log("client height", e.target.clientHeight);
        const atTop = e.target.scrollTop === 0
        const atBottom = e.target.scrollHeight - e.target.scrollTop === e.target.clientHeight
        if (atBottom) {
            // console.log("BOTTOM");
        }
        else if (atTop) {
            // console.log("TOP");
        }
    }

    const handleCreateClick = () => {
        history.push(path('incidentReportCreate'))
    }

    const handleDraftClick = () => {
        history.push(path('incidentDraft'))
    }

    const onFinish = (values) => {
        // console.log("on finish", values);
        let _values = {
            ...values,
            dateRange: [
                moment(values.dateRange[0].format('YYYY-MM-DD 00:00:00')),
                moment(values.dateRange[1].format('YYYY-MM-DD 23:59:59')),
            ]
        }
        sessionStorage.setItem('isap_site_incident_records', JSON.stringify(_values))
        setValues(_values)
        getIncidentRecords(table.pagination, _values)
    }

    const columns = [
        {
            title: 'Report UID',
            dataIndex: 'uid'
        },
        {
            title: 'Subject',
            dataIndex: 'title'
        },
        {
            title: 'Incident Type',
            dataIndex: 'incidentTypeDisplay'
        },
        {
            title: 'Site',
            dataIndex: 'siteDisplay',

        },
        {
            title: 'Status',
            dataIndex: 'status',
            render: (text, record) => {
                const colour = text === 'CLOSED' ? 'green' : (text === 'OPEN' ? 'orange' : 'cyan')
                return (
                    <Tag color={colour}>
                        {text.slice(0, 1).toUpperCase() + text.slice(1).toLowerCase()}
                    </Tag>
                )
            }
        },
        {
            title: 'Submitted By',
            dataIndex: 'submittedByStaffName'
        },
        {
            title: 'Incident Datetime Start',
            // dataIndex: "incidentDatetimeStart",
            dataIndex: 'incidentStart',
            render: (text, record) => {
                return (
                    moment(new Date(text.replace(/-/g, '/') + ' UTC')).format('DD MMM YYYY (ddd), HH:mm')
                )
            }
        },
        {
            title: 'Incident Datetime End',
            // dataIndex: "incidentDatetimeEnd",
            dataIndex: 'incidentEnd',
            render: (text, record) => {
                return (
                    text !== '' ?
                        moment(new Date(text.replace(/-/g, '/') + ' UTC')).format('DD MMM YYYY (ddd), HH:mm') :
                        ''
                )
            }
        },
        {
            title: 'Created On',
            dataIndex: 'createdOn',
            render: (text, record) => {
                return (
                    moment(new Date(text.replace(/-/g, '/') + ' UTC')).format('DD MMM YYYY (ddd), HH:mm')
                )
            }
        },
        {
            title: 'Last Updated',
            dataIndex: 'updatedOn',
            render: (text, record) => {
                return (
                    moment(new Date(text.replace(/-/g, '/') + ' UTC')).format('DD MMM YYYY (ddd), HH:mm')
                )
            }
        },
    ]

    const renderCards = (array) => {
        const cards = array.map((item) => {
            // console.log(item);
            return (
                <CardIncident item={item} key={item.id} />
            )
        })
        return cards
    }

    const renderCardIncidentRecords = (array) => {
        return (
            (array.length === 0) ? (
                <Empty image={Empty.PRESENTED_IMAGE_SIMPLE} />
            ) : (
                renderCards(array)
            )
        )
    }

    return (
        <div className="container-content allow-overflow">
            <div className="content-content">
                <Descriptions title={
                    <div className="row spaceBetween">
                        <div>
                            <span>{appState.profileAgency.securityAgencyName}</span> <Tag>Incident</Tag>
                        </div>
                        <div>
                            {/* Displays button only if there is draft record */}
                            {
                                draftCount !== 0 ? (
                                    <Badge size="small" count={draftCount} offset={[-5, 10]}>
                                        <Button onClick={handleDraftClick} shape={appState.broken === true ? 'circle' : ''} icon={<FormOutlined />}>
                                            {appState.broken === true ? '' : 'Draft'}
                                        </Button>
                                    </Badge>
                                ) : ''
                            }
                            &nbsp;&nbsp;
                            <Button onClick={handleCreateClick} type="primary" shape={appState.broken === true ? 'circle' : ''} icon={<PlusOutlined />}>
                                {appState.broken === true ? '' : 'Create'}
                            </Button>
                        </div>
                    </div>
                } />
                <FilterIncident onFinish={onFinish} values={values} />
                <FilterAppliedIncident onFinish={onFinish} values={values} />
            </div>
            <Table
                className="above-md"
                columns={columns}
                rowKey={record => record.id}
                dataSource={displayList}
                pagination={table.pagination}
                loading={table.loading}
                scroll={{ x: 1200, y: '55vh' }}
                onChange={handleTableChange}
                onRow={handleOnRow}
                rowClassName={'table-row-clickable'}
            />
            <div className="below-md container-flexgrow" onScroll={handleScoll}>
                <Spin spinning={table.loading}>
                    {renderCardIncidentRecords(displayList)}
                </Spin>
            </div>
        </div>
    )
}

export default Incident