import React, { useContext, useEffect, useState } from "react"
import { Button, Descriptions, Form, Input, Select } from "antd"
import { CheckCircleOutlined, ExclamationCircleOutlined } from '@ant-design/icons'
import { Link } from "react-router-dom"

import path from "../utils/pathSettings"
import { AppContext } from "../contexts/AppContext"

const MobileChange = () => {
    const { actionSetPageTitle } = useContext(AppContext)
    const { Option } = Select
    const [form] = Form.useForm();
    const [user, setUser] = useState({})
    const validateMessages = {
        required: 'This field is required.',
    }
    const countryCallingCode = (
        <Form.Item name="countryCallingCode" noStyle>
            <Select
                style={{
                    width: 70,
                }}
            >
                <Option value="+65">+65</Option>
            </Select>
        </Form.Item>
    )
    
    const getCurrentUserInfo = async () => {
        setUser({
            mobile: "+65 98765432",
            mobileVerified: true,
        })
    }

    useEffect(() => {
        actionSetPageTitle("Settings", true, path("settings"))

        form.setFieldsValue({
            countryCallingCode: "+65"
        })
        getCurrentUserInfo()

        return () => {
            actionSetPageTitle("", false, "")
        }
    }, [])

    const onFinish = (values) => {
        // console.log("on finish", values);
    }

    return (
        <div>
            <div className="content-content">
                <Descriptions
                    title={"Change Mobile Number"}
                    column={3}
                >
                    <Descriptions.Item label="Current Mobile" span={3}>{user.mobile}
                        {
                            user.mobileVerified === true ? (
                                <span className="emailverifiedstatus"><CheckCircleOutlined /></span>
                            ) :
                                (
                                    <span>
                                        <span className="emailverifiedstatus notverified"><ExclamationCircleOutlined /></span>
                                        <Link to={"/settings/mobile/verify"} style={{ marginLeft: "16px" }}><Button>Verify Mobile</Button></Link>
                                    </span>
                                )
                        }
                    </Descriptions.Item>
                </Descriptions>
                <Form
                    form={form}
                    layout="vertical"
                    onFinish={onFinish}
                    validateMessages={validateMessages}
                    requiredMark={false}
                >
                    <Form.Item
                        label="Mobile Number"
                        name="mobile"
                        rules={[
                            {
                                required: true,
                                pattern: new RegExp(/^[8-9]([\d]{7}$)/),
                                message: 'This is not a valid mobile number.',
                            },
                        ]}
                    >
                        <Input autoComplete="off" placeholder="Enter mobile number" addonBefore={countryCallingCode} />
                    </Form.Item>
                    <div className="button">
                        <Button type="primary" htmlType="submit">Update</Button>
                    </div>
                </Form>
            </div>
        </div>
    )
}

export default MobileChange