import React, { useContext, useState } from "react"
import { Layout } from "antd"
import { useLocation, withRouter } from "react-router-dom"

import { AppContext } from "../contexts/AppContext"
import MenuApp from './Component-MenuApp';
import imgInimicodeLogo from "../media/logo-inimicode-dark-155x31.png"
import path from "../utils/pathSettings";
import { pagesWithoutNav } from "../utils/constants";

const { Sider } = Layout;

const SideNav = (props) => {
    // console.log(props);
    // console.log(props.match.params);
    // const [mode, setMode] = props.match.params
    const { actionSetBroken } = useContext(AppContext)
    const location = useLocation()
    const [collapsed, setCollapsed] = useState(false)
    const onCollapse = (collapsed, type) => {
        // console.log(collapsed);
        // console.log(type);
        setCollapsed(collapsed)
    }

    if (pagesWithoutNav.includes(location.pathname)) {
        return ("")
    }
    else {
        return (
            <Sider
                breakpoint="lg"
                collapsedWidth="0"
                onBreakpoint={broken => {
                    // console.log("BROKEN", broken);
                    actionSetBroken(broken)
                }}
                onCollapse={(collapsed, type) => onCollapse(collapsed, type)}
                style={{
                    minHeight: '100vh', zIndex: 1
                }}
                collapsed={collapsed}
                zeroWidthTriggerStyle={
                    collapsed === true ? (
                        // appState.collapsed === true ? (
                        { position: "fixed", zIndex: 1, width: "25px", height: "30px", lineHeight: "30px", left: "0", top: "72px" }
                    ) : (
                        { position: "fixed", zIndex: 1, width: "25px", height: "30px", lineHeight: "30px", left: "200px", top: "72px" }
                    )
                }
            >
                {
                    collapsed === true ? (
                        <div />
                    ) : (
                        <div className="logo">
                            <img src={imgInimicodeLogo} />
                        </div>
                    )
                }
                <MenuApp collapsed={collapsed} setCollapsed={setCollapsed} location={props.location} />
            </Sider>
        )
    }
}

export default withRouter(SideNav)