import React from "react"
import { useHistory } from "react-router-dom"
import { Card, Tag } from "antd"
import moment from "moment"

import path from "../utils/pathSettings"

const CardPatrol = (props) => {
    // console.log(props);
    const { item } = props
    const history = useHistory()
    const totalDurationMilliseconds = moment.utc(item.end) - moment.utc(item.start) //new Date(item.end.replace(/-/g, "/") + " UTC") - new Date(item.start.replace(/-/g, "/") + " UTC")
    const totalDurationDisplayHours = Math.floor(totalDurationMilliseconds / 1000 / 60 / 60)
    const totalDurationDisplayMinutes = totalDurationMilliseconds / 1000 / 60 % 60

    const handleClick = () => {
        history.push(path("patrolRecordsProfile", [item.id]))
    }

    return (
        <Card title={
            <div className="card-patrol-title">
                <div style={{ display: "flex", alignItems: "center" }}>
                    <div>
                        {item.clockingRouteName}
                    </div>
                </div>
                <div>
                    {/* <Tag color={item.status === "CLOSED" ? "green" : (item.status === "OPEN" ? "orange" : "cyan")}> */}
                    <Tag color={item.status === "COMPLETED" ? "green" : (item.status === "SEMI-COMPLETED" ? "yellow" : (item.status === "DISRUPTED" ? "red" : "cyan"))}>
                        {item.status.slice(0, 1).toUpperCase() + item.status.slice(1).toLowerCase()}
                    </Tag>
                    <div style={{ fontSize: "12px", fontStyle: "italic", marginTop: "5px" }}>
                        {item.siteName}
                    </div>
                </div>
            </div>
        } className="card-patrol" onClick={handleClick}>
            {/* <div className="row">
                <div className="label">Patrol Datetime:</div>
                <div className="data">
                    {moment(new Date(item.datetimeStart.replace(/-/g, "/") + " UTC")).format('DD MMM YYYY (ddd), HH:mm')}
                    &nbsp;-&nbsp;
                    {moment(new Date(item.datetimeEnd.replace(/-/g, "/") + " UTC")).format('DD MMM YYYY (ddd), HH:mm')}
                </div>
            </div> */}
            <div className="row">
                <div className="label">Patrol Start:</div>
                <div className="data">{moment(new Date(item.start.replace(/-/g, "/") + " UTC")).format('DD MMM YYYY (ddd), HH:mm')}</div>
            </div>
            <div className="row">
                <div className="label">Patrol End:</div>
                <div className="data">{moment(new Date(item.end.replace(/-/g, "/") + " UTC")).format('DD MMM YYYY (ddd), HH:mm')}</div>
            </div>
            <div className="row">
                <div className="label">Total Duration:</div>
                <div className="data">{totalDurationDisplayHours !== 0 ? totalDurationDisplayHours.toFixed(2) + " h" : "" } {totalDurationDisplayMinutes !== 0 ? totalDurationDisplayMinutes.toFixed(2) + " min" : ""}</div>
            </div>
            <div className="row">
                <div className="label">Staff Num:</div>
                <div className="data">{item.staffNo}</div>
            </div>
            <div className="row">
                <div className="label">Staff Name:</div>
                <div className="data">{item.staffProfileName}</div>
            </div>
            <div className="row">
                <div className="label">Remarks:</div>
                <div className="data">{item.remark}</div>
            </div>
        </Card>
    )
}

export default CardPatrol