// g, l, s, 5 customised
// github 3e03b9e
export const listFixedList = /* GraphQL */ `
query ListFixed(
  $filter: ListCitizenshipFilterInput
  $pagination: PaginationInput
  $filter2: ListNationalityFilterInput
  $filter3: ListIncidentTypeFilterInput
  $filter4: ListRaceFilterInput
  $filter5: ListReligionFilterInput
  $filter6: ListPLRDGradeFilterInput
) {
  listCitizenship(filter: $filter, pagination: $pagination) {
    result {
      id
      name
      deleted
    }
  }
  listNationality(filter: $filter2, pagination: $pagination) {
    result {
      country
      name
      nationality
      alpha2
      alpha3
      numeric
      subdivision
      independent
      order
      deleted
    }
  }
  listIncidentType(filter: $filter3, pagination: $pagination) {
    result {
      id
      name
      deleted
    }
  }
  listRace(filter: $filter4, pagination: $pagination) {
    result {
      id
      name
      deleted
    }
  }
  listReligion(filter: $filter5, pagination: $pagination) {
    result {
      id
      name
      deleted
    }
  }
  listPLRDGrade(filter: $filter6, pagination: $pagination) {
    result {
      id
      name
      code
      deleted
    }
  }

}
`;
export const listClientAndSiteList = /* GraphQL */ `
query ListClientAndSite(
  $filter: ListClientProfileFilterInput
  $pagination: PaginationInput
  $filter2: ListSiteProfileFilterInput
  $pagination2: PaginationInput
) {
  result: listClientProfile(filter: $filter, pagination: $pagination) {
    result {
      id
      securityAgencyID
      managementAgencyID
      clientAgencyID
      permission
      status
      name
      phoneNo
      email
      createdOn
      updatedOn
      deleted
      deletedOn
    }
    count
  }
  result2: listSiteProfile(filter: $filter2, pagination: $pagination2) {
    result {
      id
      securitySiteID
      securityAgencyID
      managementAgencyID
      name
      mcstNo
      uen
      address
      unitNo
      postal
      tier
      strataLot
      status
      phoneNo
      siteHistoryID
      siteHistoryStart
      siteHistoryEnd
      siteHistoryStatus
      clientProfileID
      clientProfilePermission
      clientProfileStatus
      clientProfileName
      clientProfilePhoneNo
      clientProfileEmail
      createdOn
      updatedOn
      deleted
      deletedOn
    }
    count
  }

}
`;
export const listIncidentReportWithDraftCount = /* GraphQL */ `
query ListIncidentReport(
  $filter: ListIncidentReportFilterInput
  $pagination: PaginationInput
  $filter2: ListIncidentReportFilterInput
  $pagination2: PaginationInput
) {
  result: listIncidentReport(filter: $filter, pagination: $pagination) {
    result {
      id
      securityAgencyID
      securitySiteID
      siteProfileID
      uid
      submittedBy
      submittedByStaffProfileID
      submittedByDesignation
      submittedTimestamp
      submittedByStaffName
      closedBy
      closedByStaffProfileID
      closedByDesignation
      closedTimestamp
      closedByStaffName
      revision
      status
      statusHistory
      incidentDetailID
      incidentDetailAccountID
      incidentDetailStaffProfileID
      incidentDetailStaffProfileName
      incidentDetailStaffProfileDesignation
      type
      title
      location
      incidentStart
      incidentEnd
      personInvolved
      description
      action
      remarks
      createdOn
      updatedOn
      deleted
      deletedOn
    }
    count
  }
  result2: listIncidentReport(filter: $filter2, pagination: $pagination2) {
    result {
      id
      securityAgencyID
      securitySiteID
      siteProfileID
      uid
      submittedBy
      submittedByStaffProfileID
      submittedByDesignation
      submittedTimestamp
      submittedByStaffName
      closedBy
      closedByStaffProfileID
      closedByDesignation
      closedTimestamp
      closedByStaffName
      revision
      status
      statusHistory
      incidentDetailID
      incidentDetailAccountID
      incidentDetailStaffProfileID
      incidentDetailStaffProfileName
      incidentDetailStaffProfileDesignation
      type
      title
      location
      incidentStart
      incidentEnd
      personInvolved
      description
      action
      remarks
      createdOn
      updatedOn
      deleted
      deletedOn
    }
    count
  }
}
`;
export const listClockingReportWithPoints = /* GraphQL */ `
query ListClockingReport(
  $filter: ListClockingReportFilterInput
  $pagination: PaginationInput
  $filter2: ListClockingReportDetailFilterInput
  $pagination2: PaginationInput
) {
  result: listClockingReport(filter: $filter, pagination: $pagination) {
    result {
      id
      securitySiteID
      securityAgencyID
      siteProfileID
      clockingRouteID
      accountID
      staffProfileID
      status
      remark
      start
      end
      totalPoint
      clockedPoint
      securitySiteName
      securityAgencyName
      siteProfileName
      clockingRouteName
      staffNo
      staffProfileName
      staffProfileDesignation
      createdOn
      updatedOn
      deleted
      deletedOn
    }
    count
  }
  result2: listClockingReportDetail(filter: $filter2, pagination: $pagination2) {
    result {
      clockingReportID
      clockingPointID
      tag
      datetime
      remark
      status
      securityAgencyID
      clockingPointName
      createdOn
      updatedOn
      deleted
      deletedOn
    }
    count
  }
}
`;
export const listClockingRouteWithPoints = /* GraphQL */ `
query ListClockingRoute(
  $filter: ListClockingRouteFilterInput
  $pagination: PaginationInput
  $filter2: ListClockingRouteDetailFilterInput
  $pagination2: PaginationInput
) {
  result: listClockingRoute(filter: $filter, pagination: $pagination) {
    result {
      id
      securitySiteID
      securitySiteName
      securityAgencyID
      securityAgencyName
      siteProfileID
      siteProfileName
      name
      routeDetails
      inOrder
      islandWide
      status
      createdOn
      updatedOn
      deleted
      deletedOn
    }
    count
  }
  result2: listClockingRouteDetail(filter: $filter2, pagination: $pagination2) {
    result {
      clockingRouteID
      clockingRouteName
      securitySiteID
      securitySiteName
      securityAgencyID
      securityAgencyName
      siteProfileID
      siteProfileName
      routeDetails
      inOrder
      islandWide
      clockingRouteStatus
      clockingPointID
      clockingPointName
      clockingPointDescription
      clockingPointUpdatedOn
      tag
      isAttendance
      deleted
    }
    count
  }
}
`;