import React, { useContext, useEffect, useState } from "react"
import { Button, Descriptions, Form, Input } from "antd"

import path from "../utils/pathSettings"
import { AppContext } from "../contexts/AppContext"

const EmailVerify = () => {
    const { actionSetPageTitle } = useContext(AppContext)
    const [form] = Form.useForm();
    const [resend, setResend] = useState(false)
    const validateMessages = {
        required: 'This field is required.',
        types: {
            email: 'This is not a valid email.',
        },
    }

    const getCurrentAuthenticatedUserEmail = async () => {
        form.setFieldsValue({
            email: "current@email.com",
        })
    }

    useEffect(() => {
        actionSetPageTitle("Settings", true, path("emailChange"))

        getCurrentAuthenticatedUserEmail()

        return () => {
            actionSetPageTitle("", false, "")
        }
    }, [])

    const handleClick = async () => {
        // console.log("RESEND");
        setResend(true)
    }

    const onFinish = (values) => {
        // console.log("on finish", values);
    }

    return (
        <div>
            <div className="content-content">
                <Descriptions
                    title={"Verify Email Address"}
                    // column={3}
                // bordered
                >
                    {/* <Descriptions.Item label="Current Email" span={3}>{user.email}
                        {
                            user.emailVerified === true ? (
                                <span className="emailverifiedstatus"><CheckCircleOutlined /></span>
                            ) :
                                (
                                    <span className="emailverifiedstatus notverified"><ExclamationCircleOutlined /></span>
                                )
                        }
                    </Descriptions.Item> */}
                </Descriptions>
                <Form
                    form={form}
                    layout="vertical"
                    onFinish={onFinish}
                    validateMessages={validateMessages}
                    requiredMark={false}
                >
                    <Form.Item
                        label="Email"
                        name="email"
                        rules={[
                            {
                                type: 'email',
                                required: true,
                            },
                        ]}
                    >
                        <Input autoComplete="off" placeholder="Enter email" disabled={true} />
                    </Form.Item>
                    <Form.Item
                        label="Code (Sent to your email)"
                        name="code"
                        rules={[
                            {
                                required: true,
                            },
                        ]}
                    >
                        <Input autoComplete="off" placeholder="Enter code" />
                    </Form.Item>
                    <Descriptions column={3}>
                        <Descriptions.Item label="Did not receive confirmation code?">
                            <div className="button">
                                <Button onClick={handleClick} disabled={resend}>Resend</Button>
                            </div>
                        </Descriptions.Item>
                    </Descriptions>
                    <div className="button">
                        <Button type="primary" htmlType="submit">Verify</Button>
                    </div>
                </Form>
            </div>
        </div>
    )
}

export default EmailVerify