import { useContext, useEffect, useState } from 'react'
import { Button, DatePicker, Descriptions, Form, Image, Input, Modal, notification, Select, Spin, Tag, Upload } from 'antd'
import { CloseOutlined, PlusOutlined } from '@ant-design/icons'
import { useHistory } from 'react-router-dom'
import API from '@aws-amplify/api'
import Storage from '@aws-amplify/storage'
import moment from 'moment'

import { submitIncident } from '../backend/graphql/mutations'
import path from '../utils/pathSettings'
import { getAwsExport } from '../utils/awsExportSettings'
import { AppContext } from '../contexts/AppContext'
import imgEmptyImage192 from '../media/Image-EmptyImage-192.png'
import useUnsavedChangesWarning from '../hooks/useUnsavedChangesWarning'
import LoginConfirmation from './Component-Login-Confirmation'

const { Option } = Select
const { TextArea } = Input

const IncidentReportCreate = () => {
    const { appState, actionSetPageTitle } = useContext(AppContext)
    const [form] = Form.useForm();
    const [loading, setLoading] = useState(false)
    const [confirm, setConfirm] = useState(false)
    const dateFormatList = ['DD MMM YYYY HH:mm']
    const validateMessages = {
        required: 'This field is required.',
    }
    const getBase64 = (file) => {
        return new Promise((resolve, reject) => {
            const reader = new FileReader();
            reader.readAsDataURL(file);
            reader.onload = () => resolve(reader.result);
            reader.onerror = error => reject(error);
        });
    }
    const uploadButton = (
        <div>
            <PlusOutlined />
            <div style={{ marginTop: 8 }}>Upload</div>
        </div>
    )
    const [state, setState] = useState({
        fileList: []
    })
    const history = useHistory()
    const [Prompt, setDirty, setPristine] = useUnsavedChangesWarning()

    const uploadFile = async (file) => {
        // console.log(file);
        const user = JSON.parse(localStorage.getItem('isap_cognitouser_site'))
        const profiles = JSON.parse(user.signInUserSession.idToken.payload.profiles)

        const fileName = 'ir_' + moment().utc().format('YYYY-MM-DD_HH:mm:ss') + '_' + file.name
        const fileType = file.type

        try {
            const result = await Storage.put('incident/' + profiles[0].securityAgencyID + '/' + fileName, file, {
                contentType: fileType
            })
            // console.log(result.key);
            return result.key
        }
        catch (error) {
            console.log('error:', error);
            notification.error({
                message: error
            })
        }
    }

    const createIncidentReportRecord = async (values, imageKeyList, type) => {
        const user = JSON.parse(localStorage.getItem('isap_cognitouser_site'))
        const profiles = JSON.parse(user.signInUserSession.idToken.payload.profiles)
        // console.log("user", user);
        // console.log("profiles", profiles);
        try {
            let updateIncidentFile = []
            if (imageKeyList.length !== 0) {
                for (let i = 0; i < imageKeyList.length; i++) {
                    updateIncidentFile.push(
                        {
                            id: 0,
                            bucket: getAwsExport().aws_user_files_s3_bucket,
                            key: imageKeyList[i],
                            region: getAwsExport().aws_user_files_s3_bucket_region,
                        }
                    )
                }
            }
            let incidentDetails = {
                updateIncidentReport: {
                    id: 0,
                    securityAgencyID: profiles[0].securityAgencyID, // current fixed to first profile, need to have an attribute to store last agency profile
                    securitySiteID: appState?.siteList?.filter(s => s.id == values.site)[0]?.securitySiteID,
                    siteProfileID: values.site,
                    submittedBy: user.username,
                    submittedByStaffProfileID: profiles[0].staffProfileID,
                    status: type === 'draft' ? 'DRAFT' : values.status,
                },
                updateIncidentDetail: {
                    id: 0,
                    staffProfileID: profiles[0].staffProfileID,
                    type: values.incidentType,
                    title: values.subject,
                    incidentStart: values.incidentDatetimeStart.toISOString().split('.')[0],
                    incidentEnd: values.incidentDatetimeEnd.toISOString().split('.')[0],
                    location: values.incidentLocation,
                    description: values.incidentDescription,
                    // personInvolved not captured by front end
                    action: values.actionsTaken,
                    remarks: values.remarks !== undefined && values.remarks !== null ? values.remarks : ''
                }
            }
            if (updateIncidentFile.length !== 0) {
                incidentDetails = {
                    ...incidentDetails,
                    updateIncidentFile: updateIncidentFile
                }
            }
            // console.log("incidentDetails", incidentDetails);
            const result = await API.graphql({
                query: submitIncident,
                variables: incidentDetails,
                authMode: 'AMAZON_COGNITO_USER_POOLS'
            })
            // console.log("result", result);
            setPristine()
            notification.success({
                message: 'Created successfully'
            })
            history.push(path('incidentReport', [result.data.result.id]))
        }
        catch (error) {
            console.log('error:', error);
            notification.error({
                message: 'Unable to create incident report'
            })
            setLoading(false)
        }
    }

    useEffect(() => {
        form.setFieldsValue({
            status: 'OPEN',
            site: appState.siteProfile.siteProfileID,
        })

        actionSetPageTitle('Create Incident Report', true, path('incident'))

        return () => {
            actionSetPageTitle('', false, '')
        }
    }, [])

    useEffect(() => {
        if (appState.fixedListLoaded === true) {
            form.setFieldsValue({
                // incidentDatetimeStart: moment(new Date(incident.incidentDatetimeStart.replace(/-/g, "/") + " UTC")),
                // incidentDatetimeEnd: moment(new Date(incident.incidentDatetimeEnd.replace(/-/g, "/") + " UTC")),
            })
            setLoading(false)
        }
    }, [appState.fixedListLoaded])

    useEffect(() => {
      if (confirm) {
        onFinish(form.getFieldsValue(), 'submit')
      }
    }, [confirm])
    

    const onFinish = async (values, type) => {
        // console.log("on finish", values);
        // console.log("uploaded images", state);
        await form.validateFields()
        setLoading(true)
        // const user = JSON.parse(localStorage.getItem("isap_cognitouser_site"))
        // const profiles = JSON.parse(user.signInUserSession.idToken.payload.profiles)
        // console.log("user", user);
        // console.log("profiles", profiles);

        let imageKeyList = []

        // upload file if any
        if (state.fileList.length > 0) {
            for (let i = 0; i < state.fileList.length; i++) {

                const file = state.fileList[i].originFileObj
                const imageKey = await uploadFile(file)
                imageKeyList.push(imageKey)
                // console.log("imageKey", imageKey);
            }
        }
        // console.log("imageKeyList", imageKeyList);
        await createIncidentReportRecord(values, imageKeyList, type)
        // setLoading(true)
        // setPristine()
        // const timer = setTimeout(() => {
        //     clearTimeout(timer)
        //     notification.success({
        //         message: "Created successfully"
        //     })
        //     history.push(path("incident")) // redirect to created report page from backend response
        // }, 1000)
    }

    const handleDatePickerChange = (date, dateString) => {
        // console.log(date, dateString);
        setDirty()
    }

    const handleFormChange = () => {
        setDirty()
    }

    const handleCancel = () => {
        setState({ ...state, previewVisible: false })
    }

    const handlePreview = async (file) => {
        if (!file.url && !file.preview) {
            file.preview = await getBase64(file.originFileObj);
        }

        setState({
            ...state,
            previewImage: file.url || file.preview,
            previewVisible: true,
            previewTitle: file.name || file.url.substring(file.url.lastIndexOf('/') + 1),
        })
    }

    const handleChange = (file) => {
        let { fileList } = file
        setState({ ...state, fileList: fileList })
        setDirty()
    }

    const handleCancelUpdate = () => {
        history.push(path('incident'))
    }

    const handleSaveAsDraft = () => {
        // will be replaced by onFinish() by passing param as "draft"
        // console.log("on finish", form.getFieldsValue());
        // console.log("uploaded images", state);
        setLoading(true)
        setPristine()
        const timer = setTimeout(() => {
            clearTimeout(timer)
            notification.success({
                message: 'Saved as draft successfully'
            })
            history.push(path('incidentDraft'))
        }, 1000)
    }

    const disabledDate = (current) => {
        // Cannot select days next day onwards
        return current > moment().endOf('day');
    }

    const renderListOptions = (array, stringId) => {

        const listOptions = array.map((item, index) => {
            if (stringId !== undefined && stringId === 1) {
                return (
                    <Option key={item.id} value={item.id.toString()}>{item.name}</Option>
                )
            }
            else {
                return (
                    <Option key={item.id} value={item.id}>{item.name}</Option>
                )
            }
        })

        return listOptions
    }

    const renderSiteListOptions = (array) => {
        // console.log("array", array);
        if (array.length !== 0) {
            const listOptions = array.map((item, index) => {
                return (
                    <Option key={item.id} value={item.id}>{item.name}</Option>
                )
            })

            return listOptions
        }
        else {
            return (
                <div />
            )
        }
    }

    const renderImages = (array) => {
        const images = array.map((item) => {
            return (
                <div>
                    <Image
                        width={100}
                        src={item.key !== null ? item.key : ''}
                        fallback={imgEmptyImage192}
                    />
                    &nbsp;&nbsp;
                </div>
            )
        })
        return images
    }

    return (
        <div className="container-content allow-overflow">
            <Spin spinning={loading}>
                <div className="content-content">
                    <Form
                        form={form}
                        layout="vertical"
                        // onFinish={onFinish}
                        validateMessages={validateMessages}
                        requiredMark={false}
                    >
                        <Descriptions
                            title={
                                <div className="row spaceBetween">
                                    <div>
                                        <span>{appState.profileAgency.securityAgencyName}</span> <Tag>Incident</Tag>
                                    </div>
                                    <div>
                                        <Button onClick={handleCancelUpdate} shape={appState.broken === true ? 'circle' : ''} icon={<CloseOutlined />}>
                                            {appState.broken === true ? '' : 'Cancel'}
                                        </Button>
                                        &nbsp;&nbsp;
                                        {/* <Button onClick={() => onFinish(form.getFieldsValue(), 'draft')} shape={appState.broken === true ? 'circle' : ''} icon={<SaveOutlined />}>
                                            {appState.broken === true ? '' : 'Save as Draft'}
                                        </Button>
                                        &nbsp;&nbsp; */}
                                        {/* <Popconfirm title={
                                            <div>
                                                Are you sure?<br />
                                                No amendments can be made once report is submitted.
                                            </div>
                                        } onConfirm={this} okText="Yes" cancelText="No" placement="topRight"> */}
                                        <LoginConfirmation confirm={confirm} setConfirm={setConfirm} form={form} collapsableButton />
                                        {/* </Popconfirm> */}
                                    </div>
                                </div>
                            }
                            column={{ xs: 1, sm: 2 }}
                        />
                        {/* <Form.Item
                            label="Report UID"
                            name="reportUID"
                            rules={[
                                {
                                    required: true,
                                },
                            ]}
                        >
                            <Input autoComplete="off" placeholder="Enter report UID" disabled={true} />
                        </Form.Item>
                        <Form.Item
                            label="Site"
                            name="site"
                            rules={[
                                {
                                    required: true,
                                },
                            ]}
                        >
                            <Input autoComplete="off" placeholder="Enter site" disabled={true} />
                        </Form.Item> */}
                        <Form.Item
                            label="Incident Type"
                            name="incidentType"
                            rules={[
                                {
                                    required: true,
                                },
                            ]}
                        >
                            <Select
                                showSearch
                                placeholder="Select incident type"
                                filterOption={(input, option) =>
                                    option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
                                }
                                onChange={handleFormChange}
                            >
                                {renderListOptions(appState.incidentTypeList)}
                            </Select>
                        </Form.Item>
                        <Form.Item
                            label="Status"
                            name="status"
                            rules={[
                                {
                                    required: true,
                                },
                            ]}
                        >
                            <Select
                                showSearch
                                placeholder="Select status"
                                filterOption={(input, option) =>
                                    option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
                                }
                                onChange={handleFormChange}
                            >
                                <Option key="OPEN" value="OPEN">Open</Option>
                                <Option key="CLOSED" value="CLOSED">Closed</Option>
                            </Select>
                        </Form.Item>
                        <Form.Item
                            label="Subject"
                            name="subject"
                            rules={[
                                {
                                    required: true,
                                },
                            ]}
                        >
                            <Input autoComplete="off" placeholder="Enter subject" onChange={handleFormChange} />
                        </Form.Item>
                        <Form.Item
                            label="Site"
                            name="site"
                            rules={[
                                {
                                    required: true,
                                },
                            ]}
                        >
                            <Select
                                showSearch
                                disabled
                                placeholder="Select site"
                                filterOption={(input, option) =>
                                    option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
                                }
                            >
                                {renderListOptions(appState.siteList.filter(s => s.id === appState.siteProfile.siteProfileID))}
                            </Select>
                        </Form.Item>
                        <Form.Item
                            label="Incident Datetime Start"
                            name="incidentDatetimeStart"
                            rules={[
                                {
                                    required: true,
                                },
                            ]}
                        >
                            <DatePicker showTime onChange={handleDatePickerChange} format={dateFormatList} disabledDate={disabledDate} />
                        </Form.Item>
                        <Form.Item
                            label="Incident Datetime End"
                            name="incidentDatetimeEnd"
                            rules={[
                                {
                                    required: true,
                                },
                            ]}
                        >
                            <DatePicker showTime onChange={handleDatePickerChange} format={dateFormatList} disabledDate={disabledDate} />
                        </Form.Item>
                        <Form.Item
                            label="Incident Location"
                            name="incidentLocation"
                            rules={[
                                {
                                    required: true,
                                },
                            ]}
                        >
                            <Input autoComplete="off" placeholder="Enter incident location" onChange={handleFormChange} />
                        </Form.Item>
                        {/* <Form.Item
                            label="People Involved"
                            name="peopleInvolved"
                        >
                            <TextArea rows={2} autoComplete="off" placeholder="Enter people involved" />
                        </Form.Item> */}
                        <Form.Item
                            label="Incident Description"
                            name="incidentDescription"
                            rules={[
                                {
                                    required: true,
                                },
                            ]}
                        >
                            <TextArea rows={4} autoComplete="off" placeholder="Enter incident description" onChange={handleFormChange} />
                        </Form.Item>
                        <Form.Item
                            label="Actions Taken"
                            name="actionsTaken"
                            rules={[
                                {
                                    required: true,
                                },
                            ]}
                        >
                            <TextArea rows={3} autoComplete="off" placeholder="Enter actions taken" onChange={handleFormChange} />
                        </Form.Item>
                        <Form.Item
                            label="Remarks"
                            name="remarks"
                        >
                            <TextArea rows={2} autoComplete="off" placeholder="Enter remarks" onChange={handleFormChange} />
                        </Form.Item>
                        <Form.Item
                            label="Upload Image(s)"
                        >
                            <Upload
                                action={getBase64}
                                listType="picture-card"
                                fileList={state.fileList}
                                onPreview={handlePreview}
                                onChange={(file) => handleChange(file)}
                                multiple={true}
                            >
                                {state.fileList.length >= 5 ? null : uploadButton}
                            </Upload>
                            <Modal
                                visible={state.previewVisible}
                                title={state.previewTitle}
                                footer={null}
                                onCancel={handleCancel}
                            >
                                <img alt="upload preview" style={{ width: '100%' }} src={state.previewImage} />
                            </Modal>
                        </Form.Item>
                        <div className="button">
                            <Button onClick={handleCancelUpdate}>Cancel</Button>
                            &nbsp;&nbsp;
                            {/* <Button onClick={() => onFinish(form.getFieldsValue(), 'draft')}>Save as Draft</Button>
                            &nbsp;&nbsp; */}
                            {/* <Popconfirm title={
                                <div>
                                    Are you sure?<br />
                                    No amendments can be made once report is submitted.
                                </div>
                            } onConfirm={this} okText="Yes" cancelText="No"> */}
                            <LoginConfirmation confirm={confirm} setConfirm={setConfirm} form={form} />
                            {/* </Popconfirm> */}
                        </div>
                    </Form>
                </div>
            </Spin>
            {Prompt}
        </div>
    )
}

export default IncidentReportCreate