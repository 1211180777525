import React from "react";
import ReactDOM from "react-dom";
import App from "./App";
import reportWebVitals from "./reportWebVitals";
import { Amplify } from "@aws-amplify/core"

import { getAwsExport } from "./utils/awsExportSettings"
import DataContextProvider from "./contexts/DataContext"
import AppContextProvider from "./contexts/AppContext"

Amplify.configure({
  clientMetadata: { 'source': 'SITE' },
  API: {
    graphql_headers: async () => ({
      'source': 'SITE',
      // 'business': 'AXTKD',
    })
  },
  ...getAwsExport(),
})

ReactDOM.render(
  <React.StrictMode>
    <DataContextProvider>
      <AppContextProvider>
        <App />
      </AppContextProvider>
    </DataContextProvider>
  </React.StrictMode>,
  document.getElementById('root')
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
