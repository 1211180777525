import React, { createContext, useEffect, useState } from "react"

export const DataContext = createContext()

const DataContextProvider = (props) => {
    const staffRecords = [
        {
            id: 1,
            staffNum: "A001",
            name: "Tan Ah Gao",
            mobile: "+6598765432",
            designation: 6,
            plrdGrade: 3,
            emStatus: 1,
            emType: 1,
        },
        {
            id: 2,
            staffNum: "A002",
            name: "Jenny Kim",
            mobile: "+6598765431",
            designation: 2,
            plrdGrade: 3,
            emStatus: 3,
            emType: 2,
        },
        {
            id: 3,
            staffNum: "A003",
            name: "Maria Khoo",
            mobile: "+6598765433",
            designation: 1,
            plrdGrade: 3,
            emStatus: 2,
            emType: 1,
        },
        {
            id: 4,
            staffNum: "A004",
            name: "Kenny Sim",
            mobile: "+6598765438",
            designation: 2,
            plrdGrade: 3,
            emStatus: 4,
            emType: 2,
        },
        {
            id: 5,
            staffNum: "A005",
            name: "Kumar",
            mobile: "+6598765432",
            designation: 2,
            plrdGrade: 3,
            emStatus: 1,
            emType: 1,
        },
    ]

    const attendanceRecords = [
        {
            id: 10,
            staffNum: "A005",
            name: "Kumar",
            site: "The Suites at Central",
            timeIn: "2021-02-19 06:00:00",
            timeOut: "2021-02-19 18:00:00",
            timeInKey: null,
            timeOutKey: null,
            attendanceImages: "",
            approvalStatus: "PENDING",
            approvedDuration: null,
        },
        {
            id: 9,
            staffNum: "A004",
            name: "Kenny Sim",
            site: "The Suites at Central",
            timeIn: "2021-02-16 06:00:00",
            timeOut: "2021-02-16 18:00:00",
            timeInKey: null,
            timeOutKey: null,
            attendanceImages: "",
            approvalStatus: "PENDING",
            approvedDuration: null,
        },
        {
            id: 8,
            staffNum: "A003",
            name: "Maria Khoo",
            site: "The Suites at Central",
            timeIn: "2021-02-18 06:00:00",
            timeOut: "2021-02-18 17:55:00",
            timeInKey: null,
            timeOutKey: null,
            attendanceImages: "",
            approvalStatus: "PENDING",
            approvedDuration: null,
        },
        {
            id: 7,
            staffNum: "A002",
            name: "Jenny Kim",
            site: "The Suites at Central",
            timeIn: "2021-02-17 06:00:00",
            timeOut: "2021-02-17 18:00:00",
            timeInKey: null,
            timeOutKey: null,
            attendanceImages: "",
            approvalStatus: "PENDING",
            approvedDuration: null,
        },
        {
            id: 6,
            staffNum: "A001",
            name: "Tan Ah Gao",
            site: "The Suites at Central",
            timeIn: "2021-02-16 06:00:00",
            timeOut: "2021-02-16 18:00:00",
            timeInKey: "https://pa1.narvii.com/6295/5b0ba3610592f096e1b6d647462b6fdba18ab280_hq.gif",
            timeOutKey: "https://thumbs.gfycat.com/AnnualIdolizedIbadanmalimbe-max-1mb.gif",
            attendanceImages: "",
            approvalStatus: "APPROVED",
            approvedDuration: 720, // in minutes
        },
        {
            id: 5,
            staffNum: "A005",
            name: "Kumar",
            site: "The Suites at Central",
            timeIn: "2021-02-19 06:00:00",
            timeOut: "2021-02-19 18:00:00",
            timeInKey: null,
            timeOutKey: null,
            attendanceImages: "",
            approvalStatus: "PENDING",
            approvedDuration: null,
        },
        {
            id: 4,
            staffNum: "A004",
            name: "Kenny Sim",
            site: "The Suites at Central",
            timeIn: "2021-02-16 06:00:00",
            timeOut: "2021-02-16 18:00:00",
            timeInKey: null,
            timeOutKey: null,
            attendanceImages: "",
            approvalStatus: "PENDING",
            approvedDuration: null,
        },
        {
            id: 3,
            staffNum: "A003",
            name: "Maria Khoo",
            site: "The Suites at Central",
            timeIn: "2021-02-18 06:00:00",
            timeOut: "2021-02-18 17:55:00",
            timeInKey: null,
            timeOutKey: null,
            attendanceImages: "",
            approvalStatus: "PENDING",
            approvedDuration: null,
        },
        {
            id: 2,
            staffNum: "A002",
            name: "Jenny Kim",
            site: "The Suites at Central",
            timeIn: "2021-02-17 06:00:00",
            timeOut: "2021-02-17 18:00:00",
            timeInKey: null,
            timeOutKey: null,
            attendanceImages: "",
            approvalStatus: "PENDING",
            approvedDuration: null,
        },
        {
            id: 1,
            staffNum: "A001",
            name: "Tan Ah Gao",
            site: "The Suites at Central",
            timeIn: "2021-02-16 06:00:00",
            timeOut: "2021-02-16 18:00:00",
            timeInKey: "https://pa1.narvii.com/6295/5b0ba3610592f096e1b6d647462b6fdba18ab280_hq.gif",
            timeOutKey: "https://thumbs.gfycat.com/AnnualIdolizedIbadanmalimbe-max-1mb.gif",
            attendanceImages: "",
            approvalStatus: "APPROVED",
            approvedDuration: 720, // in minutes
        },
    ]

    const incidentRecords = [
        {
            id: 5,
            reportUID: "IR-20210824-00000A",
            subject: "False fire alarm",
            incidentType: "Fire Alarm Activation",
            status: "OPEN",
            submittedBy: "Tan Ah Gao",
            incidentDatetimeStart: "2021-04-10 06:00:00",
            incidentDatetimeEnd: "2021-04-10 08:00:00",
            createdOn: "2021-04-10 10:00:00",
            updatedOn: "2021-04-10 12:00:00",
            siteName: "Site A",
            incidentLocation: "Blk 70 Lobby",
            incidentDescription: "Lorem ipsum dolor sit amet, consectetur adipiscing elit. Phasellus nec turpis ligula. In eu odio luctus, pharetra magna eu, porttitor tellus. Class aptent taciti sociosqu ad litora torquent per conubia nostra, per inceptos himenaeos. Etiam sed diam facilisis, lobortis ex quis, efficitur eros. Sed ullamcorper turpis nunc, convallis dictum elit blandit ut. Maecenas ante enim, iaculis eget tincidunt a, luctus sed enim. Etiam risus nunc, rutrum vel facilisis vitae, dapibus fermentum purus. Nulla quis quam elementum, aliquet ligula in, bibendum enim.",
            actionsTaken: "Integer hendrerit dapibus felis vitae sagittis. Sed bibendum sit amet risus ut gravida. Donec et justo ornare, lacinia justo sit amet, efficitur lectus. Mauris ac efficitur libero. Maecenas ac ligula a turpis imperdiet rhoncus at in sapien. Sed porttitor ligula commodo lacinia dapibus. Suspendisse quis aliquam nunc, ac sagittis leo. Suspendisse suscipit suscipit placerat. Sed consectetur quam fringilla metus venenatis pulvinar. Praesent et augue volutpat, fringilla libero id, varius augue. Aliquam vel tellus purus. Etiam nec condimentum nulla. Integer risus nulla, viverra at facilisis non, euismod a nunc.",
            remarks: "Hey hey there!",
            imagesKey: "[{\"key\":\"https://i0.wp.com/jasonyu.me/wp-content/uploads/2018/06/pokemonred-e1529430185757.jpg?resize=936%2C527\"},{\"key\":\"https://www.giantbomb.com/a/uploads/scale_small/0/9740/270122-pok_mon_firered_first_battle.png\"}]",
        },
        {
            id: 4,
            reportUID: "IR-20210824-00000A",
            subject: "False fire alarm",
            incidentType: "Fire Alarm Activation",
            status: "CLOSED",
            submittedBy: "Tan Ah Gao",
            incidentDatetimeStart: "2021-04-10 06:00:00",
            incidentDatetimeEnd: "2021-04-10 08:00:00",
            createdOn: "2021-04-10 10:00:00",
            updatedOn: "2021-04-10 12:00:00",
            siteName: "Site A",
            incidentLocation: "Blk 70 Lobby",
            incidentDescription: "Lorem ipsum dolor sit amet, consectetur adipiscing elit. Phasellus nec turpis ligula. In eu odio luctus, pharetra magna eu, porttitor tellus. Class aptent taciti sociosqu ad litora torquent per conubia nostra, per inceptos himenaeos. Etiam sed diam facilisis, lobortis ex quis, efficitur eros. Sed ullamcorper turpis nunc, convallis dictum elit blandit ut. Maecenas ante enim, iaculis eget tincidunt a, luctus sed enim. Etiam risus nunc, rutrum vel facilisis vitae, dapibus fermentum purus. Nulla quis quam elementum, aliquet ligula in, bibendum enim.",
            actionsTaken: "Integer hendrerit dapibus felis vitae sagittis. Sed bibendum sit amet risus ut gravida. Donec et justo ornare, lacinia justo sit amet, efficitur lectus. Mauris ac efficitur libero. Maecenas ac ligula a turpis imperdiet rhoncus at in sapien. Sed porttitor ligula commodo lacinia dapibus. Suspendisse quis aliquam nunc, ac sagittis leo. Suspendisse suscipit suscipit placerat. Sed consectetur quam fringilla metus venenatis pulvinar. Praesent et augue volutpat, fringilla libero id, varius augue. Aliquam vel tellus purus. Etiam nec condimentum nulla. Integer risus nulla, viverra at facilisis non, euismod a nunc.",
            remarks: "This is a remark!",
            imagesKey: "[{\"key\":\"https://i0.wp.com/jasonyu.me/wp-content/uploads/2018/06/pokemonred-e1529430185757.jpg?resize=936%2C527\"}]",
        },
        {
            id: 3,
            reportUID: "IR-20210824-00000A",
            subject: "Argument between residents",
            incidentType: "General",
            status: "OPEN",
            submittedBy: "Tan Ah Gao",
            incidentDatetimeStart: "2021-04-10 06:00:00",
            incidentDatetimeEnd: "2021-04-10 06:00:00",
            createdOn: "2021-04-10 06:00:00",
            updatedOn: "2021-04-10 06:00:00",
            siteName: "Site A",
            incidentLocation: "Blk 70 Lobby",
            incidentDescription: "Lorem ipsum dolor sit amet, consectetur adipiscing elit. Phasellus nec turpis ligula. In eu odio luctus, pharetra magna eu, porttitor tellus. Class aptent taciti sociosqu ad litora torquent per conubia nostra, per inceptos himenaeos. Etiam sed diam facilisis, lobortis ex quis, efficitur eros. Sed ullamcorper turpis nunc, convallis dictum elit blandit ut. Maecenas ante enim, iaculis eget tincidunt a, luctus sed enim. Etiam risus nunc, rutrum vel facilisis vitae, dapibus fermentum purus. Nulla quis quam elementum, aliquet ligula in, bibendum enim.",
            actionsTaken: "Integer hendrerit dapibus felis vitae sagittis. Sed bibendum sit amet risus ut gravida. Donec et justo ornare, lacinia justo sit amet, efficitur lectus. Mauris ac efficitur libero. Maecenas ac ligula a turpis imperdiet rhoncus at in sapien. Sed porttitor ligula commodo lacinia dapibus. Suspendisse quis aliquam nunc, ac sagittis leo. Suspendisse suscipit suscipit placerat. Sed consectetur quam fringilla metus venenatis pulvinar. Praesent et augue volutpat, fringilla libero id, varius augue. Aliquam vel tellus purus. Etiam nec condimentum nulla. Integer risus nulla, viverra at facilisis non, euismod a nunc.",
            remarks: "",
            imagesKey: "[]",
        },
        {
            id: 2,
            reportUID: "IR-20210824-00000A",
            subject: "Damanged lighting at side gate",
            incidentType: "Faulty Equipment",
            status: "CLOSED",
            submittedBy: "Tan Ah Gao",
            incidentDatetimeStart: "2021-04-10 06:00:00",
            incidentDatetimeEnd: "2021-04-10 08:00:00",
            createdOn: "2021-04-10 08:00:00",
            updatedOn: "2021-04-10 08:00:00",
            siteName: "Site A",
            incidentLocation: "Blk 70 Lobby",
            incidentDescription: "Lorem ipsum dolor sit amet, consectetur adipiscing elit. Phasellus nec turpis ligula. In eu odio luctus, pharetra magna eu, porttitor tellus. Class aptent taciti sociosqu ad litora torquent per conubia nostra, per inceptos himenaeos. Etiam sed diam facilisis, lobortis ex quis, efficitur eros. Sed ullamcorper turpis nunc, convallis dictum elit blandit ut. Maecenas ante enim, iaculis eget tincidunt a, luctus sed enim. Etiam risus nunc, rutrum vel facilisis vitae, dapibus fermentum purus. Nulla quis quam elementum, aliquet ligula in, bibendum enim.",
            actionsTaken: "Integer hendrerit dapibus felis vitae sagittis. Sed bibendum sit amet risus ut gravida. Donec et justo ornare, lacinia justo sit amet, efficitur lectus. Mauris ac efficitur libero. Maecenas ac ligula a turpis imperdiet rhoncus at in sapien. Sed porttitor ligula commodo lacinia dapibus. Suspendisse quis aliquam nunc, ac sagittis leo. Suspendisse suscipit suscipit placerat. Sed consectetur quam fringilla metus venenatis pulvinar. Praesent et augue volutpat, fringilla libero id, varius augue. Aliquam vel tellus purus. Etiam nec condimentum nulla. Integer risus nulla, viverra at facilisis non, euismod a nunc.",
            remarks: "This is another remark.",
            imagesKey: "[]",
        },
        {
            id: 1,
            reportUID: "IR-20210824-00000A",
            subject: "Lost mobile phone",
            incidentType: "Lost and Found",
            status: "OPEN",
            submittedBy: "Tan Ah Gao",
            incidentDatetimeStart: "2021-04-10 06:00:00",
            incidentDatetimeEnd: "2021-04-10 06:00:00",
            createdOn: "2021-04-10 06:00:00",
            updatedOn: "2021-04-10 06:00:00",
            siteName: "Site A",
            incidentLocation: "Blk 70 Lobby",
            incidentDescription: "Lorem ipsum dolor sit amet, consectetur adipiscing elit. Phasellus nec turpis ligula. In eu odio luctus, pharetra magna eu, porttitor tellus. Class aptent taciti sociosqu ad litora torquent per conubia nostra, per inceptos himenaeos. Etiam sed diam facilisis, lobortis ex quis, efficitur eros. Sed ullamcorper turpis nunc, convallis dictum elit blandit ut. Maecenas ante enim, iaculis eget tincidunt a, luctus sed enim. Etiam risus nunc, rutrum vel facilisis vitae, dapibus fermentum purus. Nulla quis quam elementum, aliquet ligula in, bibendum enim.",
            actionsTaken: "Integer hendrerit dapibus felis vitae sagittis. Sed bibendum sit amet risus ut gravida. Donec et justo ornare, lacinia justo sit amet, efficitur lectus. Mauris ac efficitur libero. Maecenas ac ligula a turpis imperdiet rhoncus at in sapien. Sed porttitor ligula commodo lacinia dapibus. Suspendisse quis aliquam nunc, ac sagittis leo. Suspendisse suscipit suscipit placerat. Sed consectetur quam fringilla metus venenatis pulvinar. Praesent et augue volutpat, fringilla libero id, varius augue. Aliquam vel tellus purus. Etiam nec condimentum nulla. Integer risus nulla, viverra at facilisis non, euismod a nunc.",
            remarks: "",
            imagesKey: "[]",
        },
    ]

    const incidentDraftRecords = [
        {
            id: 1,
            reportUID: "IR-20210824-00000A",
            subject: "Lost mobile phone",
            incidentType: "Lost and Found",
            status: "DRAFT",
            submittedBy: "Tan Ah Gao",
            incidentDatetimeStart: "2021-04-10 06:00:00",
            incidentDatetimeEnd: "2021-04-10 06:00:00",
            createdOn: "2021-04-10 06:00:00",
            updatedOn: "2021-04-10 06:00:00",
            siteName: "Site A",
            incidentLocation: "Blk 70 Lobby",
            incidentDescription: "Lorem ipsum dolor sit amet, consectetur adipiscing elit. Phasellus nec turpis ligula. In eu odio luctus, pharetra magna eu, porttitor tellus. Class aptent taciti sociosqu ad litora torquent per conubia nostra, per inceptos himenaeos. Etiam sed diam facilisis, lobortis ex quis, efficitur eros. Sed ullamcorper turpis nunc, convallis dictum elit blandit ut. Maecenas ante enim, iaculis eget tincidunt a, luctus sed enim. Etiam risus nunc, rutrum vel facilisis vitae, dapibus fermentum purus. Nulla quis quam elementum, aliquet ligula in, bibendum enim.",
            actionsTaken: "Integer hendrerit dapibus felis vitae sagittis. Sed bibendum sit amet risus ut gravida. Donec et justo ornare, lacinia justo sit amet, efficitur lectus. Mauris ac efficitur libero. Maecenas ac ligula a turpis imperdiet rhoncus at in sapien. Sed porttitor ligula commodo lacinia dapibus. Suspendisse quis aliquam nunc, ac sagittis leo. Suspendisse suscipit suscipit placerat. Sed consectetur quam fringilla metus venenatis pulvinar. Praesent et augue volutpat, fringilla libero id, varius augue. Aliquam vel tellus purus. Etiam nec condimentum nulla. Integer risus nulla, viverra at facilisis non, euismod a nunc.",
            remarks: "",
            imagesKey: "[{\"key\":\"https://i0.wp.com/jasonyu.me/wp-content/uploads/2018/06/pokemonred-e1529430185757.jpg?resize=936%2C527\"},{\"key\":\"https://www.giantbomb.com/a/uploads/scale_small/0/9740/270122-pok_mon_firered_first_battle.png\"}]",
        },
    ]

    const incidentCommentRecords = [
        {
            id: 1,
            name: "Tan Ah Gao",
            createdOn: "2021-04-10 07:00:00",
            updatedOn: "2021-04-10 07:00:00",
            comment: "Lorem ipsum dolor sit amet, consectetur adipiscing elit. Phasellus nec turpis ligula. In eu odio luctus, pharetra magna eu, porttitor tellus. Class aptent taciti sociosqu ad litora torquent per conubia nostra, per inceptos himenaeos. Etiam sed diam facilisis, lobortis ex quis, efficitur eros. Sed ullamcorper turpis nunc, convallis dictum elit blandit ut. Maecenas ante enim, iaculis eget tincidunt a, luctus sed enim. Etiam risus nunc, rutrum vel facilisis vitae, dapibus fermentum purus. Nulla quis quam elementum, aliquet ligula in, bibendum enim."
        },
        {
            id: 2,
            name: "Jenny Lee",
            createdOn: "2021-04-10 08:00:00",
            updatedOn: "2021-04-10 08:00:00",
            comment: "Integer hendrerit dapibus felis vitae sagittis. Sed bibendum sit amet risus ut gravida. Donec et justo ornare, lacinia justo sit amet, efficitur lectus."
        },
        {
            id: 3,
            name: "Tan Ah Gao",
            createdOn: "2021-04-10 09:00:00",
            updatedOn: "2021-04-10 09:00:00",
            comment: "Lorem ipsum dolor sit amet, consectetur adipiscing elit. Phasellus nec turpis ligula. In eu odio luctus, pharetra magna eu, porttitor tellus. Class aptent taciti sociosqu ad litora torquent per conubia nostra, per inceptos himenaeos. Etiam sed diam facilisis, lobortis ex quis, efficitur eros. Sed ullamcorper turpis nunc, convallis dictum elit blandit ut. Maecenas ante enim, iaculis eget tincidunt a, luctus sed enim. Etiam risus nunc, rutrum vel facilisis vitae, dapibus fermentum purus. Nulla quis quam elementum, aliquet ligula in, bibendum enim."
        },
    ]

    const visitorRecords = [
        {
            id: 5,
            datetime: "2021-04-10 06:00:00",
            site: "Security Site",
            name: "Tay",
            contactNum: "98765432",
            purposeOfVisit: "Visiting",
            block: "70",
            unit: "08-08",
            vehicleNum: "",
            remarks: "",
            submissionType: "FORM",
        },
        {
            id: 4,
            datetime: "2021-04-10 06:00:00",
            site: "Security Site",
            name: "Tay",
            contactNum: "98765432",
            purposeOfVisit: "Visiting",
            block: "70",
            unit: "08-08",
            vehicleNum: "",
            remarks: "",
            submissionType: "QR",
        },
        {
            id: 3,
            datetime: "2021-04-10 06:00:00",
            site: "Security Site",
            name: "Tay",
            contactNum: "98765432",
            purposeOfVisit: "Maintenance",
            block: "70",
            unit: "08-08",
            vehicleNum: "SGP123A",
            remarks: "",
            submissionType: "FORM",
        },
        {
            id: 2,
            datetime: "2021-04-10 06:00:00",
            site: "Security Site",
            name: "Tay",
            contactNum: "98765432",
            purposeOfVisit: "Visiting",
            block: "70",
            unit: "08-08",
            vehicleNum: "SGP123A",
            remarks: "",
            submissionType: "FORM",
        },
        {
            id: 1,
            datetime: "2021-04-10 06:00:00",
            site: "Security Site",
            name: "Tay",
            contactNum: "98765432",
            purposeOfVisit: "Delivery",
            block: "70",
            unit: "08-08",
            vehicleNum: "",
            remarks: "Grab",
            submissionType: "FORM",
        },
    ]

    const siteRecords = [
        {
            id: 1,
            site: "Apple Store Orchard",
            contactNum: "98765432",
            status: "ACTIVE",
            block: "12",
            streetName: "Ping Guo Steet",
            unitNum: "01-01",
            postalCode: "890765",
            // client: "Apple Inc",
            clientId: 1,
        },
        {
            id: 2,
            site: "Samsung Store Orchard",
            contactNum: "98765432",
            status: "ACTIVE",
            block: "12",
            streetName: "San Xing Steet",
            unitNum: "01-01",
            postalCode: "890765",
            // client: "Samsung",
            clientId: 2,
        },
        {
            id: 3,
            site: "Google Office",
            contactNum: "98765432",
            status: "ACTIVE",
            block: "12",
            streetName: "Gu Ge Steet",
            unitNum: "01-01",
            postalCode: "890765",
            // client: "Google",
            clientId: 3,
        },
        {
            id: 4,
            site: "Shopee Office",
            contactNum: "98765432",
            status: "ACTIVE",
            block: "12",
            streetName: "Pee Pee Steet",
            unitNum: "01-01",
            postalCode: "890765",
            // client: "Sea Ltd",
            clientId: 4,
        },
        {
            id: 5,
            site: "Xiaomi Office",
            contactNum: "98765432",
            status: "INACTIVE",
            block: "12",
            streetName: "Ping Guo Steet",
            unitNum: "01-01",
            postalCode: "890765",
            // client: "Xiaomi",
            clientId: 5,
        },
    ]

    const clientRecords = [
        {
            id: 1,
            client: "Apple Inc",
            contactNum: "98765432",
            status: "ACTIVE",
            block: "12",
            streetName: "Ping Guo Steet",
            unitNum: "01-01",
            postalCode: "890765",
        },
        {
            id: 2,
            client: "Samsung",
            contactNum: "98765432",
            status: "ACTIVE",
            block: "12",
            streetName: "San Xing Steet",
            unitNum: "01-01",
            postalCode: "890765",
        },
        {
            id: 3,
            client: "Google",
            contactNum: "98765432",
            status: "ACTIVE",
            block: "12",
            streetName: "Gu Ge Steet",
            unitNum: "01-01",
            postalCode: "890765",
        },
        {
            id: 4,
            client: "Sea Ltd",
            contactNum: "98765432",
            status: "ACTIVE",
            block: "12",
            streetName: "Pee Pee Steet",
            unitNum: "01-01",
            postalCode: "890765",
        },
        {
            id: 5,
            client: "Xiaomi",
            contactNum: "98765432",
            status: "INACTIVE",
            block: "12",
            streetName: "Ping Guo Steet",
            unitNum: "01-01",
            postalCode: "890765",
        },
    ]

    const clientUserRecords = [
        {
            id: 1,
            client: 1,
            clientDisplay: "Apple Inc",
            status: "ACTIVE",
            name: "Chan Ah Bee",
            mobile: "+6598765432",
            userStatus: "ACTIVE",
        },
        {
            id: 2,
            client: 2,
            clientDisplay: "Samsung",
            status: "ACTIVE",
            name: "Too Ah Bee",
            mobile: "+6598765432",
            userStatus: "ACTIVE",
        },
        {
            id: 3,
            client: 3,
            status: "ACTIVE",
            clientDisplay: "Google",
            name: "Tee Ah Bee",
            mobile: "+6598765432",
            userStatus: "ACTIVE",
        },
        {
            id: 4,
            client: 4,
            clientDisplay: "Sea Ltd",
            status: "ACTIVE",
            name: "Foo Ah Bee",
            mobile: "+6598765432",
            userStatus: "ACTIVE",
        },
        {
            id: 5,
            client: 5,
            clientDisplay: "Xiaomi",
            status: "INACTIVE",
            name: "Fan Ah Bee",
            mobile: "+6598765432",
            userStatus: "ACTIVE",
        },
    ]

    const patrolRecords = [
        {
            id: 1,
            datetimeStart: "2021-04-10 06:00:00",
            datetimeEnd: "2021-04-10 08:00:00",
            siteName: "Site A",
            routeName: "Route 1",
            status: "COMPLETED",
            staffNum: "A001",
            staffName: "Tan Ah Gao",
            remarks: "This is an extremely long remarks. We need not show the whole thing here because it will look very weird. Hahaha ><",
        },
        {
            id: 2,
            datetimeStart: "2021-04-10 06:00:00",
            datetimeEnd: "2021-04-10 08:00:00",
            siteName: "Site B",
            routeName: "Route A",
            status: "COMPLETED",
            staffNum: "A001",
            staffName: "Tan Ah Gao",
            remarks: "",
        },
        {
            id: 3,
            datetimeStart: "2021-04-10 06:00:00",
            datetimeEnd: "2021-04-10 08:00:00",
            siteName: "Site A",
            routeName: "Route 2",
            status: "SEMI-COMPLETED",
            staffNum: "A001",
            staffName: "Tan Ah Gao",
            remarks: "Raining cannot access certain checkpoint.",
        },
        {
            id: 4,
            datetimeStart: "2021-04-10 06:00:00",
            datetimeEnd: "2021-04-10 08:00:00",
            siteName: "Site A",
            routeName: "Route 3",
            status: "DISRUPTED",
            staffNum: "A001",
            staffName: "Tan Ah Gao",
            remarks: "Incident to attend to.",
        },
        {
            id: 5,
            datetimeStart: "2021-04-10 06:00:00",
            datetimeEnd: "2021-04-10 08:00:00",
            siteName: "Site A",
            routeName: "Route 1",
            status: "PENDING",
            staffNum: "A001",
            staffName: "Tan Ah Gao",
            remarks: "",
        },
        {
            id: 6,
            datetimeStart: "2021-04-10 06:00:00",
            datetimeEnd: "2021-04-10 08:00:00",
            siteName: "Site A",
            routeName: "Route 1",
            status: "COMPLETED",
            staffNum: "A001",
            staffName: "Tan Ah Gao",
            remarks: "",
        },
    ]

    const patrolCheckPointRecords = [
        {
            id: 1,
            checkpoint: "Point A",
            scanned: 1,
            datetime: "2021-04-5 06:00:00",
            remarks: "",
        },
        {
            id: 2,
            checkpoint: "Point B",
            scanned: 1,
            datetime: "2021-04-5 06:00:00",
            remarks: "",
        },
        {
            id: 3,
            checkpoint: "Point C",
            scanned: 1,
            datetime: "2021-04-5 06:00:00",
            remarks: "",
        },
        {
            id: 4,
            checkpoint: "Point D",
            scanned: 1,
            datetime: "2021-04-5 06:00:00",
            remarks: "",
        },
        {
            id: 5,
            checkpoint: "Point E",
            scanned: 1,
            datetime: "2021-04-5 06:00:00",
            remarks: "",
        },
        {
            id: 6,
            checkpoint: "Point F",
            scanned: 1,
            datetime: "2021-04-5 06:00:00",
            remarks: "",
        },
        {
            id: 7,
            checkpoint: "Point G",
            scanned: 1,
            datetime: "2021-04-5 06:00:00",
            remarks: "",
        },
        {
            id: 8,
            checkpoint: "Point H",
            scanned: 0,
            datetime: "",
            remarks: "",
        },
    ]

    const checkpointRecords = [
        {
            id: 1,
            name: "Checkpoint A",
            tagId: "A123123B",
            status: "ACTIVE",
            siteId: 1,
            site: "Inimicode",
        },
        {
            id: 2,
            name: "Checkpoint B",
            tagId: "A123123C",
            status: "ACTIVE",
            siteId: 1,
            site: "Inimicode",
        },
        {
            id: 3,
            name: "Checkpoint C",
            tagId: "A123123Q",
            status: "ACTIVE",
            siteId: 1,
            site: "Inimicode",
        },
        {
            id: 4,
            name: "Checkpoint D",
            tagId: "A123123W",
            status: "ACTIVE",
            siteId: 1,
            site: "Inimicode",
        },
        {
            id: 5,
            name: "Checkpoint 1",
            tagId: "A123123O",
            status: "ACTIVE",
            siteId: 2,
            site: "The Suites at Central",
        },
        {
            id: 6,
            name: "Checkpoint 2",
            tagId: "A123123P",
            status: "INACTIVE",
            siteId: 2,
            site: "The Suites at Central",
        },
        {
            id: 7,
            name: "Checkpoint E",
            tagId: "A123121Q",
            status: "ACTIVE",
            siteId: 1,
            site: "Inimicode",
        },
        {
            id: 8,
            name: "Checkpoint F",
            tagId: "A123121W",
            status: "ACTIVE",
            siteId: 1,
            site: "Inimicode",
        },
        {
            id: 9,
            name: "Checkpoint 3",
            tagId: "A123121O",
            status: "ACTIVE",
            siteId: 2,
            site: "The Suites at Central",
        },
        {
            id: 10,
            name: "Checkpoint 4",
            tagId: "A123121P",
            status: "INACTIVE",
            siteId: 2,
            site: "The Suites at Central",
        },
    ]

    const routeRecords = [
        {
            id: 1,
            name: "Route A",
            status: "ACTIVE",
            siteId: 1,
        },
        {
            id: 2,
            name: "Route B",
            status: "ACTIVE",
            siteId: 1,
        },
        {
            id: 3,
            name: "Route 1",
            status: "ACTIVE",
            siteId: 2,
        },
        {
            id: 4,
            name: "Route 2",
            status: "INACTIVE",
            siteId: 2,
        },
        {
            id: 5,
            name: "Route Y",
            status: "ACTIVE",
            siteId: 3,
        },
    ]

    const plrdRecords = [
        {
            id: 4,
            plrdId: 4,
            dateIssued: "2021-02-25 06:00:00",
        },
        {
            id: 3,
            plrdId: 3,
            dateIssued: "2021-02-20 06:00:00",
        },
        {
            id: 2,
            plrdId: 2,
            dateIssued: "2021-02-18 06:00:00",
        },
        {
            id: 1,
            plrdId: 1,
            dateIssued: "2021-02-16 06:00:00",
        },
    ]

    const profilePersonal = {
        name: "Jason",
        nric: "S9876543A",
        // nric: "*****543A",
        dob: "1990-01-01 00:00:00",
        gender: "MALE",
        race: 2,
        nationality: 2,
        citizenship: 2,
        prDateOfIssue: "",
        religion: 1,
        maritalStatus: "SINGLE",
        email: "email@email.com",
        mobile: "+6598765432",
    }

    const profileAgency = {
        id: 1,
        client: 1,
        clientDisplay: "Apple Inc",
        status: "ACTIVE",
        name: "Chan Ah Bee",
        mobile: "+6598765432",
        userStatus: "ACTIVE",
    }

    const currentWorkRecords = [
        {
            id: 3,
            designation: "Security Supervisor",
            contractType: "Full-Time",
            agency: "Inimicode Security",
            dateJoined: "2021-04-25 06:00:00",
            dateEnded: null,
        },
    ]

    // const permissionStaffAdminRecordsArchive = [
    //     {
    //         id: 1,
    //         staffNum: "A001",
    //         name: "Tan Ah Gao",
    //         cAdminAccount: 1,
    //         rAdminAccount: 1,
    //         uAdminAccount: 1,
    //         dAdminAccount: 1,
    //         cStaffAccount: 1,
    //         rStaffAccount: 1,
    //         uStaffAccount: 1,
    //         dStaffAccount: 1,
    //         cAttendance: -1,
    //         rAttendance: 1,
    //         uAttendanceApprove: 1,
    //         uAttendanceTiming: 0,
    //         dAttendance: -1,
    //         cPatrol: -1,
    //         rPatrol: 1,
    //         uPatrol: 0,
    //         dPatrol: -1,
    //         cIncident: 1,
    //         rIncident: 1,
    //         uIncident: 0,
    //         dIncident: -1,
    //         cVisitor: -1,
    //         rVisitor: 1,
    //         uVisitor: 0,
    //         dVisitor: -1,
    //     },
    //     {
    //         id: 2,
    //         staffNum: "A002",
    //         name: "Jenny Kim",
    //         cAdminAccount: -1,
    //         rAdminAccount: -1,
    //         uAdminAccount: -1,
    //         dAdminAccount: -1,
    //         cStaffAccount: 1,
    //         rStaffAccount: 1,
    //         uStaffAccount: 1,
    //         dStaffAccount: 1,
    //         cAttendance: 1,
    //         rAttendance: 1,
    //         uAttendance: -1,
    //         dAttendance: -1,
    //         cPatrol: 1,
    //         rPatrol: 1,
    //         uPatrol: -1,
    //         dPatrol: -1,
    //         cIncident: 1,
    //         rIncident: 1,
    //         uIncident: -1,
    //         dIncident: -1,
    //         cVisitor: 1,
    //         rVisitor: 1,
    //         uVisitor: -1,
    //         dVisitor: -1,
    //     },
    // ]

    const permissionStaffAdminRecords = [
        {
            id: 1,
            staffNum: "A001",
            name: "Tan Ah Gao",
            permissions: "[2, 3, 7, 12, 16, 20, 23]"
        },
        {
            id: 2,
            staffNum: "A002",
            name: "Jenny Kim",
            permissions: "[2, 3, 7, 12, 16, 20, 23]"
        },
    ]

    // const permissionStaffGeneralRecordsArchive = [
    //     {
    //         id: 1,
    //         staffNum: "A001",
    //         name: "Tan Ah Gao",
    //         cAttendance: 1,
    //         rAttendance: 1,
    //         uAttendance: -1,
    //         dAttendance: -1,
    //         cPatrol: 1,
    //         rPatrol: 1,
    //         uPatrol: -1,
    //         dPatrol: -1,
    //         cIncident: 1,
    //         rIncident: 1,
    //         uIncident: -1,
    //         dIncident: -1,
    //         cVisitor: 1,
    //         rVisitor: 1,
    //         uVisitor: -1,
    //         dVisitor: -1,
    //     },
    //     {
    //         id: 2,
    //         staffNum: "A002",
    //         name: "Jenny Kim",
    //         cAttendance: 1,
    //         rAttendance: 1,
    //         uAttendance: -1,
    //         dAttendance: -1,
    //         cPatrol: 1,
    //         rPatrol: 1,
    //         uPatrol: -1,
    //         dPatrol: -1,
    //         cIncident: 1,
    //         rIncident: 1,
    //         uIncident: -1,
    //         dIncident: -1,
    //         cVisitor: 1,
    //         rVisitor: 1,
    //         uVisitor: -1,
    //         dVisitor: -1,
    //     },
    //     {
    //         id: 3,
    //         staffNum: "A003",
    //         name: "Maria Khoo",
    //         cAttendance: 1,
    //         rAttendance: 1,
    //         uAttendance: -1,
    //         dAttendance: -1,
    //         cPatrol: 1,
    //         rPatrol: 1,
    //         uPatrol: -1,
    //         dPatrol: -1,
    //         cIncident: 1,
    //         rIncident: 1,
    //         uIncident: -1,
    //         dIncident: -1,
    //         cVisitor: 1,
    //         rVisitor: 1,
    //         uVisitor: -1,
    //         dVisitor: -1,
    //     },
    //     {
    //         id: 4,
    //         staffNum: "A004",
    //         name: "Kenny Sim",
    //         cAttendance: 1,
    //         rAttendance: 1,
    //         uAttendance: -1,
    //         dAttendance: -1,
    //         cPatrol: 1,
    //         rPatrol: 1,
    //         uPatrol: -1,
    //         dPatrol: -1,
    //         cIncident: 1,
    //         rIncident: 1,
    //         uIncident: -1,
    //         dIncident: -1,
    //         cVisitor: 1,
    //         rVisitor: 1,
    //         uVisitor: -1,
    //         dVisitor: -1,
    //     },
    //     {
    //         id: 5,
    //         staffNum: "A005",
    //         name: "Kumar",
    //         cAttendance: 1,
    //         rAttendance: 1,
    //         uAttendance: -1,
    //         dAttendance: -1,
    //         cPatrol: 1,
    //         rPatrol: 1,
    //         uPatrol: -1,
    //         dPatrol: -1,
    //         cIncident: 1,
    //         rIncident: 1,
    //         uIncident: -1,
    //         dIncident: -1,
    //         cVisitor: 1,
    //         rVisitor: 1,
    //         uVisitor: -1,
    //         dVisitor: -1,
    //     },
    // ]

    const permissionStaffGeneralRecords = [
        {
            id: 1,
            staffNum: "A001",
            name: "Tan Ah Gao",
            permissions: "[1, 6, 11, 15]"
        },
        {
            id: 2,
            staffNum: "A002",
            name: "Jenny Kim",
            permissions: "[1, 6, 11, 15]"
        },
        {
            id: 3,
            staffNum: "A003",
            name: "Maria Khoo",
            permissions: "[1, 6, 11, 15]"
        },
        {
            id: 4,
            staffNum: "A004",
            name: "Kenny Sim",
            permissions: "[1, 6, 11, 15]"
        },
        {
            id: 5,
            staffNum: "A005",
            name: "Kumar",
            permissions: "[1, 6, 11, 15]"
        },
    ]

    // const staffPermissionGeneralArchive = {
    //     // cAttendance: 1,
    //     // rAttendance: 1,
    //     cAttendance: 0,
    //     rAttendance: 0,
    //     uAttendance: -1,
    //     dAttendance: -1,
    //     cPatrol: 1,
    //     rPatrol: 1,
    //     uPatrol: -1,
    //     dPatrol: -1,
    //     cIncident: 1,
    //     rIncident: 1,
    //     uIncident: -1,
    //     dIncident: -1,
    //     cVisitor: 1,
    //     rVisitor: 1,
    //     uVisitor: -1,
    //     dVisitor: -1,
    // }

    const staffPermissionGeneral = {
        permissions: "[1, 6, 11, 15]"
    }

    // const staffPermissionAdminArchive = {
    //     adminPermission: 0,
    //     cAdminAccount: 1,
    //     rAdminAccount: 1,
    //     uAdminAccount: 1,
    //     dAdminAccount: 1,
    //     cStaffAccount: 1,
    //     rStaffAccount: 1,
    //     uStaffAccount: 1,
    //     dStaffAccount: 1,
    //     cAttendance: -1,
    //     rAttendance: 1,
    //     uAttendance: 1,
    //     dAttendance: -1,
    //     cPatrol: -1,
    //     rPatrol: 1,
    //     uPatrol: 0,
    //     dPatrol: -1,
    //     cIncident: 1,
    //     rIncident: 1,
    //     uIncident: 0,
    //     dIncident: -1,
    //     cVisitor: -1,
    //     rVisitor: 1,
    //     uVisitor: 0,
    //     dVisitor: -1,
    // }

    const staffPermissionAdmin = {
        adminPermission: 0,
        permissions: "[2, 3, 7, 12, 16, 20, 23]"
    }

    const clientPermissionRecords = [
        {
            id: 1,
            client: "Apple Inc",
            permissions: "[2, 7, 11, 16]"
        },
        {
            id: 2,
            client: "Samsung",
            permissions: "[2, 7, 11, 16]"
        },
        {
            id: 3,
            client: "Google",
            permissions: "[2, 7, 11, 16]"
        },
        {
            id: 4,
            client: "Sea Ltd",
            permissions: "[2, 7, 11, 16]"
        },
        {
            id: 2,
            client: "Samsung",
            permissions: "[2, 7, 11, 16]"
        },
    ]

    const clientPermission = {
        permissions: "[2, 7, 11, 16]"
    }

    // ====================

    const incidentTypeList = [
        { id: 1, name: "General" },
        { id: 2, name: "Access Control" },
        { id: 3, name: "Accident" },
        { id: 4, name: "Fire Alarm Activation" },
        { id: 5, name: "Lost and Found" },
        { id: 6, name: "Suspicious Person, Vehicle and Item" },
        { id: 7, name: "Faulty Equipment" },
    ]

    const citizenshipList = [
        { id: 1, name: "Others" },
        { id: 2, name: "Singapore" },
        { id: 3, name: "Malaysia" },
    ]

    const nationalityList = [
        { id: 1, name: "Others" },
        { id: 2, name: "Singaporean" },
        { id: 3, name: "Malaysian" },
    ]

    const raceList = [
        { id: 1, name: "Others" },
        { id: 2, name: "Chinese" },
        { id: 3, name: "Malay" },
        { id: 4, name: "Indian" },
    ]

    const religionList = [
        { id: 1, name: "None" },
        { id: 2, name: "Others" },
        { id: 3, name: "Baha’i faith" },
        { id: 4, name: "Buddhism" },
        { id: 5, name: "Christianity" },
        { id: 6, name: "Hinduism" },
        { id: 7, name: "Islam" },
        { id: 8, name: "Jainism" },
        { id: 9, name: "Judaism" },
        { id: 10, name: "Sikhism" },
        { id: 11, name: "Taoism" },
        { id: 12, name: "Zoroastrianism" },
    ]

    const plrdGradeList = [
        { id: 1, name: "Security Officer (SO)" },
        { id: 2, name: "Senior Security Officer (SSO)" },
        { id: 3, name: "Security Supervisor (SS)" },
        { id: 4, name: "Senior Security Supervisor (SSS)" },
        { id: 5, name: "Chief Security Officer (CSO)" },
    ]

    const designationList = [
        { id: 1, name: "Security Officer (SO)", code: "SO" },
        { id: 2, name: "Senior Security Officer (SSO)", code: "SSO" },
        { id: 3, name: "Security Supervisor (SS)", code: "SS" },
        { id: 4, name: "Senior Security Supervisor (SSS)", code: "SSS" },
        { id: 5, name: "Chief Security Officer (CSO)", code: "CSO" },
        { id: 6, name: "Admin", code: "ADMIN" },
        // { id: 7, name: "Others" },
    ]

    const emStatusList = [
        { id: 1, name: "Active", code: "ACTIVE" },
        { id: 2, name: "Inactive", code: "INACTIVE" },
        { id: 3, name: "Incoming", code: "INCOMING" },
        { id: 4, name: "Ended", code: "ENDED" },
    ]

    const emTypeList = [
        { id: 1, name: "Full-Time", code: "FT" },
        { id: 2, name: "Part-Time", code: "PT" },
    ]

    const purposeOfVisitList = [
        { id: 1, name: "Others" },
        { id: 2, name: "Visiting" },
        { id: 3, name: "Delivery" },
        { id: 4, name: "Pick up / Drop off" },
        { id: 5, name: "Tuition" },
        { id: 6, name: "Maintenance" },
        { id: 7, name: "Property viewing" },
    ]

    const siteList = [ // sites under agency
        { id: 1, name: "Inimicode" },
        { id: 2, name: "The Suites at Central" },
        { id: 3, name: "Parc Centros" },
        { id: 4, name: "Grand Duchess" },
        { id: 5, name: "Hazel Park Condo" },
    ]

    const clientList = [ // clients under agency
        { id: 1, name: "Apple Inc" },
        { id: 2, name: "Samsung" },
        { id: 3, name: "Google" },
        { id: 4, name: "Sea Ltd" },
        { id: 5, name: "Xiaomi" },
    ]

    const permissionList = [ // simplified list, refer to document for details
        {
            id: 1,
            name: "Create and view attendance records",
            module: "attendance",
        },
        {
            id: 2,
            name: "View attendance records",
            module: "attendance",
        },
        {
            id: 3,
            name: "Approve/Reject attendance records",
            module: "attendance",
        },
        {
            id: 4,
            name: "Create and edit attendance records",
            module: "attendance",
        },
        {
            id: 5,
            name: "Delete attendance records",
            module: "attendance",
        },
        {
            id: 6,
            name: "Create and view patrol records",
            module: "patrol",
        },
        {
            id: 7,
            name: "View patrol records",
            module: "patrol",
        },
        {
            id: 8,
            name: "Create patrol records",
            module: "patrol",
        },
        {
            id: 9,
            name: "Modify patrol records",
            module: "patrol",
        },
        {
            id: 10,
            name: "Delete patrol records",
            module: "patrol",
        },
        {
            id: 11,
            name: "Create and view incident records",
            module: "incident",
        },
        {
            id: 12,
            name: "Create and view incident records",
            module: "incident",
        },
        {
            id: 13,
            name: "Modify incident records",
            module: "incident",
        },
        {
            id: 14,
            name: "Delete incident records",
            module: "incident",
        },
        {
            id: 15,
            name: "Create and view visitor records",
            module: "visitor",
        },
        {
            id: 16,
            name: "View visitor records",
            module: "visitor",
        },
        {
            id: 17,
            name: "Create visitor records",
            module: "visitor",
        },
        {
            id: 18,
            name: "Modify visitor records",
            module: "visitor",
        },
        {
            id: 19,
            name: "Delete visitor records",
            module: "visitor",
        },
        {
            id: 20,
            name: "View admin accounts",
            module: "adminaccount",
        },
        {
            id: 21,
            name: "Create and modify admin accounts",
            module: "adminaccount",
        },
        {
            id: 22,
            name: "Delete admin accounts",
            module: "adminaccount",
        },
        {
            id: 23,
            name: "View staff accounts",
            module: "staffaccount",
        },
        {
            id: 24,
            name: "Create and modify staff accounts",
            module: "staffaccount",
        },
        {
            id: 25,
            name: "Delete staff accounts",
            module: "staffaccount",
        },
    ]

    const [dataState, setDataState] = useState({
        staffRecords: staffRecords,
        attendanceRecords: attendanceRecords,
        incidentRecords: incidentRecords,
        incidentDraftRecords: incidentDraftRecords,
        incidentCommentRecords: incidentCommentRecords,
        visitorRecords: visitorRecords,
        siteRecords: siteRecords,
        clientRecords: clientRecords,
        clientUserRecords: clientUserRecords,
        patrolRecords: patrolRecords,
        patrolCheckPointRecords: patrolCheckPointRecords,
        checkpointRecords: checkpointRecords,
        routeRecords: routeRecords,
        plrdRecords: plrdRecords,
        profilePersonal: profilePersonal,
        profileAgency: profileAgency,
        currentWorkRecords: currentWorkRecords,
        permissionStaffAdminRecords: permissionStaffAdminRecords,
        permissionStaffGeneralRecords: permissionStaffGeneralRecords,
        staffPermissionGeneral: staffPermissionGeneral,
        staffPermissionAdmin: staffPermissionAdmin,
        clientPermissionRecords: clientPermissionRecords,
        clientPermission: clientPermission,
        // ====================
        citizenshipList: citizenshipList,
        nationalityList: nationalityList,
        incidentTypeList: incidentTypeList,
        raceList: raceList,
        religionList: religionList,
        plrdGradeList: plrdGradeList,
        designationList: designationList,
        emStatusList: emStatusList,
        emTypeList: emTypeList,
        purposeOfVisitList: purposeOfVisitList,
        siteList: siteList, // only for agency portal
        clientList: clientList, // only for agency portal
        permissionList: permissionList,
    })

    return (
        <DataContext.Provider value={{ dataState, setDataState }}>
            {props.children}
        </DataContext.Provider>
    )
}

export default DataContextProvider