import React, { useContext, useEffect, useState } from "react"
import { Button, Descriptions, Form, Input } from "antd"

import path from "../utils/pathSettings"
import { AppContext } from "../contexts/AppContext"

const MobileVerify = () => {
    const { actionSetPageTitle } = useContext(AppContext)
    const [form] = Form.useForm();
    const [resend, setResend] = useState(false)
    const validateMessages = {
        required: 'This field is required.',
    }

    const getCurrentAuthenticatedUserEmail = async () => {
        form.setFieldsValue({
            mobile: "+65 98765432",
        })
    }

    useEffect(() => {
        actionSetPageTitle("Settings", true, path("mobileChange"))

        getCurrentAuthenticatedUserEmail()

        return () => {
            actionSetPageTitle("", false, "")
        }
    }, [])

    const handleClick = async () => {
        // console.log("RESEND");
        setResend(true)
    }

    const onFinish = (values) => {
        // console.log("on finish", values);
    }

    return (
        <div>
            <div className="content-content">
                <Descriptions
                    title={"Verify Mobile Number"}
                    // column={3}
                // bordered
                >
                    {/* <Descriptions.Item label="Current Email" span={3}>{user.email}
                        {
                            user.emailVerified === true ? (
                                <span className="emailverifiedstatus"><CheckCircleOutlined /></span>
                            ) :
                                (
                                    <span className="emailverifiedstatus notverified"><ExclamationCircleOutlined /></span>
                                )
                        }
                    </Descriptions.Item> */}
                </Descriptions>
                <Form
                    form={form}
                    layout="vertical"
                    onFinish={onFinish}
                    validateMessages={validateMessages}
                    requiredMark={false}
                >
                    <Form.Item
                        label="Mobile Number"
                        name="mobile"
                        rules={[
                            {
                                required: true,
                            },
                        ]}
                    >
                        <Input autoComplete="off" placeholder="Enter mobile number" disabled={true} />
                    </Form.Item>
                    <Form.Item
                        label="Code (Sent to your phone via SMS)"
                        name="code"
                        rules={[
                            {
                                required: true,
                            },
                        ]}
                    >
                        <Input autoComplete="off" placeholder="Enter code" />
                    </Form.Item>
                    <Descriptions column={3}>
                        <Descriptions.Item label="Did not receive confirmation code?">
                            <div className="button">
                                <Button onClick={handleClick} disabled={resend}>Resend</Button>
                            </div>
                        </Descriptions.Item>
                    </Descriptions>
                    <div className="button">
                        <Button type="primary" htmlType="submit">Verify</Button>
                    </div>
                </Form>
            </div>
        </div>
    )
}

export default MobileVerify