const awsmobile = {
    "aws_project_region": "ap-southeast-1",
    "aws_cognito_identity_pool_id": "ap-southeast-1:9b9edd50-8b1e-42a3-a44f-495167c699cf",
    "aws_cognito_region": "ap-southeast-1",
    "aws_user_pools_id": "ap-southeast-1_gQlBhmBFm",
    "aws_user_pools_web_client_id": "7m21adcuo2mfoo6l8bg3hevv5t",
    "oauth": {},
    "aws_cognito_username_attributes": [
        "EMAIL",
        "PHONE_NUMBER"
    ],
    "aws_cognito_social_providers": [],
    "aws_cognito_signup_attributes": [
        "PHONE_NUMBER"
    ],
    "aws_cognito_mfa_configuration": "OFF",
    "aws_cognito_mfa_types": [
        "SMS"
    ],
    "aws_cognito_password_protection_settings": {
        "passwordPolicyMinLength": 8,
        "passwordPolicyCharacters": []
    },
    "aws_cognito_verification_mechanisms": [
        "EMAIL",
        "PHONE_NUMBER"
    ],
    "aws_appsync_graphqlEndpoint": "https://5oue6mfedjetfnkglclm24knem.appsync-api.ap-southeast-1.amazonaws.com/graphql",
    "aws_appsync_region": "ap-southeast-1",
    "aws_appsync_authenticationType": "AWS_IAM",
    "aws_user_files_s3_bucket": "isap173501-dev",
    "aws_user_files_s3_bucket_region": "ap-southeast-1",
    "aws_cognito_login_mechanisms": [
        "EMAIL",
        "PREFERRED_USERNAME",
        "PHONE_NUMBER"
    ],
    "aws_cognito_login_mechanism": [
        "EMAIL",
        "PREFERRED_USERNAME",
        "PHONE_NUMBER"
    ]
};


export default awsmobile;