import path from "./pathSettings";
import React, { useContext, useEffect, useState } from 'react'
import { Button, DatePicker, Descriptions, Empty, Form, Image, Input, Modal, notification, Popconfirm, Select, Spin, Tag, Upload } from 'antd'
const { Option } = Select

export const pagesWithoutNav = [path('login'), path('siteSelection')]
export const siteSelectionRequestForOTP = true;

export const renderListOptions = (array, key = 'id', value = 'id', name = 'name') => {
    if (typeof (array) === 'object' && array.length !== 0) {
        const listOptions = array.map((item, _index) => {
            return (
                <Option key={item[key]} value={item[value]}>{item[name]}</Option>
            )
        })

        return listOptions
    }
    else {
        return (
            <div />
        )
    }
}