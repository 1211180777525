import React from "react"

const ComingSoon = () => {
    return (
        <div>
            Page - Coming Soon
        </div>
    )
}

export default ComingSoon