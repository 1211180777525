import React, { useContext, useEffect, useState } from "react"
import { Button, Collapse, DatePicker, Form, Input, Select } from "antd"
import { SearchOutlined } from '@ant-design/icons'
import moment from "moment"

import { AppContext } from "../contexts/AppContext"

const { Panel } = Collapse;
const { RangePicker } = DatePicker;
const { Option } = Select

const FilterPatrol = (props) => {
    const { appState } = useContext(AppContext)
    const { onFinish, values } = props
    const checkpointsPage = props.checkpointsPage !== undefined ? props.checkpointsPage : false
    const routesPage = props.routesPage !== undefined ? props.routesPage : false
    const [form] = Form.useForm();
    const [activeKey, setActiveKey] = useState("filter")
    const dateFormatList = ["DD MMM YYYY"]
    const validateMessages = {
        required: 'This field is required.',
    }

    useEffect(() => {
        form.setFieldsValue(values)
    }, [values])

    useEffect(() => {
        if (appState.broken === true) {
            setActiveKey("")
        }
        else {
            setActiveKey("filter")
        }
    }, [appState.broken])

    const onChangeCollapse = () => {
        if (activeKey === "") {
            setActiveKey("filter")
        }
        else {
            setActiveKey("")
        }
    }

    const onFinishThis = (values) => {
        setActiveKey("")
        onFinish(values)
    }

    const onChangeDatePicker = (date, dateString) => {
        // console.log(date, dateString);
    }

    const disabledDate = (current) => {
        // Cannot select days next day onwards
        return current > moment().endOf('day');
    }

    const renderListOptions = (array) => {
        if (array.length !== 0) {
            const listOptions = array.map((item, index) => {
                return (
                    <Option key={item.id} value={item.id}>{item.name}</Option>
                )
            })

            return listOptions
        }
        else {
            return (
                <div />
            )
        }
    }

    return (
        <div className="filter">
            <Collapse activeKey={activeKey} onChange={onChangeCollapse} ghost>
                <Panel header="Filter" key="filter">
                    <Form
                        form={form}
                        layout="vertical"
                        onFinish={onFinishThis}
                        validateMessages={validateMessages}
                        requiredMark={false}
                    >
                        <Form.Item
                            name="search"
                        >
                            <Input autoComplete="off" prefix={<SearchOutlined />} placeholder={routesPage === true ? "Search (Route)" : (checkpointsPage === true ? "Search (Checkpoint, Tag ID)" : "Search (Route, Staff Num, Staff Name)")} allowClear />
                        </Form.Item>
                        {
                            checkpointsPage === false && routesPage === false ? (
                                <Form.Item
                                    label="Patrol Start Date Range"
                                    name="dateRange"
                                    rules={[
                                        {
                                            required: true,
                                        },
                                    ]}
                                >
                                    <RangePicker onChange={onChangeDatePicker} format={dateFormatList} disabledDate={disabledDate} />
                                </Form.Item>
                            ) : ("")
                        }
                        <div className="row">
                            {
                                checkpointsPage !== true ? (
                                    <Form.Item
                                        label="Status"
                                        name="status"
                                    >
                                        <Select
                                            showSearch
                                            placeholder="Select status"
                                            filterOption={(input, option) =>
                                                option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
                                            }
                                        >
                                            {
                                                routesPage === true || checkpointsPage === true ? (
                                                    <>
                                                        <Option value={0}>All</Option>
                                                        <Option value={"ACTIVE"}>Active</Option>
                                                        <Option value={"INACTIVE"}>Inactive</Option>
                                                    </>
                                                ) : (
                                                    <>
                                                        <Option value={0}>All</Option>
                                                        <Option value={"COMPLETED"}>Completed</Option>
                                                        <Option value={"SEMI-COMPLETED"}>Semi-completed</Option>
                                                        <Option value={"DISRUPTED"}>Disrupted</Option>
                                                        <Option value={"PENDING"}>Pending</Option>
                                                    </>
                                                )
                                            }

                                        </Select>
                                    </Form.Item>
                                ) : ("")
                            }
                            <Form.Item
                                label="Site"
                                name="site"
                            >
                                <Select
                                    showSearch
                                    disabled
                                    placeholder="Select site"
                                    filterOption={(input, option) =>
                                        option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
                                    }
                                >
                                    {/* <Option value={0}>All</Option> */}
                                    {renderListOptions(appState.siteList.filter(s => s.id === appState.siteProfile.siteProfileID))}
                                </Select>
                            </Form.Item>
                        </div>
                        <div className="button">
                            <Button type="primary" htmlType="submit">Apply</Button>
                        </div>
                    </Form>
                </Panel>
            </Collapse>
        </div>
    )
}

export default FilterPatrol