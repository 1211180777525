import React, { useContext, useEffect } from "react"
import { Avatar, Dropdown, Layout, Menu, notification, PageHeader } from "antd"
import { UserOutlined } from "@ant-design/icons"
import { Link, useHistory, useLocation } from "react-router-dom"
import Auth from "@aws-amplify/auth"

import path from "../utils/pathSettings"
import { AppContext } from "../contexts/AppContext"
import { pagesWithoutNav } from "../utils/constants"

const { Header } = Layout

const HeaderApp = () => {
    const { appState, actionLogout } = useContext(AppContext)
    const history = useHistory()
    const location = useLocation()

    const user = JSON.parse(localStorage.getItem("isap_cognitouser_site"))
    const name = user?.signInUserSession.idToken.payload.name

    const content = (
        <Menu style={{ maxWidth: "250px" }}>
            <div style={{padding: '0.5rem'}}>
                {appState.siteProfile.name ?? ''}
            </div>
            <Menu.Divider />
            <Menu.Item key={path("profile")}>
                <Link to={path("profile")}><b>{name}</b></Link>
            </Menu.Item>
            {/* <div style={{ padding: "5px 12px", fontWeight: "bold" }}>{"Name of User"}</div> */}
            <Menu.Divider />
            {/* <Menu.Item key={path("settings")}>
                <Link to={path("settings")}>Settings</Link>
            </Menu.Item> */}
            <Menu.Item key={path("siteSelection")}>
                <Link to={{
                    pathname: path("siteSelection"),
                    state: { toChange: true }
                }} >Change Site</Link>
            </Menu.Item>
            <Menu.Item key="/logout" onClick={(e) => handleClick(e, 'logout')}>Log Out</Menu.Item>
        </Menu>
    )

    useEffect(() => {
        // console.log("appState: ", appState);
    }, [])

    const handleClick = async (e, id) => {
        switch (id) {
            case "logout":
                // console.log("ONCLICK: LOGOUT");
                await Auth.signOut()
                actionLogout()
                notification.success({
                    message: "Log out successfully"
                })
            default:
        }
    }

    if (pagesWithoutNav.includes(location.pathname)) {
        return ("")
    }
    else {
        return (
            <Header className="header">
                <PageHeader
                    onBack={
                        appState.backButton === true ? (
                            appState.backURL !== "" ? (
                                () => history.push(appState.backURL)
                            ) : (
                                () => window.history.back()
                            )
                        ) : (
                            false
                        )
                    }
                    title={appState.pageTitle}
                    subTitle={appState.pageSubtitle}
                    extra={
                        [
                            <Dropdown overlay={content} trigger={["click"]} key={"profilepic"}>
                                <Avatar className="avatar" icon={<UserOutlined />} src={""}>UN</Avatar>
                            </Dropdown>,
                        ]
                    }
                />
            </Header>
        )
    }
}

export default HeaderApp