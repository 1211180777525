import React from "react"
import { Button, Result } from "antd"
import { useHistory } from "react-router-dom"

import path from "../utils/pathSettings"

const Error404 = () => {
    const history = useHistory()
    return (
        <Result
            status="404"
            title="404"
            subTitle="Sorry, the page you visited does not exist."
            extra={<Button type="primary" onClick={() => { history.push(path("home")) }}> Back Home</Button>}
        />
    )
}

export default Error404