import React from "react"
import { Route, Switch } from "react-router-dom"
import { PrivateRoute } from "./PrivateRoute"

import path from "./pathSettings"
import ComingSoon from "../components/Page-ComingSoon"
import EmailChange from "../components/Page-Settings-Email-Change"
import EmailVerify from "../components/Page-Settings-Email-Verify"
import Home from "../components/Page-Home"
import Login from "../components/Page-Login"
import MobileChange from "../components/Page-Settings-Mobile-Change"
import MobileVerify from "../components/Page-Settings-Mobile-Verify"
import PatrolRecords from "../components/Page-Patrol-Records"
import PatrolRecordsProfile from "../components/Page-Patrol-Records-Profile"
import PasswordChange from "../components/Page-Settings-Password-Change"
import Settings from "../components/Page-Settings"
import SiteSelection from "../components/Page-SiteSelection"
import Error404 from "../components/Page-Error404"
import AttendanceRecords from "../components/Page-Attendance-Records"
import AttendanceOnDuty from "../components/Page-Attendance-OnDuty"
import OccurrenceCreate from "../components/Page-Occurrence-Profile-Create"
import Occurrence from "../components/Page-Occurrence"
import IncidentReportCreate from "../components/Page-Incident-Report-Create"
import IncidentDraftReportEdit from "../components/Page-Incident-Draft-Report-Edit"
import IncidentDraft from "../components/Page-Incident-Draft"
import IncidentReportEdit from "../components/Page-Incident-Report-Edit"
import IncidentReport from "../components/Page-Incident-Report"
import Incident from "../components/Page-Incident"
import VisitorRecordsCreate from "../components/Page-Visitor-Records-Create"
import Visitor from "../components/Page-Visitor"

const RoutePath = () => {
    
    return (
        <Switch>
            <Route path={path("login")} component={Login} />
            <Route path={path("siteSelection")} component={SiteSelection} />
            <PrivateRoute path={path('attendanceOnDuty')} component={AttendanceOnDuty} />
            <PrivateRoute path={path('attendanceRecords')} component={AttendanceRecords} />
            <PrivateRoute path={path("emailChange")} component={EmailChange} />
            <PrivateRoute path={path("emailVerify")} component={EmailVerify} />
            <PrivateRoute path={path('incidentReportCreate')} component={IncidentReportCreate} />
            <PrivateRoute path={path('incidentDraftReportEdit', [':id'])} component={IncidentDraftReportEdit} />
            <PrivateRoute path={path('incidentDraft')} component={IncidentDraft} />
            <PrivateRoute path={path('incidentReportEdit', [':id'])} component={IncidentReportEdit} />
            <PrivateRoute path={path('incidentReport', [':id'])} component={IncidentReport} />
            <PrivateRoute path={path('incident')} component={Incident} />
            <PrivateRoute path={path("mobileChange")} component={MobileChange} />
            <PrivateRoute path={path("mobileVerify")} component={MobileVerify} />
            <PrivateRoute path={path('occurrenceCreate')} component={OccurrenceCreate} />
            <PrivateRoute path={path('occurrence')} component={Occurrence} />
            <PrivateRoute path={path("patrolRecordsProfile", [":id"])} component={PatrolRecordsProfile} />
            <PrivateRoute exact path={path("patrolRecords")}  component={PatrolRecords} />
            <PrivateRoute path={path("preferences")} component={ComingSoon} />
            <PrivateRoute path={path("passwordChange")} component={PasswordChange} />
            <PrivateRoute path={path("settings")} component={Settings} />
            <PrivateRoute path={path("visitorCreate")} component={VisitorRecordsCreate} />
            <PrivateRoute path={path("visitor")} component={Visitor} />
            <PrivateRoute exact path={path("home")} component={Home} />
            <Route component={Error404} />
        </Switch>
    )
}

export default RoutePath