import React, { useContext, useEffect, useState } from "react"
import { Button, Descriptions, Form, Input, Tooltip } from "antd"

import path from "../utils/pathSettings"
import { AppContext } from "../contexts/AppContext"

const PasswordChange = () => {
    const { actionSetPageTitle } = useContext(AppContext)
    const [form] = Form.useForm();
    const validateMessages = {
        required: 'This field is required.',
        string: {
            min: "This field must be at least ${min} characters",
        },
    }

    useEffect(() => {
        actionSetPageTitle("Settings", true, path("settings"))

        return () => {
            actionSetPageTitle("", false, "")
        }
    }, [])

    const onFinish = (values) => {
        // console.log("on finish", values);
    }

    return (
        <div>
            <div className="content-content">
                <Descriptions title={"Change Password"} />
                <Form
                    form={form}
                    layout="vertical"
                    onFinish={onFinish}
                    validateMessages={validateMessages}
                    requiredMark={false}
                >
                    <Form.Item
                        label="Current Password"
                        name="password"
                        rules={[
                            {
                                required: true,
                            },
                            {
                                min: 8,
                            },
                        ]}
                        extra={
                            <Tooltip placement="bottomLeft" title={"Kindly log out of your account and click on \"Forgot password\" at the login page."} trigger={["click"]}>
                                <div className="hint">
                                    Forget your password?
                                </div>
                            </Tooltip>
                        }
                    >
                        <Input.Password placeholder="Enter password" />
                    </Form.Item>
                    <Form.Item
                        label="New Password"
                        name="newPassword"
                        rules={[
                            {
                                required: true,
                            },
                            {
                                min: 8,
                            },
                        ]}
                    >
                        <Input.Password placeholder="Enter password" />
                    </Form.Item>
                    <Form.Item
                        label="Confirm New Password"
                        name="confirmNewPassword"
                        rules={[
                            {
                                required: true,
                            },
                            {
                                min: 8,
                            },
                            ({ getFieldValue }) => ({
                                validator(_, value) {
                                    if (!value || getFieldValue('newPassword') === value) {
                                        return Promise.resolve();
                                    }

                                    return Promise.reject(new Error('The two passwords that you entered do not match.'));
                                },
                            }),
                        ]}
                    >
                        <Input.Password placeholder="Enter password" />
                    </Form.Item>
                    <div className="button">
                        <Button type="primary" htmlType="submit">Update</Button>
                    </div>
                </Form>
            </div>
        </div>
    )
}

export default PasswordChange